import React, { useContext, useEffect, useState } from "react";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { CurrentLoggedInUserContext, userIsAuthorisedContext } from "../../App";
import { getUserCart, loginUser, removeUserCart, saveLoggedInUser, saveToken, saveUserCart } from "../../services/users.service";
import { toastError, toastSuccess } from "../../utils/toastUtils";



function Login() {
  const [type, settype] = useState("password");
  const [showPassword, setshowPassword] = useState(false);

  const [currentLoggedInUser, setCurrentLoggedInUser] = useContext(CurrentLoggedInUserContext);
  const [userIsAuthorised, setUserIsAuthorised] = useContext(userIsAuthorisedContext);
  const navigate = useNavigate();

  const [cartArr, setCartArr] = useState([]);
  const handlePassword = () => {
    type === "password" ? settype("text") : settype("password");
    setshowPassword(!showPassword);
  };
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");



  const handleGetCart = () => {
    let tempCartArr = getUserCart()
    tempCartArr = JSON.parse(tempCartArr)

    setCartArr(tempCartArr ? [...tempCartArr] : [])
  }




  const handleLogin = async () => {
    try {

      if (email == "") {
        toastError("Email cannot be empty !!!")
        return;
      }
      if (password == "") {
        toastError("Password cannot be empty !!!")
        return;
      }
      if (!email.includes("@")) {
        toastError("Invalid email provided !!!")
        return;
      }
      if (!email.includes(".")) {
        toastError("Invalid email provided !!!")
        return;
      }





      let obj = {
        email,
        password,
        cartArr,
      }
      let { data: res } = await loginUser(obj);
      if (res.message) {
        toastSuccess(res.message);
        setUserIsAuthorised(true)
        setCurrentLoggedInUser(res.loggedInUser);
        saveToken(res.token);
        saveLoggedInUser(JSON.stringify(res.loggedInUser));
        removeUserCart()
        navigate("/")
      }
    }
    catch (err) {
      toastError(err)
    }
  }


  useEffect(() => {
    handleGetCart()
  }, [])



  return (
    <main>
      <section className="login ptb-80">
        <div className="container">
          <h1 className="text-center mb-4">Login</h1>
          <div className="row">
            <div className="col-12 col-md-10 col-lg-6 col-xxl-4 mx-auto">
              <form action="#" className="row form">
                <div className="col-12 mb-4">
                  <label className="mb-2">EMAIL</label>
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    type="email"
                    className="form-control"
                  />
                </div>
                <div className="col-12 mb-4">
                  <div className="d-flex align-items-center justify-content-between mb-2">
                    <label className="w-auto">PASSWORD</label>
                    <Link to="/ResetPassword" className="fw-semibold brown2">
                      Forgot password?
                    </Link>
                  </div>
                  <div className="search-form">
                    <div className="form-control">
                      <input
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        type={type}
                        className="border-0"
                      />
                      <div className="icon pointer" onClick={handlePassword}>
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 text-center pt-1">
                  <button
                    type="button"
                    onClick={() => handleLogin()}
                    className="btn btn-hover btn-custom btn-brown w-100"
                  >
                    SIGN IN
                  </button>
                  <div className="mt-3">
                    <Link to="/Register" className="fw-semibold brown2 fs-6">
                      Create account
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>


    </main>
  );
}

export default Login;
