
  import { axiosApiInstance } from "./axios.service";
import { url } from "./url.service";

const serverUrl = url + "/homeBanner";



export const getHomeBannerActive = (query) => {
  return axiosApiInstance.get(`${serverUrl}/getActiveBanner?${query}`);
};

  export const getById = (id) => {
    return axiosApiInstance.get(`${serverUrl}/getById/${id}`);
  }


