import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CurrentLoggedInUserContext, userIsAuthorisedContext } from "../../App";
import { removeLoggedInUser, removeToken } from "../../services/users.service";
import MyOrder from "./MyOrder";
import Profile from "./Profile";
import TrackOrder from "./TrackOrder";
import WaitingForApprovalOrder from "./WaitingForApprovalOrder";



function Account() {
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams();

  const [currentLoggedInUser, setCurrentLoggedInUser] = useContext(CurrentLoggedInUserContext);
  const [userIsAuthorised, setUserIsAuthorised] = useContext(userIsAuthorisedContext);
  const [tabs, setTabs] = useState([
    {
      name: "My Account",
      active: false,
      render: <Profile />,
    },
    {
      name: "My Orders",
      active: true,
      render: <MyOrder />,
    },
    {
      name: "Waiting For Approval",
      active: false,
      render: <WaitingForApprovalOrder />,
    },
    {
      name: "Track Your Order",
      active: false,
      render: <TrackOrder />,
    },
  ]);

  const activeTab = (i) => {
    const temp = tabs.find((item, index) => i === index);
    // setTabs([...temp]);
    navigate(`/Account?tab=${encodeURIComponent(temp.name)}`)
  };




  const handleLogout = () => {
    setCurrentLoggedInUser({});
    setUserIsAuthorised(false);
    removeToken();
    removeLoggedInUser();
    navigate("/")
  }



  useEffect(() => {
    let temp = tabs.map((el) => {
      if (el.name == searchParams.get("tab")) {
        el.active = true
      }
      else {
        el.active = false
      }
      return el
    })



    setTabs([...temp]);



    // console.log(searchParams.get("tab"), "searchParams.get(tab)")
  }, [searchParams.get("tab")])




  return (
    <main>
      <section className="ptb-50 my-account">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-md-3">
              <div className="left">
                <ul className="bg3 ibm">
                  {tabs.map((item, i) => {
                    return (
                      <li
                        className={`${item.active ? "active" : ""}`}
                        key={i}
                        onClick={() => activeTab(i)}
                      >
                        {item.name}
                      </li>
                    );
                  })}
                  <li onClick={() => handleLogout()}>Logout</li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-9">
              {tabs.map((item, i) => {
                if (item.active) {
                  return (
                    <div className="right" key={i}>
                      {item.render}
                    </div>
                  );
                }
              })}
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Account;
