import React, { useContext, useEffect, useState } from 'react'
import { CurrentLoggedInUserContext, UserCartContext, userIsAuthorisedContext } from "../App";
import { getUserCart } from "../services/users.service";
export function useGetCartArr() {
    const [currentLoggedInUser, setCurrentLoggedInUser] = useContext(CurrentLoggedInUserContext);
    const [userIsAuthorised, setUserIsAuthorised] = useContext(userIsAuthorisedContext);
    const [cartArr, setCartArr] = useContext(UserCartContext);

    const [cartValueArr, setCartValueArr] = useState([])


    useEffect(() => {
        let tempCart = getUserCart()
        setCartValueArr(tempCart)
    }, [])


    return cartValueArr
}







