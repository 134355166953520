import React, { useEffect, useState } from "react";
import { AiFillCalendar } from "react-icons/ai";
import { FaUser } from "react-icons/fa";
import { Link } from "react-router-dom";
import { getBlog } from "../services/blog.service";
import { toastError } from "../utils/toastUtils";
import { images } from "./Utility/Images";
import DOMPurify from 'dompurify'
import { generateFilePath } from "../utils/filepath";
function Blog() {

  const [blogsArr, setBlogsArr] = useState([]);



  const handleGetBlogs = async () => {
    try {
      let { data: res } = await getBlog()
      if (res.data) {
        setBlogsArr(res.data)
      }
    }
    catch (err) {
      toastError(err)
    }
  }
  const sanitizedData = (data) => ({
    __html: DOMPurify.sanitize(data)
  })

  useEffect(() => {
    handleGetBlogs()
  }, [])



  return (
    <main>
      <section className="ptb-50">
        <div className="container">
          {/* <div className="text-center mb-5">
            <h1>Blogs</h1>
          </div> */}
          <div className="row gy-4">
            {
              blogsArr && blogsArr.length > 0 && blogsArr.map((el, index) => {
                return (
                  <div key={index} className="col-12">
                    {/* <div className="imgblog">
                      <img src={images.blogimg1} alt="" className='img-fluid' />
                    </div> */}
                    {/* <div className="imgblog">
                      <img src={images.blogimg13} alt="" className='img-fluid' />
                    </div> */}
                    <div className="blog-box">
                      <div className="image">
                        <Link to={`/${el.slug}`}>
                          <img src={el.image ? generateFilePath(el.image) : images.product} alt="" />
                        </Link>
                      </div>
                      <div className="content flex-1">
                        <h4>
                          <Link to={`/${el.slug}`}>{el?.title}</Link>
                        </h4>
                        <ul className="tags">
                          <li>
                            <div className="icon">
                              <AiFillCalendar />
                            </div>
                            {new Date(el.createdAt).toDateString()}
                          </li>
                          <li>
                            <div className="icon">
                              <FaUser />
                            </div>
                            {el?.author}
                          </li>
                        </ul>
                        <div className="desp">
                          <div
                          // dangerouslySetInnerHTML={sanitizedData(el?.description?.slice(0, 150))}
                          />
                          {el?.shortDescription}
                          <Link to={`/${el.slug}`} className="brown2 fw-semibold">
                            ... Read More
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })
            }
            {/* <div className="col-12">
              <div className="blog-box">
                <div className="image">
                  <Link to={`/BlogDetail/${el.slug}`}>
                    <img src={images.product} alt="" />
                  </Link>
                </div>
                <div className="content flex-1">
                  <h4>
                    <Link to={`/BlogDetail/${el.slug}`}>Drawing room</Link>
                  </h4>
                  <ul className="tags">
                    <li>
                      <div className="icon">
                        <AiFillCalendar />
                      </div>
                      Dec 08,2022
                    </li>
                    <li>
                      <div className="icon">
                        <FaUser />
                      </div>
                      Admin
                    </li>
                  </ul>
                  <p className="desp">
                    lit. Phasellus aliquet nibh elementum id iaculis pharetra.
                    Maecenas eleifend sed ex. Donec quis magna sed felis
                    elementum blandit nec qui. Maecen.lit. Phasellus aliquet
                    nibh id iaculis pharetra. Maecenas e sed ex
                    <Link to={`/BlogDetail/${el.slug}`} className="brown2 fw-semibold">
                      ...Read More
                    </Link>
                  </p>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </main>
  );
}

export default Blog;
