import React from 'react';
import { images } from './Utility/Images';


function Error() {
    return ( 
        <main>
            <section className="ptb-50">
                <div className="container text-center">
                    <img src={images.error} alt='' className='w-auto img-contain' />
                    <h1 className="heading brown2">Page Not Found</h1>
                </div>
            </section>
        </main>
     );
}

export default Error;