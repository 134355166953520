import axios from "axios";
import jwtDecode from "jwt-decode";
import { toastError } from "../utils/toastUtils";
import { getToken, removeLoggedInUser, removeToken } from "./users.service";


export const axiosApiInstance = axios.create();
axiosApiInstance.interceptors.request.use(
    async (config) => {

        let token = await getToken()

        if (token) {
            config.headers['authorization'] = 'Bearer ' + token;
        }
        // config.headers['Content-Type'] = 'application/json';
        return config;
    },
    error => {
        toastError(error)
        Promise.reject(error)
    });

axiosApiInstance.interceptors.response.use(
    (res) => {
        // Add configurations here
        return res;
    },
    async (err) => {
        toastError(err)

        let { config, response } = err;
        if (response && response.status == 401) {
            removeToken();
            removeLoggedInUser();
            window.location.href = "/";
            return Promise.reject(err);

        }
    }
);