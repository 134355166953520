import React, { useLayoutEffect } from "react";
import { useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Collapse from "react-bootstrap/Collapse";
import _ from "lodash";
import { useEffect } from "react";
import { toastError } from "../utils/toastUtils";
import { getProducts } from "../services/product.service";
import Offcanvas from 'react-bootstrap/Offcanvas';


function ShopFilter({
  categoryArrData,
  subcategoryArrData,
  setProductArr,
  materialArrData,
  setLoading,
  sortByData,
  selectedCategoryIdData,
  showFilterMobile,
  setShowFilterMobile,
  filterBreakPoint,
}) {
  const [categoryArr, setCategoryArr] = useState([]);
  const [subCategroyArr, setSubCategroyArr] = useState([]);
  const [materialArr, setMaterialArr] = useState([]);
  const [selectedCategoryId, setSelectedCategoryId] = useState("");
  const [sortBy, setSortBy] = useState("");

  useEffect(() => {
    setCategoryArr([...categoryArrData]);
    if (categoryArrData.some(el => el.checked)) {
      handleGetPaginatedProducts(
        categoryArrData,
        subCategroyArr,
        materialArr,
        sortBy
      );
    }
  }, [categoryArrData]);

  useEffect(() => {
    setSubCategroyArr([...subcategoryArrData]);
    if (subcategoryArrData.some(el => el.checked)) {
      handleGetPaginatedProducts(
        categoryArr,
        subcategoryArrData,
        materialArr,
        sortBy
      );
    }
  }, [subcategoryArrData]);

  useEffect(() => {
    setMaterialArr([...materialArrData]);
    if (materialArrData.some(el => el.checked)) {
      handleGetPaginatedProducts(
        categoryArr,
        subCategroyArr,
        materialArrData,
        sortBy
      );
    }
  }, [materialArrData]);

  useEffect(() => {
    setSortBy(sortByData);

    handleGetPaginatedProducts(
      categoryArr,
      subCategroyArr,
      materialArr,
      sortByData
    );

  }, [sortByData]);

  useEffect(() => {
    setSelectedCategoryId(selectedCategoryIdData);
  }, [selectedCategoryIdData]);

  const [page, setPage] = useState(0);
  const [limit, setLimit] = useState(10);

  const handleGetPaginatedProducts = async (
    categoryArr,
    subCategroyArr,
    materialArr,
    sortBy
  ) => {
    console.log(categoryArr, subCategroyArr, materialArr);
    try {
      setLoading(true)
      let query = "";
      let selectedCategoryArr = returnSelectedCategories(categoryArr).map(
        (el) => {
          return { categoryId: el._id };
        }
      );
      let selectedSubCategoryArr = returnSelectedCategories(subCategroyArr).map(
        (el) => {
          return { categoryId: el._id };
        }
      );
      
      let selectedMaterialArr = materialArr
      .filter((el) => el.checked)
      .map((el) => {
        return { materialId: el._id };
      });
      if (sortBy && sortBy != "") {
        query = `${query}&sortBy=${sortBy}`;
      }
      
      let tempArr = [...selectedCategoryArr, ...selectedSubCategoryArr];
      
      if (tempArr && tempArr.length > 0) {
        query = `${query}&categoryArr=${JSON.stringify([...tempArr])}`;
      }
      
      if (selectedMaterialArr && selectedMaterialArr.length > 0) {
        query = `${query}&materialArr=${JSON.stringify([
          ...selectedMaterialArr,
        ])}`;
      }
      console.log(query, "query");
      let { data: res } = await getProducts(`${query}`);
      if (res.data) {
        setProductArr(res.data);
        setLoading(false)
      }
    } catch (err) {
      toastError(err);
      setLoading(false)
    }
  };

  useEffect(() => {
    console.log(categoryArrData, "categoryArrData")
    setCategoryArr([...categoryArrData]);
  }, [categoryArrData]);

  const returnSelectedCategories = (arr) => {
    let new_selected_arr = arr.filter((el) => el.checked);
    return new_selected_arr;
  };

  const handleRenderNestedCategory = (arr, id, value) => {
    let tempArr = arr.map((el) => {
      if (el._id == id) {
        el.checked = value;
        return el;
      } else {
        if (el.subCategoryArr && el.subCategoryArr.length > 0) {
          handleRenderNestedCategory(el.subCategoryArr, id, value);
        } else {
          return el;
        }
      }
    });
    return tempArr;
  };

  const handleCategoryCheckBoxEvent = (id, value) => {
    let tempCategoryArr = categoryArr.map((el) => {
      if (el._id == id) {
        el.checked = value;
      }
      return el;
    });
    handleGetPaginatedProducts(
      tempCategoryArr,
      subCategroyArr,
      materialArr,
      sortBy
    );
    setCategoryArr([...tempCategoryArr]);
  };

  const handleMaterialCheckBoxEvent = (id, value) => {
    let tempCategoryArr = materialArr.map((el) => {
      if (el._id == id) {
        el.checked = value;
      }
      return el;
    });
    handleGetPaginatedProducts(
      categoryArr,
      subCategroyArr,
      tempCategoryArr,
      sortBy
    );
    setMaterialArr([...tempCategoryArr]);
  };
  const handleSubCategoryCheckBoxEvent = (id, value) => {
    let tempCategoryArr = subCategroyArr.map((el) => {
      if (el._id == id) {
        el.checked = value;
      }
      return el;
    });
    handleGetPaginatedProducts(
      categoryArr,
      tempCategoryArr,
      materialArr,
      sortBy
    );
    setSubCategroyArr([...tempCategoryArr]);
  };

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  // useEffect(() => {
  //   if (categoryArr.some(el => el.checked)) {

  //   }
  // }, [categoryArr]);

  // useEffect(() => {

  //   handleGetPaginatedProducts(
  //     categoryArr,
  //     subCategroyArr,
  //     materialArr,
  //     sortBy
  //   );
  // }, [sortBy]);
  // useEffect(() => {
  //   if (materialArr.some(el => el.checked)) {
  //     handleGetPaginatedProducts(
  //       categoryArr,
  //       subCategroyArr,
  //       materialArr,
  //       sortBy
  //     );
  //   }
  // }, [materialArr]);
  // useEffect(() => {
  //   if (subCategroyArr.some(el => el.checked)) {
  //     handleGetPaginatedProducts(
  //       categoryArr,
  //       subCategroyArr,
  //       materialArr,
  //       sortBy
  //     );
  //   }
  // }, [subCategroyArr]);

  useLayoutEffect(() => {
    window.addEventListener("resize", function () {
      setWindowWidth(window.innerWidth);
    });
    setWindowWidth(window.innerWidth);
    return () => {
      window.addEventListener("resize", function () {
        setWindowWidth(window.innerWidth);
      });
    };
  }, []);

  const Filter = () => {
    return (
      <div className="shop-filter bg3">
        <div className="box pb-0">
          <h5 className="title">Filter</h5>
        </div>
        <div className="box">
          <h5 className="title">Categories</h5>
          <ul className="list comm-list">
            {categoryArr &&
              categoryArr.length > 0 &&
              categoryArr.map((el, indexX) => {
                return (
                  <li className="mb-3" key={indexX}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <input
                        className="form-check-input pointer"
                        checked={el?.checked}
                        id={`${el?.name}${indexX}`}
                        onChange={(event) =>
                          handleCategoryCheckBoxEvent(
                            el?._id,
                            event.target.checked
                          )
                        }
                        type="checkbox"
                      />
                      <label htmlFor={`${el?.name}${indexX}`} style={{ paddingLeft: 5 }}>{el?.name}</label>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="box">
          <h5 className="title">Products</h5>
          <ul className="list comm-list">
            {subCategroyArr &&
              subCategroyArr.length > 0 &&
              subCategroyArr.map((el, indexX) => {
                return (
                  <li className="mb-3" key={indexX}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <input
                        className="form-check-input pointer"
                        checked={el?.checked}
                        id={`${el?.name}${indexX}`}
                        onChange={(event) =>
                          handleSubCategoryCheckBoxEvent(
                            el?._id,
                            event.target.checked
                          )
                        }
                        type="checkbox"
                      />
                      <label htmlFor={`${el?.name}${indexX}`} style={{ paddingLeft: 5 }}>{el?.name}</label>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
        <div className="box">
          <h5 className="title">Materials</h5>
          <ul className="list comm-list">
            {materialArr &&
              materialArr.length > 0 &&
              materialArr.map((el, indexX) => {
                return (
                  <li className="mb-3" key={indexX}>
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <input
                        className="form-check-input pointer"
                        checked={el?.checked}
                        id={`${el?.name}${indexX}`}
                        onChange={(event) =>
                          handleMaterialCheckBoxEvent(
                            el?._id,
                            event.target.checked
                          )
                        }
                        type="checkbox"
                      />
                      <label htmlFor={`${el?.name}${indexX}`} style={{ paddingLeft: 5 }}>{el?.name}</label>
                    </div>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    );
  };

  return (
    <>
      {windowWidth < filterBreakPoint && (
        <Offcanvas show={showFilterMobile} onHide={() => setShowFilterMobile(false)}>
          <Offcanvas.Header closeButton className="p-0">
          </Offcanvas.Header>
          <Offcanvas.Body className="p-0">
            <Filter />
          </Offcanvas.Body>
        </Offcanvas>
      )}
      {windowWidth > filterBreakPoint && <Filter />}
    </>
  );
}

export default ShopFilter;

{
  /* <div className="box">
  <h5 className="title">Free shipping</h5>
  <ul className="list comm-list">
    <li>
      <label>
        <input type="checkbox" className="form-check-input" />
        Yes
      </label>
    </li>
    <li>
      <label>
        <input type="checkbox" className="form-check-input" />
        No
      </label>
    </li>
  </ul>
</div> */
}
{
  /* <div className="box">
  <h5 className="title">Price Range </h5>
  <div className="price-range">
    <div>
      <label className="fs-15 fw-semibold line-height-normal">Min</label>
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={minPrizeTooltip}
      >
        <input
          type="range"
          className="form-range"
          step="100"
          min="0"
          max="1000"
          onChange={(e) => setMinPrize(e.target.value)}
          value={minPrize}
        />
      </OverlayTrigger>
    </div>
    <div>
      <label className="fs-15 fw-semibold line-height-normal">Max</label>
      <OverlayTrigger
        placement="top"
        delay={{ show: 250, hide: 400 }}
        overlay={maxPrizeTooltip}
      >
        <input
          type="range"
          className="form-range"
          step="100"
          min="500"
          max="10000"
          onChange={(e) => setMaxPrize(e.target.value)}
          value={maxPrize}
        />
      </OverlayTrigger>
    </div>
  </div>
</div> */
}
