import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import { BrowserRouter, Route, Routes } from "react-router-dom";
// import "../node_modules/bootstrap/dist/js/bootstrap.bundle";
import "./assets/scss/main.scss";
import { Toaster, Toaster as Toastify } from "react-hot-toast";
import About from "./components/About";
import Account from "./components/Account/Account";
import Payment from "./components/Account/Payment";
import Login from "./components/Auth/Login";
import Register from "./components/Auth/Register";
import ResetPassword from "./components/Auth/ResetPassword";
import Blog from "./components/Blog";
import BlogDetail from "./components/BlogDetail";
import Cart from "./components/Cart";
import Contact from "./components/Contact";
import EquiryForm from "./components/EquiryForm";
import Error from "./components/Error";
import FAQ from "./components/FAQ";
import Index from "./components/Index";
import Footer from "./components/Layout/Footer";
import Header from "./components/Layout/Header";
import PrivacyPolicy from "./components/PrivacyPolicy";
import RequestCustomization from "./components/RequestCustomization";
import Shop from "./components/Shop";
import ShopDetail from "./components/ShopDetail";
import TermCondition from "./components/TermCondition";
import ScrollTop from "./components/Utility/ScrollTop";
import Loader from "./components/Loader";

export const CurrentLoggedInUserContext = createContext();
export const userIsAuthorisedContext = createContext();
export const UserCartContext = createContext();
export const LoadingContext = createContext();

// useMemo(() => {
//   axiosApiInstance.interceptors.request.use(
//     async (config) => {
//       // console.log(token)
//       if (token) {
//         config.headers['authorization'] = 'Bearer ' + token;
//       }
//       // config.headers['Content-Type'] = 'application/json';
//       return config;
//     },
//     error => {
//       console.log(error)
//       Promise.reject(error)
//     });
//   axiosApiInstance.interceptors.response.use(
//     (res) => {
//       // Add configurations here
//       return res;
//     },
//     async (err) => {
//       console.log("INterceptor error")
//       let {config,response} = err;
//       if(response && response.status == 401){

//           let decode =await jwtDecode(token);
// console.log(decode,"tokentoken")
//             let refreshTokenResponse = await refreshToken(decode.user);

//             if(refreshTokenResponse.data && refreshTokenResponse.data.success){
//                 let refreshToken = refreshTokenResponse.data.token;
//                 localStorage.setItem("token", refreshToken);
//                 await  new Promise(resolve => {
//                   config.headers["authorization"] = "Bearer " + refreshToken;
//                   console.log(config,"configconfigconfig")
//                     resolve(axios(config))
//                   })
//             }

//       }
//       await logoutUser()

//       return Promise.reject(err);
//     }
//   );
// }, [])

function App() {
  const [currentLoggedInUser, setCurrentLoggedInUser] = useState({});
  const [userIsAuthorised, setUserIsAuthorised] = useState(false);
  const [cartArr, setCartArr] = useState([]);
  const [loading, setLoading] = useState(false);
  return (
    <BrowserRouter>
      <LoadingContext.Provider value={[loading, setLoading]}>
        <UserCartContext.Provider value={[cartArr, setCartArr]}>
          <CurrentLoggedInUserContext.Provider
            value={[currentLoggedInUser, setCurrentLoggedInUser]}
          >
            <userIsAuthorisedContext.Provider
              value={[userIsAuthorised, setUserIsAuthorised]}
            >
              <Loader />
              <Header />
              <ScrollTop />
              <Routes>
                {userIsAuthorised ? (
                  <>
                    <Route path="/Account" exact element={<Account />} />
                    <Route path="/Payment" exact element={<Payment />} />
                  </>
                ) : (
                  <>
                    <Route path="/Login" exact element={<Login />} />
                    <Route path="/Register" exact element={<Register />} />
                  </>
                )}
                <Route path="/" exact element={<Index />}></Route>
                <Route path="/EquiryForm" exact element={<EquiryForm />}></Route>
                <Route path="/Blogs" exact element={<Blog />}></Route>
                <Route
                  path="/:slug"
                  exact
                  element={<BlogDetail />}
                ></Route>
                <Route
                  path="/PrivacyPolicy"
                  exact
                  element={<PrivacyPolicy />}
                ></Route>
                <Route
                  path="/TermCondition"
                  exact
                  element={<TermCondition />}
                ></Route>
                <Route path="/Contact" exact element={<Contact />}></Route>
                <Route path="/FAQ" exact element={<FAQ />}></Route>
                <Route path="/About" exact element={<About />}></Route>
                {/* <Route path="/Shop" exact element={<Shop />}></Route> */}
                <Route path="/Shop/:slug" exact element={<Shop />}></Route>

                <Route
                  path="/ShopDetail/:slug"
                  exact
                  element={<ShopDetail />}
                ></Route>
                <Route path="/ResetPassword" exact element={<ResetPassword />} />
                <Route path="/Cart" exact element={<Cart />} />
                <Route path="/Account" exact element={<Account />} />
                <Route path="/Payment" exact element={<Payment />} />
                <Route
                  path="/RequestCustomization?"
                  exact
                  element={<RequestCustomization />}
                />
                <Route path="*" exact element={<Error />} />
              </Routes>
              <Footer />
              <Toastify />
              <Toaster />
            </userIsAuthorisedContext.Provider>
          </CurrentLoggedInUserContext.Provider>
        </UserCartContext.Provider>
      </LoadingContext.Provider>
    </BrowserRouter>
  );
}

export default App;
