import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { images } from "./Utility/Images";
import { toastError, toastSuccess } from "../utils/toastUtils";
import { add } from "../services/customization.service";
import { useLocation, useParams, useSearchParams } from "react-router-dom";
import { getProductById } from "../services/product.service";
import { generateFilePath } from "../utils/filepath";
import { LoadingContext } from "../App";
function RequestCustomization() {
  const location = useLocation();
  const params = useParams();
  const [loading, setLoading] = useContext(LoadingContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [size, setSize] = useState("");
  const [quantity, setQuantity] = useState("");
  const [pinCode, setPinCode] = useState("");
  const [comment, setComment] = useState("");

  const [image, setImage] = useState("");
  const [appointmentTime, setAppointmentTime] = useState("");
  const [productObj, setProductObj] = useState({});
  const [getFileName, setGetFileName] = useState('')

  const [color, setColor] = useState([
    {
      type: "color",
      value: "",
    },
  ]);
  useEffect(() => {
    // console.log(location, "useLocationuseLocation");
    // let get= location.search.remove("?")

    // console.log(get,"123132")
    handleGetProductById(searchParams.get("productSlug"));
  }, [searchParams.get("productSlug")]);

  const handleColorValue = (value, i) => {
    const temp = color.map((item, index) => {
      i === index ? (item.value = value) : (item.value = item.value);
      return item;
    });
    setColor([...temp]);
  };

  const handleColorIncrement = () => {
    setColor((prev) => {
      return [
        ...prev,
        {
          type: "color",
          value: "",
        },
      ];
    });
  };

  const handleColorDecrement = () => {
    const temp = [...color];
    temp.splice(-1, 1);
    setColor([...temp]);
  };

  const clear = () => {
    setSize("");
    setQuantity("");
    setPinCode("");
    setComment("");
    setColor([
      {
        type: "color",
        value: "",
      },
    ]);
  };
  const handleGetProductById = async (id) => {
    try {
      let { data: res } = await getProductById(id);
      if (res.data) {
        // console.log(res.data);
        setProductObj(res.data);



      }
    } catch (err) {
      toastError(err);
    }
  };
  const handleSubmit = async (e) => {
    try {
      setLoading(true);

      e.preventDefault();
      if (quantity == "") {
        setLoading(false);
        toastError("quantity is mandatory");
        return;
      }
      if (email == "") {
        setLoading(false);
        toastError("email is mandatory");
        return;
      }
      let obj = {
        productId: searchParams.get("productId"),
        size,
        quantity,
        pinCode,
        comment,
        name,
        email,

        color: color.map((x) => ({ value: x.value != "" ? x.value : "#000000" })),
        image,
      };
      // console.log(obj, "obj234234");
      let { data: res } = await add(obj);
      if (res.success) {
        setLoading(false);
        toastSuccess(res?.message);
        clear();
      }
    }
    catch (err) {
      setLoading(false);
      toastError(err)
    }
  };

  const getBase64 = (file, cb) => {
    if (file) {

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        cb(reader.result);
      };
      reader.onerror = function (error) {
        // console.log('Error: ', error)
      };
    }
  };
  const handleFileSelection = async (event) => {
    event.preventDefault();
    let tempArr = [];
    let fileNameArr = []
    if (event.target.files && event.target.files.length > 0) {

      for (let i = 0; i <= event.target.files.length - 1; i++) {


        fileNameArr.push(event.target.files[i].name)
        getBase64(event.target.files[i], (result) => {
          // console.log(event.target.files[i], "event.target.files[i]")
          tempArr.push({ link: event.target.files[i], base64: result });
          if (i == event.target.files.length - 1) {
            setImage([...tempArr])
          }
        });
      }
    }
    setGetFileName(`${fileNameArr[0]} ${fileNameArr.length > 1 ? `+ ${fileNameArr.length - 1}more` : ""}`)
  };


  return (
    <main>
      <section className="ptb-50 request-customize">
        <div className="container">
          <div className="text-center mb-4">
            <h1 className="title">Request Customization</h1>
            <p className="mb-0">
              Fill out this form and let us know your requirement.
            </p>
          </div>
          <div className="row gy-4">
            <div className="col-12 col-md-6">
              <div className="left">
                <img
                  src={productObj?.imageArr && productObj?.imageArr.length > 0 ? generateFilePath(productObj?.imageArr[0].image) : images.kitchen}
                  alt=""
                  className="w-100 img-cover"
                />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="right">
                <h4 className="heading mb-4">{productObj?.name}</h4>
                <form className="form row gx-2">
                  <div className="col-12 mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Size"
                      value={size}
                      onChange={(e) => setSize(e.target.value)}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <div className="color">
                      <ul className="color-box">
                        {color &&
                          color.map((item, i) => {
                            return (
                              <li key={i}>
                                <input
                                  type={item.type}
                                  className="form-control flex-1"
                                  placeholder="Color"
                                  onChange={(e) =>
                                    handleColorValue(e.target.value, i)
                                  }
                                  value={item.value}
                                />
                              </li>
                            );
                          })}
                      </ul>
                      <div className="icon">
                        {/* {color.length < 4 && ( */}
                        <h4 onClick={handleColorIncrement}>
                          <span>+</span>
                        </h4>
                        {/* )} */}
                        {color.length > 1 && (
                          <h4 onClick={handleColorDecrement}>
                            <span>-</span>
                          </h4>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Quantity *"
                      value={quantity}
                      onChange={(e) => setQuantity(e.target.value)}
                    />
                  </div>
                  <div className="col-12 mb-3">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Pincode"
                      value={pinCode}
                      onChange={(e) => setPinCode(e.target.value)}
                    />
                  </div>

                  <div className="col-12 mb-3 height-auto">
                    <div className="position-relative">
                      <input
                        type="file"
                        className="form-control"
                        multiple
                        onChange={(e) => handleFileSelection(e)}
                      />

                      <div className="form-text brown fw-semibold" s style={{ height: "unset" }}>
                        {/* <FileUpload onFileChange={handleFileSet} /> */}

                        {getFileName ? getFileName : "Attach reference image"}
                      </div>
                    </div>
                  </div>

                  <div className="col-12 mb-3">
                    <textarea
                      rows="1"
                      className="form-control"
                      placeholder="Comment"
                      value={comment}
                      onChange={(e) => setComment(e.target.value)}
                    ></textarea>
                  </div>
                  <div className="col-12">
                    <p className="mb-0 line-height-normal small text-end">
                      <span className="text-danger">*</span>&nbsp;Indicates a
                      required field.
                    </p>
                  </div>
                </form>
              </div>
            </div>
            <div className="col-12 text-center">
              <button
                className="btn btn-custom btn-brown px-4"
                onClick={(e) => {
                  handleSubmit(e);
                }}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default RequestCustomization;
