import axios from "axios";
import { axiosApiInstance } from "./axios.service";
import { url } from "./url.service";
import { getToken } from "./users.service";

const serverUrl = url + "/address";

export const addAddress = async (formData) => {
    let token = await getToken()

    let config = {
        headers: {
            authorization: `Bearer ${token}`
        }
    }
    return axiosApiInstance.post(serverUrl + "/addUserAddress", formData, config);
};

export const getAddress = async (query) => {
    let token = await getToken()

    let config = {
        headers: {
            authorization: `Bearer ${token}`
        }
    }
    return axiosApiInstance.get(`${serverUrl}/getUserAddress?${query}`, config);
};

export const deleteAddress = async (id) => {
    let token = await getToken()

    let config = {
        headers: {
            authorization: `Bearer ${token}`
        }
    }
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`, config);
};

export const updateAddress = async (formData, id) => {
    let token = await getToken()

    let config = {
        headers: {
            authorization: `Bearer ${token}`
        }
    }
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData, config);
};

