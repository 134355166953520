import React, { useState } from "react";
import { Link } from "react-router-dom";

function ResetPassword() {
  return (
    <main>
      <section className="login ptb-80">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-10 col-lg-6 col-xxl-4 mx-auto">
              <div className="text-center mb-4">
                <h1>
                  Reset your password
                </h1>
                <p>We will send you an email to reset your password.</p>
              </div>
              <form action="/Login" className="row form">
                <div className="col-12 mb-4">
                  <label className="mb-2">EMAIL</label>
                  <input type="email" className="form-control" required />
                </div>
                <div className="col-12 text-center pt-1">
                  <button
                    type="submit"
                    className="btn btn-hover btn-custom btn-brown px-4"
                  >
                    SUBMIT
                  </button>
                  <div className="mt-3">
                    <Link to="/Login" className="fw-semibold brown2 fs-6">
                      Cancel
                    </Link>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      
    </main>
  );
}

export default ResetPassword;
