import React, { useContext, useEffect } from "react";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { FaPhoneAlt, FaStarOfLife } from "react-icons/fa";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { images } from "../Utility/Images";
import { BsCheckLg } from "react-icons/bs";
import { BiUpload } from "react-icons/bi";
import {
  getOrderById,
  orderCallback,
  updatePayment,
} from "../../services/order.service";
import { toastError, toastSuccess } from "../../utils/toastUtils";
import { LoadingContext } from "../../App";
import { orderStatusObj } from "../../utils/orderStatusObj";

function Payment() {
  const [quoteModal, setQuoteModal] = useState(false);
  const [orderObj, setOrderObj] = useState({});
  const [loading, setLoading] = useContext(LoadingContext);
  const [searchParams, setSearchParams] = useSearchParams();
  const [halfPayment, setHalfPayment] = useState(false);
  const [image, setImage] = useState("");
  const [uploadedDoc, setUploadedDoc] = useState('')
  const [finalHalfPayment, setFinalHalfPayment] = useState(false)
  const navigate = useNavigate();

  const getOrder = async () => {
    try {
      setLoading(true);
      const { data: res } = await getOrderById(searchParams.get("orderId"));
      // console.log(res?.data, res, "orderObj");
      if (res) {

        // console.log(halfPayment, res?.data?.halfPayment, res?.data?.payment, res?.data?.halfPayment, res?.data?.payment == "PENDING")
        // console.log((halfPayment && !orderObj?.halfPayment && orderObj?.payment == "PENDING"))

        if (res?.data?.payment == orderStatusObj.HALFPAYMENTRECIEVED)
          setFinalHalfPayment(true)
        setLoading(false);
        setOrderObj(res.data);
        setHalfPayment(res.data.halfPayment);
      }
    } catch (error) {
      setLoading(false);
      toastError(error);
    }
  };

  const Payment = async () => {
    try {
      setLoading(true);
      if (image == "") {
        toastError("Copy of payment is mandatory");
        setLoading(false);
        return;
      }
      let obj = {
        halfPayment,
        image,
      };
      const { data: res } = await updatePayment(
        obj,
        searchParams.get("orderId")
      );
      if (res.success) {
        setLoading(false);
        // toastSuccess(res?.message);
        setQuoteModal(true)
        // getOrder()
        // handleRedirect(res.data, res.orderId)
        // setOrderObj(res.data);
      }
    } catch (error) {
      setLoading(false);
      toastError(error);
    }
  };


  useEffect(() => {
    // console.log((halfPayment && !orderObj?.halfPayment && orderObj?.payment == "PENDING"))

  }, [halfPayment])
  // updatePayment

  useEffect(() => {
    getOrder();
  }, []);

  const getBase64 = (file, cb) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
      cb(reader.result);
    };
    reader.onerror = function (error) {
      // console.log('Error: ', error)
    };
  };
  const handleFileSelection = (event) => {
    event.preventDefault();
    if (event.target.files[0]) {
      getBase64(event.target.files[0], (result) => {
        setImage(result);
        setUploadedDoc(event.target.files[0].name)
        // if (returnOriginal) {
        //   onFileChange(event.target.files[0]);
        // } else {
        //   onFileChange(result);
        // }
      });
    }
  };

  return (
    <main>
      <section className="payment-page ptb-50">
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-8">
              <div className="left">
                {orderObj?.halfPayment ? (
                  <>
                    <h3 className="mb-4">Pay the balance amount</h3>
                    <div className="top">

                      <ul className="mt-4">
                        <li>
                          Please make the balance 50% payment within 7 working days to get your order dispatched.
                        </li>
                      </ul>
                    </div>
                  </>
                ) : (
                  <>
                    <h3 className="mb-4">Payment</h3>
                    <div className="top">
                      <div>
                        <div className="form-check form-check-inline">
                          <label className="pointer">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="payment"
                              checked={halfPayment}
                              disabled={orderObj?.halfPayment || (orderObj?.imageArr && orderObj?.imageArr.length > 0)}
                              onClick={() => setHalfPayment(true)}
                            />
                            Pay 50% in Advance
                          </label>
                        </div>
                        <div className="form-check form-check-inline">
                          <label className="pointer">
                            <input
                              className="form-check-input"
                              type="radio"
                              name="payment"
                              checked={!halfPayment}
                              disabled={orderObj?.halfPayment || (orderObj?.imageArr && orderObj?.imageArr.length > 0)}
                              onClick={() => setHalfPayment(false)}
                            />
                            100% Pay
                          </label>
                        </div>
                      </div>

                      <ul>
                        <li>
                          <BsCheckLg className="brown2 small me-2" />
                          In case of 50% advance, OBL will be released after
                          balance 50% paid
                        </li>
                        <li>
                          <BsCheckLg className="brown2 small me-2" />
                          Please make payment within 7 working days to confirm
                          order
                        </li>
                      </ul>
                    </div>
                  </>
                )}

                <h3 className="mt-4 mb-3">Bank Details</h3>
                <div>

                  <ul className="bank-detail bg3">
                    <li>
                      ACCOUNT NAME:{" "}
                      <span className="brown2">ARTISAGA PRIVATE LIMITED</span>
                    </li>
                    <li>
                      ACCOUNT NUMBER:{" "}
                      <span className="brown2">922020019966152</span>
                    </li>
                    <li>
                      IFSC CODE: <span className="brown2">UTIB0000571</span>
                    </li>
                    <li>
                      BANK NAME:{" "}
                      <span className="brown2">AXIS BANK</span>
                    </li>
                    <li>
                      BRANCH NAME: <span className="brown2">HIRANANDANI ESTATE, THANE</span>
                    </li>
                    <li>
                      SWIFT CODE: <span className="brown2">AXISINBB571</span>
                    </li>
                    <li>
                      AD CODE: <span className="brown2">6360561-6010009</span>
                    </li>
                  </ul>
                  <ul className="bank-detail bg3 mt-4">
                    <li>
                      ACCOUNT NAME:{" "}
                      <span className="brown2">ARTISAGA PRIVATE LIMITED</span>
                    </li>
                    <li>
                      ACCOUNT NUMBER:{" "}
                      <span className="brown2">922793781</span>
                    </li>
                    <li>
                      PAYMENT METHOD:{" "}
                      <span className="brown2">EFT (Electronic Funds Transfer)</span>
                    </li>
                    <li>
                      ROUTING CODE: <span className="brown2">035210009</span>
                    </li>
                    <li>
                      BANK NAME:{" "}
                      <span className="brown2" style={{ textTransform: "uppercase" }}>Digital Commerce Bank</span>
                    </li>
                    <li style={{ textTransform: "uppercase" }}>
                      Bank Address: <span className="brown2" style={{ textTransform: "uppercase" }}>736 Meridian Road N.E, Calgary, Alberta, CA</span>
                    </li>
                  </ul>
                  <ul className="bank-detail bg3 mt-4">
                    <li style={{ textTransform: "uppercase" }}>
                      Payment method:{" "}
                      <span style={{ textTransform: "uppercase" }} className="brown2">ACH Transfer</span>
                    </li>
                    <li style={{ textTransform: "uppercase" }}>
                      ACCOUNT NAME:{" "}
                      <span style={{ textTransform: "uppercase" }} className="brown2">ARTISAGA PRIVATE LIMITED</span>
                    </li>
                    <li style={{ textTransform: "uppercase" }}>
                      Account number:{" "}
                      <span className="brown2" style={{ textTransform: "uppercase" }}>8337392180</span>
                    </li>
                    <li style={{ textTransform: "uppercase" }}>
                      Routing number: <span style={{ textTransform: "uppercase" }} className="brown2">026073150</span>
                    </li>
                    <li style={{ textTransform: "uppercase" }}>
                      Account type:{" "}
                      <span className="brown2" style={{ textTransform: "uppercase" }}>Business Checking Account</span>
                    </li>
                    <li style={{ textTransform: "uppercase" }}>
                      Bank name: <span className="brown2" style={{ textTransform: "uppercase" }}>Community Federal Savings Bank (CFSB)</span>
                    </li>
                    <li style={{ textTransform: "uppercase" }}>
                      Bank Address: <span className="brown2" style={{ textTransform: "uppercase" }}>810 Seventh Avenue, New York, NY 10019, US</span>
                    </li>
                  </ul>
                </div>

              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="order-summary">
                <div className="box bg3">
                  <h5>Order Summary</h5>
                  <ul>
                    <li>
                      Subtotal <span>$ {parseInt(orderObj?.totalAmount)}</span>
                    </li>
                    <li>
                      Packaging/Handling Charges
                      <span>$ {orderObj?.handlingCharges}</span>
                    </li>
                    <li>
                      Shipping price{" "}
                      <span>
                        ${" "}
                        {orderObj?.selectedOption &&
                          orderObj?.selectedOption?.value
                          ? parseInt(orderObj?.selectedOption?.value)
                          : 0}
                      </span>
                    </li>
                    <li>
                      Order Total{" "}
                      <span>
                        ${" "}
                        {parseInt(orderObj?.totalAmount) +
                          (orderObj?.selectedOption &&
                            orderObj?.selectedOption?.value
                            ? parseInt(orderObj?.selectedOption?.value)
                            : 0) +
                          (orderObj?.handlingCharges
                            ? orderObj?.handlingCharges
                            : 0)}
                      </span>
                    </li>
                    {
                      orderObj?.halfPayment &&
                      <li>
                        Pending Amount
                        <span>
                          $ {(parseInt(orderObj?.totalAmount) +
                            (orderObj?.selectedOption &&
                              orderObj?.selectedOption?.value
                              ? parseInt(orderObj?.selectedOption?.value)
                              : 0) +
                            (orderObj?.handlingCharges
                              ? orderObj?.handlingCharges
                              : 0)) / 2}
                        </span>
                      </li>
                    }

                  </ul>
                  <form className="form mt-2 mb-3">
                    <div className="get-file">
                      <p className="mb-0 line-height-normal small">
                        <span className="text-danger">*</span>&nbsp;Please
                        upload a copy of your payment advice
                      </p>
                      <label htmlFor="payment_file" className="brown">
                        <BiUpload />
                      </label>

                      <input
                        type="file"
                        className="form-control py-2 d-none"
                        onChange={(e) => handleFileSelection(e)}
                        id="payment_file"
                      />
                    </div>
                    <div className="form-text brown">{uploadedDoc}</div>
                  </form>
                  <button
                    role="button"
                    className="btn btn-custom btn-brown w-100"
                    onClick={() => Payment()}
                  >
                    Submit
                  </button>
                </div>
                <div className="box2 bg1">
                  <ul>

                    <li>

                      For any queries, please email at <a href="mailto:info@artisaga.com">info@artisaga.com</a>
                    </li>
                    {/* <li>
                      <BsCheckLg className="brown2" />
                      Order tracking and returns available
                    </li>
                    <li>
                      <BsCheckLg className="brown2" />
                      Fast day delivery available
                    </li>
                    <li>
                      <BsCheckLg className="brown2" />
                      Order tracking and returns available
                    </li>
                    <li>
                      <BsCheckLg className="brown2" />
                      Order tracking and returns available
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Modal
        show={quoteModal}
        size="lg"
        centered
        onHide={() => { setQuoteModal(false); navigate("/") }}
      >
        {
          (halfPayment) ?

            <Modal.Body className="congrats-modal custom-modal text-center">
              <button
                type="button"
                className="btn-close end"
                aria-label="Close"
                onClick={() => { setQuoteModal(false); navigate("/") }}
              ></button>
              <img
                src={images.party}
                alt=""
                className="img-fluid img-contain"
                width={80}
              />
              <h2 className="my-3 fw-semibold">Thank You!</h2>

              <p className="mb-0 fw-bold">
                {
                  finalHalfPayment ?
                    'We have received your balance 50% amount against the order.'
                    :
                    "We have received your 50% advance against the order."
                }
              </p>
              <p className="my-3 line-height-normal">
                {
                  finalHalfPayment ?
                    "We will share the shipping bill over email and also upload in your portal."
                    :
                    "We will inform you over email once your order is ready to dispatch. You are requested to make balance 50% payment when the order is ready. Original bill of lading will be released after receiving 100% payment."
                }
              </p>
              {/* <p className="mb-0">
                We have received your balance 50% amount against the order. We will inform you once your order is dispatched. Please keep a check on your email for further updates.
              </p> */}
              {/* {
                !finalHalfPayment &&
                <p className="mb-0">
                  Original bill of loading will be released after recieving 100% payment.
                </p>
              } */}
              <p className="my-3 line-height-normal">
                Please keep a check on your email for further updates
              </p>
              <button onClick={() => navigate("/")} className="btn btn-custom btn-brown px-4 mt-4">
                Continue shopping
              </button>
            </Modal.Body>
            :
            <Modal.Body className="congrats-modal custom-modal text-center">
              <button
                type="button"
                className="btn-close end"
                aria-label="Close"
                onClick={() => { setQuoteModal(false); navigate("/") }}
              ></button>
              <img
                src={images.party}
                alt=""
                className="img-fluid img-contain"
                width={80}
              />
              <h2 className="my-3 fw-semibold">Thank You!</h2>
              <p className="mb-0 fw-semibold">
                Your order is placed successfully.
              </p>
              <p className="my-3 line-height-normal">
                We have received your 100% advance against the order.
              </p>
              <p className="my-3 line-height-normal">
                We will inform you over email once your order is ready to dispatch and share the shipping bill over email and also upload in your portal.
              </p>
              <p className="my-3 line-height-normal">
                Please keep a check on your email for further updates
              </p>
              <button onClick={() => navigate("/")} className="btn btn-custom btn-brown px-4 mt-4">
                Continue shopping
              </button>
            </Modal.Body>

        }
      </Modal>






    </main>
  );
}

export default Payment;
