import React, { useEffect } from "react";
import { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { Link, useSearchParams } from "react-router-dom";

function FAQ() {
  const [searchParams, setSearchParams] = useSearchParams();

  const [tabs, setTabs] = useState([
    {
      name: "Creating an account ",
      active: true,
      tab: 1,
    },
    {
      name: "Placing an order",
      active: false,
      tab: 2,
    },
    {
      name: "Design and Production",
      active: false,
      tab: 3,
    },
    {
      name: "Payments",
      active: false,
      tab: 4,
    },
    {
      name: "Shipping & Returns",
      active: false,
      tab: 5,
    },
  ]);

  const activeTab = (i) => {
    const temp = tabs.map((item, index) => {
      i === index ? (item.active = true) : (item.active = false);
      return item;
    });
    setTabs([...temp]);
  };

  useEffect(() => {
    // console.log(searchParams.get("active"), "asd");
    let aciveValue = searchParams.get("active");
    let hasActiveElement = tabs.some(el => el.name == aciveValue);

    if (aciveValue && hasActiveElement) {
      let tempTabs = tabs.map((el) => {
        if (el.name == aciveValue) {
          el.active = true;
        } else {
          el.active = false;
        }
        return el;
      });
      setTabs([...tempTabs]);
    }
  }, [searchParams.get("active")]);

  return (
    <main>
      <section className="faq ptb-50">
        <div className="container">
          <div className="mb-5">
            <h1>Have Any Questions?</h1>
            <p>
              If you have any queries, check out our FAQ below or contact us
              through the enquiry form
            </p>
          </div>
          <div className="row">
            <div className="col-12 col-lg-3">
              <div className="left">
                <ul className="tabs bg3 ibm">
                  {tabs.map((item, i) => {
                    return (
                      <li
                        className={`${item.active ? "active" : ""}`}
                        key={i}
                        onClick={() => activeTab(i)}
                      >
                        {item.name}
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
            <div className="col-12 col-lg-9">
              <div className="right">
                <Accordion defaultActiveKey="0" flush>
                  {tabs.map((item, i) => {
                    if (item.active && item.tab === 1) {
                      return (
                        <span key={i}>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              How do I create an account
                            </Accordion.Header>
                            <Accordion.Body>
                              Creating an account is really simple. Just go to
                              the Artisaga{" "}
                              <Link to="/Login">
                                <b> sign-up </b>{" "}
                              </Link>{" "}
                              page, enter the details as required and click on{" "}
                              <strong>
                                <b style={{ fontSize: "13px" }}>‘Create’. </b>
                              </strong>
                              That’s it! Your account is ready to use. This step
                              will take less than 2 minutes to complete.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>
                              Can I place an order without creating an account
                              on Artisaga?
                            </Accordion.Header>
                            <Accordion.Body>
                              No, having an account set up is essential to check
                              prices and place an order on Artisaga.
                            </Accordion.Body>
                          </Accordion.Item>
                        </span>
                      );
                    }
                    if (item.active && item.tab === 2) {
                      return (
                        <span key={i}>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              How can I place a wholesale order online on
                              Artisaga?
                            </Accordion.Header>
                            <Accordion.Body>
                              You can place an order online by logging in with
                              your account on Artisaga. To place an order,
                              <ol>
                                <li>
                                  Add items to your cart (Minimum order value
                                  USD $250) to proceed further.
                                </li>
                                <li>
                                  After you add the items to the cart, click on{" "}
                                  <strong>
                                    <b style={{ fontSize: "13px" }}>
                                      {" "}
                                      ‘Request for a Final Quote’{" "}
                                    </b>
                                  </strong>
                                  and enter all the required details along with
                                  choosing the preferable
                                  <strong>
                                    <b style={{ fontSize: "13px" }}>
                                      {" "}
                                      Delivery type
                                    </b>
                                  </strong>{" "}
                                  and{" "}
                                  <strong>
                                    <b style={{ fontSize: "13px" }}>
                                      Method of shipment.
                                    </b>
                                  </strong>{" "}
                                  (You can choose multiple options in{" "}
                                  <strong>
                                    {" "}
                                    <b style={{ fontSize: "13px" }}>
                                      Delivery type{" "}
                                    </b>
                                  </strong>{" "}
                                  and
                                  <strong>
                                    <b style={{ fontSize: "13px" }}>
                                      {" "}
                                      Method of shipment
                                    </b>
                                  </strong>{" "}
                                  to get the quote.
                                </li>
                                <li>
                                  After our team receives your submitted order,
                                  we will work on getting the best shipping
                                  rates with our trusted partners and submit
                                  back the sheet to you in the next 48 hours.
                                </li>
                                <li>
                                  Once your order sheet is submitted with
                                  shipping costs as requested by you, we shall
                                  intimate you over email. Alternatively, you
                                  can also check your ‘My orders’ by logging in.
                                </li>
                                <li>
                                  After receiving the order sheet with
                                  comparable shipping cost (as requested by
                                  you), choose
                                  <strong>
                                    <b style={{ fontSize: "13px" }}> ONE</b>{" "}
                                  </strong>
                                  final shipping charge from your order sheet
                                  and click on
                                  <strong>
                                    <b style={{ fontSize: "13px" }}>
                                      {" "}
                                      ‘Accept’
                                    </b>
                                  </strong>
                                </li>
                                <li>
                                  You will be directed to the Payment page.
                                  Select either of the options:
                                  <strong>
                                    <b style={{ fontSize: "13px" }}>
                                      {" "}
                                      ‘Pay 50%Advance’
                                    </b>
                                  </strong>{" "}
                                  OR{" "}
                                  <strong>
                                    <b style={{ fontSize: "13px" }}>
                                      ‘100% Pay’{" "}
                                    </b>
                                  </strong>{" "}
                                  and make a SWIFT transfer to the Bank Details
                                  mentioned below.
                                </li>
                                <br></br>
                                <p>
                                  Note: Please pay only the amount you have
                                  selected from the above option.
                                </p>
                                <li>
                                  After making the SWIFT transfer to our
                                  mentioned account details,
                                  <strong>
                                    <b style={{ fontSize: "13px" }}>
                                      {" "}
                                      Please upload a copy of your payment
                                      advice and click on SUBMIT
                                    </b>
                                  </strong>{" "}
                                  by logging into your account.
                                  <strong>
                                    <b style={{ fontSize: "12px" }}>
                                      {" "}
                                      THIS IS A MANDATORY PROCESS. ORDER SHALL
                                      NOT BE CONFIRMED UNTIL THE PAYMENT ADVICE
                                      IS UPLOADED.
                                    </b>
                                  </strong>
                                </li>
                                <li>
                                  After uploading the payment advice,
                                  Congratulations! Your order is completed. Now
                                  sit back and relax while our team is working
                                  on executing your order. At any point of time
                                  for checking on your order status, please free
                                  to feel drop us an email at{" "}
                                  <a href="mailto:sales@artisaga.com">
                                    {" "}
                                    sales@artisaga.com
                                  </a>{" "}
                                  or Call us/WhatsApp us at +91 9833362013 with
                                  your order ID.
                                </li>
                              </ol>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>
                              Can I place wholesale order on Artisaga offline?
                            </Accordion.Header>
                            <Accordion.Body>
                              Yes, You can place the order offline by contacting
                              us at{" "}
                              <a href="mailto:sales@artisaga.com.">
                                sales@artisaga.com.
                              </a>{" "}
                              Our team will guide you with the process offline.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3">
                            <Accordion.Header>
                              What is the minimum order quantity to place the
                              order?
                            </Accordion.Header>
                            <Accordion.Body>
                              We do not offer a minimum order quantity per
                              design, but a minimum order value of USD$ 250 from
                              the entire catalogue to place an order.
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="4">
                            <Accordion.Header>
                              Can I order a sample on Artisaga?
                            </Accordion.Header>
                            <Accordion.Body>
                              Yes, You can order a sample from Artisaga. Please
                              write to us at{" "}
                              <a href="mailto:sales@artisaga.com">
                                {" "}
                                sales@artisaga.com
                              </a>{" "}
                              and mention the webpage address or link of the
                              product that you like from our website via email.
                              Please note, all samples are shipped on nominal
                              actual charges.
                            </Accordion.Body>
                          </Accordion.Item>

                          <Accordion.Item eventKey="5">
                            <Accordion.Header>
                              Why are prices different for different quantities
                              against the same product?
                            </Accordion.Header>
                            <Accordion.Body>
                              <p>
                                We offer Ex-factory pricing as per quantities.
                                Higher the units of the same product, the lesser
                                the cost per unit. Our standard pricing format
                                is as follows:
                              </p>
                              <p>0 - 50: Price A</p>
                              <p>51 - 100: Price B</p>
                              <p>101 - 300: Price C</p>
                              <p>301 - 500: Price D</p>
                              <p>501 - 2000 + : Price E</p>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="6">
                            <Accordion.Header>
                              What is the price per product for 2000 + units in
                              the same design?
                            </Accordion.Header>
                            <Accordion.Body>
                              To get the quote for per product cost if the
                              quantity is 2000+, click on the{" "}
                              <strong>
                                <b style={{ frontSize: "12px" }}>
                                  {" "}
                                  ‘get quote’{" "}
                                </b>
                              </strong>
                              available on the individual product page and enter
                              all the required details. Our team will revert
                              back in 24 hours with the best quotes depending on
                              your quantities.
                            </Accordion.Body>
                          </Accordion.Item>

                          <Accordion.Item eventKey="7">
                            <Accordion.Header>
                              Do you have a catalogue?
                            </Accordion.Header>
                            <Accordion.Body>
                              Yes, we have catalogues for each category. Please
                              write to us at{" "}
                              <a href="mailto:sales@artisaga.com">
                                {" "}
                                sales@artisaga.com
                              </a>{" "}
                              and mention your requirement. We will send our
                              e-copy of the catalogue to you via email.
                            </Accordion.Body>
                          </Accordion.Item>
                        </span>
                      );
                    }
                    if (item.active && item.tab === 3) {
                      return (
                        <span key={i}>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              Can I customise the design on Artisaga?
                            </Accordion.Header>
                            <Accordion.Body>
                              Yes, You can customise the design. To customise
                              the product, click on{" "}
                              <strong>
                                {" "}
                                <b style={{ fontSize: "13px" }}>
                                  ‘Request Customization’{" "}
                                </b>
                              </strong>
                              on the product page and enter all the required
                              details. Our team will revert back in 24 hours.
                            </Accordion.Body>
                          </Accordion.Item>

                          <Accordion.Item eventKey="1">
                            <Accordion.Header>
                              What is the production lead time?
                            </Accordion.Header>

                            <Accordion.Body>
                              Production lead time for any styles depends on the
                              quantities. It may range anywhere from 15 days -
                              45 days. For getting exact production time, please
                              email at
                              <a href="mailto:sales@artisaga.com">
                                {" "}
                                sales@artisaga.com
                              </a>{" "}
                              with the link of the product page with estimated
                              quantity.
                            </Accordion.Body>
                          </Accordion.Item>

                          <Accordion.Item eventKey="3">
                            <Accordion.Header>
                              How does Artisaga take care of Quality in
                              production?
                            </Accordion.Header>

                            <Accordion.Body>
                              We have a dedicated team that tracks the quality
                              of production at two levels - First QC is done at
                              the production unit where standardised quality
                              measures are set for each product line and each
                              unit undergoes the check as per the set measures.
                              Once the goods pass the first QC assessment at the
                              production unit, they are packed and dispatched to
                              our warehouse. Second QC is done at the warehouse
                              where goods are reopened and undergo QC and
                              export-compliant packaging. We also provide
                              third-party QC assessment and certificate from
                              Credentials on request. We also welcome the
                              buyer's QC team to assess their production units
                              either through virtual video call or in-person
                              visit at our warehouse in Mumbai.
                            </Accordion.Body>
                          </Accordion.Item>
                        </span>
                      );
                    }
                    if (item.active && item.tab === 4) {
                      return (
                        <span key={i}>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              How do I make payment on Artisaga to process my
                              order?
                            </Accordion.Header>
                            <Accordion.Body>
                              We do not have any payment gateway integrated on
                              the website. To make payment, you are requested to
                              account transfer to our SWIFT ACCOUNT DETAILS
                              mentioned on the{" "}
                              <strong>
                                <b style={{ fontSize: "13px" }}>‘PAYMENT’ </b>
                              </strong>{" "}
                              page. Once you decide the advance payment amount
                              between ‘Pay 50% in Advance’ or ‘100% Pay, Bank
                              account details are mentioned on the page below.
                              You are requested to make payment within 7 working
                              days to confirm the order. On making the payment,
                              it is mandatory to upload a copy of payment advice
                              on the payment page. Once payment advice is
                              submitted, our backend team will confirm the
                              receipt of payment and start executing the order.
                              <p>
                                To upload payment advice, simply scan the
                                original received payment advice or take a clear
                                picture of the same and upload it on your device
                                and then on the upload section on the{" "}
                                <strong>
                                  <b style={{ fontSize: "13px" }}>‘PAYMENT’ </b>
                                </strong>
                                page.
                              </p>
                              <p>
                                In case of any queries, write to us at{" "}
                                <a href="mailto:sales@artisaga.com">
                                  sales@artisaga.com
                                </a>
                              </p>
                              <p>Mentioned below Bank account details:</p>
                              <ul>
                                <li>ACCOUNT NUMBER: 922020019966152</li>
                                <li>IFSC CODE: UTIB0000571</li>
                                <li>BANK NAME: AXIS BANK</li>
                                <li>BRANCH NAME: HIRANANDANI ESTATE, THANE</li>
                                <li>SWIFT CODE: AXISINBB571</li>
                                <li>AD CODE: 6360561-6010009</li>
                              </ul>
                            </Accordion.Body>
                          </Accordion.Item>

                          <Accordion.Item eventKey="1">
                            <Accordion.Header>
                              Why do I have to Request for Quote for placing an
                              order?
                            </Accordion.Header>

                            <Accordion.Body>
                              All the product prices mentioned on the product
                              page are USD $ Ex-Factory cost, which does not
                              include shipping and other export costs. We offer
                              final prices as per your convenience which could
                              be either Ex-Factory, F.O.B Nhava Sheva or C.I.F
                              by waterways/airways. To quote the final offering
                              on various costs for your comparison, we need to
                              know what shipment type you are interested in, so
                              accordingly our backend team can work on final
                              offerings in cost. If you have your business
                              relation with any agent/shipping company in India
                              and would want us to only take care of production,
                              you can select Ex-Factory costing. We recommend
                              getting quotations from us for all shipment types
                              and then compare the quotes to make a decision.
                            </Accordion.Body>
                          </Accordion.Item>

                          <Accordion.Item eventKey="2">
                            <Accordion.Header>
                              Do you offer any credit period (NET TERMS) for
                              payments for buyers?
                            </Accordion.Header>
                            <Accordion.Body>
                              No, we do not offer a credit period (NET TERMS)
                              for payments. We have options either to pay 100%
                              Advance or 50% Advance and balance on CAD (Cash
                              Against Documents) basis. In case of higher
                              Invoice value, we can reduce the advance amount to
                              30% and balance on CAD (Cash Against Documents)
                              basis. Please write to us at{" "}
                              <a href="mailto:sales@artisaga.com">
                                sales@artisaga.com
                              </a>{" "}
                              with a request to change the payable advance
                              amount.
                            </Accordion.Body>
                          </Accordion.Item>
                        </span>
                      );
                    }
                    if (item.active && item.tab === 5) {
                      return (
                        <span key={i}>
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              How can I track my order?
                            </Accordion.Header>
                            <Accordion.Body>
                              <p>
                                After your order is dispatched, we will email
                                the shipping bill and scanned copy of Bill of
                                Lading on your registered email id.
                                Alternatively, you can find the same in ‘My
                                orders’ by logging in.{" "}
                              </p>
                            </Accordion.Body>
                          </Accordion.Item>

                          <Accordion.Item eventKey="1">
                            <Accordion.Header>
                              What are the geographies Artisaga delivers?
                            </Accordion.Header>

                            <Accordion.Body>
                              <p>
                                {" "}
                                Artisaga ships all over the world. Shipping
                                costs largely vary depending on the weight and
                                destination.{" "}
                              </p>
                            </Accordion.Body>
                          </Accordion.Item>

                          <Accordion.Item eventKey="2">
                            <Accordion.Header>
                              What if my goods are damaged in transit?
                            </Accordion.Header>
                            <Accordion.Body>
                              <p>
                                Artisaga strictly follows export-compliant
                                packaging and does a drop-test before shipping
                                the goods. We take cargo insurance for every
                                shipment. In case of damage in transit, apart
                                from insurance claim from the assigned insurance
                                company, Artisaga is not responsible for any
                                monetary loss. We do not initiate refunds for
                                any goods damaged in transit.
                              </p>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="3">
                            <Accordion.Header>
                              Can I return my order?
                            </Accordion.Header>
                            <Accordion.Body>
                              <p>
                                We only accept returns in the case of defective
                                merchandise. To initiate returns, please write
                                to us with clear images of the defects at{" "}
                                <a href="mailto:sales@artisaga.com">
                                  {" "}
                                  sales@artisaga.com
                                </a>{" "}
                                After assessing the defect, we will credit the
                                return amount, replace the goods or issue a
                                credit note for future order.
                              </p>
                            </Accordion.Body>
                          </Accordion.Item>
                        </span>
                      );
                    }
                  })}
                </Accordion>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default FAQ;
