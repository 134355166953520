import React, { useContext, useState } from "react";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaPhoneAlt,
  FaTwitter,
} from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import { ImLocation } from "react-icons/im";
import { LoadingContext } from "../App";
import { addContactInfo } from "../services/contactInfo.service";
import { toastError, toastSuccess } from "../utils/toastUtils";

function Contact() {

  const [loading, setLoading] = useContext(LoadingContext);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [message, setMessage] = useState("");

  const handleAddContactsInfo = async () => {
    try {
      setLoading(true)
      if (name == "") {
        toastError("Name is mandatory !!!")
        setLoading(false)
        return
      }
      if (email == "") {
        toastError("Email is mandatory !!!")
        setLoading(false)
        return
      }
      if (companyName == "") {
        toastError("Company Name is mandatory !!!")
        setLoading(false)
        return
      }
      if (message == "") {
        toastError("Message is mandatory !!!")
        setLoading(false)
        return
      }
      let obj = {
        name,
        email,
        companyName,
        message,
      }

      let { data: res } = await addContactInfo(obj)
      if (res.message) {
        toastSuccess(res.message)
        setLoading(false)
      }
    }
    catch (err) {
      toastError(err)
      setLoading(false)
    }
  }


  return (
    <main>
      <section className="ptb-50 contact-us">
        <div className="container">
          <div className="text-center mb-5">
            <h1 className="title">Connect With Us</h1>
            <p>
              Fill the form mentioned below and we will contact you within 24 hours
            </p>
          </div>
          <div className="row">
            <div className="col-12 col-lg-6">
              <div className="bottom">
                <h4>Get in Touch</h4>
                <ul className="links">
                  <li>
                    <div className="inner">
                      <div className="icon">
                        <FaPhoneAlt />
                      </div>
                      <div className="d-flex gap-2">
                        <a href="tel:+91 9833362013">+91 9833362013</a>

                      </div>
                    </div>
                  </li>
                  <li>
                    <div className="inner">
                      <div className="icon">
                        <GrMail />
                      </div>
                      <a href="mailto:info@artisaga.com">
                        info@artisaga.com </a>
                    </div>
                  </li>
                  <li>
                    <div className="inner">
                      <div className="icon">
                        <ImLocation />
                      </div>
                      <a
                        href="https://www.google.co.in/maps/place/Hiranandani+Estate,+Thane+West,+Thane,+Maharashtra/@19.2698977,72.959899,12.78z/data=!4m5!3m4!1s0x3be7bbf2c465948f:0xc7f8b5e5d69854f1!8m2!3d19.2591372!4d72.9844401?hl=en"

                        target="_blank"

                      >
                        Hiranandani estate, Thane, Mumbai 400607                      </a>
                    </div>
                  </li>
                  <li>
                    <div className="inner">
                      <ul className="social-links">
                        <li>
                          <a href="https://www.facebook.com/artisaga.official" className="icon" target="_blank">
                            <FaFacebookF />
                          </a>
                        </li>
                        <li>
                          <a href="https://www.instagram.com/artisaga.official/" className="icon" target="_blank">
                            <FaInstagram />
                          </a>
                        </li>
                        {/* <li>
                          <a href="https://twitter.com/Artisaga1" className="icon" target="_blank">
                            <FaTwitter />
                          </a>
                        </li> */}
                        <li>
                          <a href="https://www.linkedin.com/company/artisagaofficial/" className="icon" target="_blank">
                            <FaLinkedinIn />
                          </a>
                        </li>
                      </ul>
                    </div>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <form className="form row">
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                    placeholder="Name *"
                  />
                </div>
                <div className="col-12">
                  <input
                    type="email"
                    className="form-control"
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                    placeholder="Email *"
                  />
                </div>
                <div className="col-12">
                  <input
                    type="text"
                    className="form-control"
                    onChange={(e) => setCompanyName(e.target.value)}
                    value={companyName}
                    placeholder="Company name *"
                  />
                </div>
                <div className="col-12">
                  <textarea
                    rows="4"
                    className="form-control"
                    onChange={(e) => setMessage(e.target.value)}
                    value={message}
                    placeholder="Message"
                  ></textarea>
                </div>
                <div className="col-12">
                  <button type="button" onClick={() => handleAddContactsInfo()} className="btn btn-custom btn-brown px-4 mt-3">
                    Send Message
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Contact;
