import React, { useEffect } from "react";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useNavigate } from "react-router-dom";
import { getAllOrders } from "../../services/order.service";
import { orderStatusObj } from "../../utils/orderStatusObj";
import { toastError } from "../../utils/toastUtils";
import { images } from "../Utility/Images";
import { generateFilePath } from "../../utils/filepath";

function MyOrder() {
  const navigate = useNavigate();
  const columns = [
    {
      name: "Order Date",
      selector: (row) => `${new Date(row?.createdAt).toDateString()}`,
    },
    {
      name: "Order ID",
      selector: (row, i) => `${row?.orderId}`,
    },
    {
      name: "Subtotal",
      selector: (row) => `$ ${row.totalAmount}`,
    },
    {
      name: "Total",
      selector: (row) => `$ ${parseFloat(((parseFloat(row.totalAmount) + (row?.selectedOption?.value ? parseFloat(row?.selectedOption?.value) : 0)) + (row?.handlingCharges)).toFixed(2))}`,
    },
    {
      name: "Payment Status",
      selector: (row) => `${row.payment == orderStatusObj.HALFPAYMENTRECIEVED ? "50% balance pending" : row.payment == orderStatusObj.COMPLETEPAYMENTRECIEVED ? "Complete payment recieved" : "100% pending"}`,
    },
    {
      name: "Order Status",
      selector: (row) => `${row.status}`,
    },
  ];

  const [ordersArr, setOrdersArr] = useState([]);


  const handleGetAllOrders = async () => {
    try {
      let { data: res } = await getAllOrders()
      if (res.data) {
        setOrdersArr(res.data)
      }
    }
    catch (err) {
      toastError(err)
    }
  }


  useEffect(() => { handleGetAllOrders() }, [])



  const [negotiateModal, setNegotiateModal] = useState(false);

  return (
    <>
      <div className="my-order">
        <div className="title box-bg">
          <h4>Orders</h4>
        </div>
        <DataTable
          columns={columns}
          data={ordersArr}
          expandableRows
          expandableRowsComponent={ExpandableRow}
          expandableRowsComponentProps={{ setNegotiateModal }}
        />
      </div>

    </>
  );
}

export default MyOrder;

function ExpandableRow(data, setNegotiateModal) {
  // console.log(data, "asdf");
  let tempData = undefined


  if (data && data.data) {
    tempData = data.data
  }
  const [selectedOption, setSelectedOption] = useState({})

  return (
    <div className="table-expanded bg3">
      <div className="table-responsive">
        {
          tempData &&
          <table className="table table-bordered">
            <thead>
              <th>Product</th>
              <th>Description</th>
              <th>Qty</th>
              <th>Unit Price</th>
              <th>Amount</th>
            </thead>
            <tbody>
              {tempData?.items.map((item, i) => {
                return (
                  <tr key={i}>
                    <td><img src={generateFilePath(item?.image)} alt='' height={50} width={50} /></td>
                    <td>{item?.name}</td>
                    <td>{item?.quantity}</td>
                    <td>{item?.price / item?.quantity}</td>
                    <td>{item?.price}</td>
                  </tr>
                );
              })}
              <tr>
                <td colSpan={4}>Subtotal</td>
                <td>${tempData?.totalAmount}</td>
              </tr>
              <tr>
                <td colSpan={5}>
                  <div className="shipping-charger">
                    <p className="mb-2 fw-semibold">Shipping Charges</p>
                    <ul>
                      {
                        tempData.selectedOption &&
                        <li >
                          <label>
                            <div>
                              {/* <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  onChange={(e) => { setSelectedOption(el); console.log(index, e.target.checked) }}
                                /> */}
                              <p className="small fw-semibold mb-0">
                                {tempData.selectedOption.name}
                              </p>
                            </div>
                            <p className="small fw-semibold mb-0">
                              $ {tempData.selectedOption.value}
                            </p>
                          </label>
                        </li>
                      }

                      {/* {
                      data?.deliveryTypeArr && data?.deliveryTypeArr.length > 0 && data?.deliveryTypeArr.map((el, index) => {
                        return (
                          <li key={index}>
                            <label>
                              <div>
                                <p className="small fw-semibold mb-0">
                                  {el.name}
                                </p>
                              </div>
                              <p className="small fw-semibold mb-0">
                                $ {el.value}
                              </p>
                            </label>
                          </li>
                        )
                      })
                    }

                    {
                      data?.methodOfShipingArr && data?.methodOfShipingArr.length > 0 && data?.methodOfShipingArr.map((el, index) => {
                        return (
                          <li key={index}>
                            <label>
                              <div>
                                <p className="small fw-semibold mb-0">
                                  {el.name}
                                </p>
                              </div>
                              <p className="small fw-semibold mb-0">
                                $ {el.value}
                              </p>
                            </label>
                          </li>
                        )
                      })
                    } */}

                    </ul>
                  </div>
                </td>
              </tr>
              <tr>
                <td colSpan={4}>Packaging/Handling Charges</td>
                <td>${tempData?.handlingCharges}</td>
              </tr>
              <tr>
                <td colSpan={4}>Total Amt.</td>
                <td>${(parseInt(tempData.totalAmount) + (tempData?.selectedOption?.value ? parseInt(tempData?.selectedOption?.value) : 0)) + (tempData?.handlingCharges)}</td>
              </tr>
              <tr>
                <td colSpan={4}>Tracking Details</td>
                <td>{tempData.trackingId ? <a target={"_blank"} href={generateFilePath(tempData.trackingId)}>Click to open your file</a> : "Not Yet Updated by admin"}</td>
              </tr>
              <tr>
                <td colSpan={4}>Payment Status</td>
                <td>{`${tempData.payment == orderStatusObj.HALFPAYMENTRECIEVED ? "50% balance pending" : tempData.payment == orderStatusObj.COMPLETEPAYMENTRECIEVED ? "Complete payment recieved" : "100% pending"}`}</td>
              </tr>
              <tr>
                <td colSpan={4}>Status</td>
                <td>{tempData.status}</td>
              </tr>
            </tbody>
          </table>
        }

      </div>
    </div>
  );
}
