import React, { useContext, useEffect, useRef, useState } from "react";
import { Modal } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { LoadingContext } from "../../App";
import { addNegotiations, getNegotiations } from "../../services/negitiations.service";
import { getAllOrders, updateOrderById } from "../../services/order.service";
import { getDecodedToken } from "../../services/users.service";
import { orderStatusObj } from "../../utils/orderStatusObj";
import { toastError, toastSuccess } from "../../utils/toastUtils";
import { images } from "../Utility/Images";
import { generateFilePath } from "../../utils/filepath";

function WaitingForApprovalOrder() {
  const navigate = useNavigate();
  const [loading, setLoading] = useContext(LoadingContext);

  const [selectedOrder, setSelectedOrder] = useState();

  const [negotiationText, setNegotiationText] = useState("");
  const chatRef = useRef()


  const [negotiationArr, setNegotiationArr] = useState([]);
  const [currentLoggedInUserId, setCurrentLoggedInUserId] = useState("");

  useEffect(() => {
    if (selectedOrder?._id) {
      handleGetNegotiations()
    }
  }, [selectedOrder])

  const handleGetNegotiations = async () => {
    try {
      setLoading(true)


      let { data: res } = await getNegotiations(selectedOrder._id)
      if (res.data) {
        setLoading(false)
        setNegotiationArr(res.data)
        chatRef?.current?.scrollIntoView({ behavior: "smooth" })

      }
    }
    catch (err) {
      setLoading(false)
      toastError(err)
    }
  }



  const handleAddNegotiations = async () => {
    try {
      setLoading(true)
      if (negotiationText == "") {
        toastError("Please enter some text to !!")
        setLoading(false)
        return
      }

      let obj = {
        message: negotiationText,
        createdBy: currentLoggedInUserId,
        orderId: selectedOrder?._id
      }
      let { data: res } = await addNegotiations(obj)
      if (res.message) {
        setLoading(false)
        handleGetNegotiations()
      }
    }
    catch (err) {
      setLoading(false)
      toastError(err)
    }
  }



  const columns = [
    {
      name: "Order Date",
      selector: (row) => `${new Date(row?.createdAt).toDateString()}`,
    },
    {
      name: "Order ID",
      selector: (row, i) => `${row?.orderId}`,
    },
    {
      name: "Subtotal",
      selector: (row) => `$ ${row.totalAmount}`,
    },
    {
      name: "Total",
      selector: (row) => `$ ${parseFloat(((parseFloat(row.totalAmount) + (row?.selectedOption?.value ? parseFloat(row?.selectedOption?.value) : 0)) + (row?.handlingCharges)).toFixed(2))}`,
    },
    {
      name: "Payment Status",
      selector: (row) => `${row.payment == orderStatusObj.HALFPAYMENTRECIEVED ? "50% balance pending" : row.payment == orderStatusObj.COMPLETEPAYMENTRECIEVED ? "Complete payment recieved" : "100% pending"}`,
    },
    // {
    //   name: "Total",
    //   selector: (row) => `$ ${row.totalAmount}`,
    // },
  ];

  const [ordersArr, setOrdersArr] = useState([]);


  const handleGetAllOrders = async () => {
    try {
      let { data: res } = await getAllOrders(`status=${orderStatusObj.ORDERINPROCESS}&status2=${orderStatusObj?.WAITINGFORAPPROVAL}`)
      if (res.data) {
        setOrdersArr(res.data)
      }
    }
    catch (err) {
      toastError(err)
    }
  }



  const [searchParams, setSearchParams] = useSearchParams();

  const openAutoNegotiate = () => {
    try {
      let negotiateValue = searchParams.get("negotiate");
      if (negotiateValue == "true") {
        let orderValue = searchParams.get("orderId");
        let orderObj = ordersArr.find(el => el._id == orderValue)
        if (orderObj) {
          setSelectedOrder(orderObj)
          setNegotiateModal(true)
        }

      }


    } catch (error) {
      toastError(error)
    }
  }

  useEffect(() => {
    if (ordersArr.length)
      openAutoNegotiate()

  }, [ordersArr])


  useEffect(() => {
    handleGetAllOrders();
    let decoded = getDecodedToken()
    if (decoded) {
      setCurrentLoggedInUserId(decoded.userId)
    }
    else {
      navigate('/Login')
      toastError('Please Login And Click on the link again')
    }
  }, [])

  const [negotiateModal, setNegotiateModal] = useState(false);




  return (
    <>
      <div className="my-order">
        <div className="title box-bg">
          <h4>Waiting For Approval</h4>
        </div>
        <DataTable
          columns={columns}
          data={ordersArr}
          expandableRows
          expandableRowsComponent={ExpandableRow}
          expandableRowsComponentProps={{ setNegotiateModal, setSelectedOrder, navigate, setLoading }}
        />
      </div>

      <Modal
        show={negotiateModal}
        size='lg'
        centered
        onHide={() => setNegotiateModal(false)}
      >
        <Modal.Body className="custom-modal">
          <button
            type="button"
            className="btn-close end"
            aria-label="Close"
            onClick={() => setNegotiateModal(false)}
          ></button>
          <h3 className="heading brown2 mb-3 text-center">Negotiate</h3>

          <ul className="chat-box">
            {
              negotiationArr && negotiationArr.length > 0 && negotiationArr.map((el, index) => {
                return (
                  <li className={el?.createdBy == currentLoggedInUserId ? "box reverse" : "box"} key={index}>
                    <img src={images.party} alt='' />
                    <div>
                      <h6 className="lexend">{el?.createdBy == currentLoggedInUserId ? "You" : "Admin"}</h6>
                      <p className="small">{el?.message}</p>
                    </div>
                  </li>
                )
              })

            }
            <div style={{ marginTop: "145px" }} ref={chatRef}></div>

          </ul>


          <form className="form row">
            <div className="col-12">
              <input
                type='text'
                className="form-control"
                placeholder="Comment"
                value={negotiationText}
                onChange={(e) => setNegotiationText(e.target.value)}
              />
            </div>
            <div className="col-12">
              <p className="mb-0 line-height-normal small">
                <span className="text-danger">*</span>&nbsp;Please reach out to
                info@artisaga.com for discussing on order.
              </p>
            </div>
            <div className="col-12 text-center">
              <button
                type="button"
                onClick={() => handleAddNegotiations()}
                className="btn btn-custom btn-brown px-4"
              >
                Submit
              </button>
              <button
                type="button"
                onClick={() => handleGetNegotiations()}
                className="btn ms-3 btn-custom btn-brown px-4"
              >
                Reload
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default WaitingForApprovalOrder;

function ExpandableRow({ data, setNegotiateModal, setSelectedOrder, navigate, setLoading }) {
  //   console.log(data, "asdf");
  const [selectedOption, setSelectedOption] = useState({})

  const [quoteModal, setQuoteModal] = useState(false);


  useEffect(() => {

    // console.log("ASDF", data)
    if (data.selectedOption) {
      setSelectedOption(data.selectedOption)
    }

  }, [data])




  const handleRejectOrder = async () => {
    try {
      setLoading(true)
      let obj = {
        status: orderStatusObj?.REJECTED
      }

      let { data: res } = await updateOrderById(data._id, obj)
      if (res.message) {
        setLoading(false)
        toastSuccess(res.message)
      }
    }
    catch (err) {
      setLoading(false)
      toastError(err)
    }
  }




  const handleRedirectToPayment = async () => {
    try {
      setLoading(true)
      if (!selectedOption || !selectedOption?.value) {
        toastError("Please select atleast one option to negotiate");
        setLoading(false)
        return
      }




      let obj = {
        selectedOption: selectedOption,

      }

      let { data: res } = await updateOrderById(data._id, obj)
      if (res.message) {
        setLoading(false)
        toastSuccess(res.message)
        navigate(`/Payment?orderId=${data._id}`)
      }
    }
    catch (err) {
      setLoading(false)
      toastError(err)
    }
  }




  const handleOpenNegotiateModal = () => {
    setSelectedOrder(data)
    if (selectedOption?.value) {
      setNegotiateModal(true)
    }
    else {
      toastError("Please select atleast one option to negotiate");
      return
    }
  }





  return (
    <div className="table-expanded bg3">
      <div className="table-responsive">
        <table className="table table-bordered">
          <thead>
            <th>Product</th>
            <th>Description</th>
            <th>Qty</th>
            <th>Unit Price</th>
            <th>Amount</th>
          </thead>
          <tbody>
            {data?.items.map((item, i) => {
              return (
                <tr key={i}>
                  <td><img src={generateFilePath(item.image)} alt='' height={50} width={50} /></td>
                  <td>{item?.name}</td>
                  <td>{item?.quantity}</td>
                  <td>{item?.price / item?.quantity}</td>
                  <td>{item?.price}</td>
                </tr>
              );
            })}
            <tr>
              <td colSpan={4}>Packaging/Handling Charges</td>
              <td>${data?.handlingCharges}</td>
            </tr>
            <tr>
              <td colSpan={4}>Subtotal</td>
              <td>${data?.totalAmount + data?.handlingCharges}</td>
            </tr>
            {data?.deliveryTypeArr && data?.deliveryTypeArr.length > 0 && <tr>
              <td colSpan={5}>
                <div className="shipping-charger">
                  <p className="mb-2 fw-semibold">Shipping Charges</p>
                  <ul>
                    {
                      data?.deliveryTypeArr && data?.deliveryTypeArr.length > 0 && data?.deliveryTypeArr.map((el, index) => {
                        return (
                          <li key={index}>
                            <label>
                              <div>
                                <input
                                  className="form-check-input"
                                  type="radio"
                                  name="flexRadioDefault"
                                  checked={data?.selectedOption && el.name == selectedOption.name}
                                  disabled={data?.selectedOption}
                                  onChange={(e) => { setSelectedOption(el); }}
                                />
                                <p className="small fw-semibold mb-0">
                                  {el.name}
                                </p>
                              </div>
                              <p className="small fw-semibold mb-0">
                                $ {el.value}
                              </p>
                            </label>
                          </li>
                        )
                      })
                    }
                  </ul>
                </div>
              </td>
            </tr>}

            <tr>
              <td colSpan={4}>Total Amt.</td>
              <td>${(parseInt(data.totalAmount) + (selectedOption?.value ? parseInt(selectedOption?.value) : 0) + (data?.handlingCharges))}</td>
            </tr>
            <tr>
              <td colSpan={4}>Payment Status</td>
              <td>{`${data.payment == orderStatusObj.HALFPAYMENTRECIEVED ? "50% balance pending" : data.payment == orderStatusObj.COMPLETEPAYMENTRECIEVED ? "Complete payment recieved" : "100% pending"}`}</td>
            </tr>
            <tr>
              <td colSpan={4}>Status</td>
              <td>{`${data.status}`}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <div className="btns">
        {
          !data?.selectedOption &&
          <button type="button" onClick={() => handleRejectOrder()} className="btn btn-custom btn-danger btn-sm">
            Reject
          </button>
        }
        {
          !data?.selectedOption &&
          <button
            type="button"
            className="btn btn-custom btn-brown-outline btn-sm"
            onClick={() => handleOpenNegotiateModal()}
          >
            Negotiate
          </button>
        }
        {data.payment != orderStatusObj.COMPLETEPAYMENTRECIEVED &&
          <div onClick={() => handleRedirectToPayment()} type="button" className="btn btn-custom btn-brown btn-sm">
            {data.halfPayment ? "Pay remaining balance" : "Accept"}
          </div>
        }
      </div>








    </div>
  );
}
