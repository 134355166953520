// 

import axios from "axios";
import { axiosApiInstance } from "./axios.service";
import { url } from "./url.service";

const serverUrl = url + "/quote";

export const addQuote = (formData) => {
    return axiosApiInstance.post(serverUrl + "/", formData);
};

export const get = () => {
    return axiosApiInstance.get(`${serverUrl}/`);
};

export const deleteById = (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

