import React from "react";

function TermCondition() {
  return (
    <main>
      <section className="ptb-50">
        <div className="container">
          <h1 className="mb-4">Terms & Conditions</h1>
          <h4>Welcome to ARTISAGA!</h4>
          <p>
            This agreement outlines the terms and conditions between you (the
            user or buyer) and ARTISAGA PRIVATE LIMITED (referred to as
            "ARTISAGA", "we" or "our"), which govern your access and use of the
            platform.
          </p>
          <p>
            By using our platform, you agree to comply with these terms of use
            and our privacy policy, which may change at any time without prior
            notice. You are encouraged to carefully review these agreements
            before making any transactions on our platform.
          </p>
          <p>
            Additional terms and conditions may apply to specific services or
            features, and you agree to abide by them. If there is a conflict
            between these terms and the terms posted for a specific portion of
            the platform or service, the latter will control your use of that
            portion. We own and operate the platform, which provides a medium
            for buyers to purchase Indian artisanal products wholesale and
            provides services for packaging, quality control, and logistics
            management.
          </p>
          To place an order with us, you must be at least 18 years old and
          register on the platform, providing accurate and complete information.
          We reserve the right to refuse or cancel registration at our sole
          discretion.
          <p>
            We strive to provide a seamless platform experience, free from any
            disruption, interruption, and delay. However, we may send you
            updates, promotional materials, and other information related to the
            platform and services, which you can opt out of at any time.
          </p>
          <p>
            You agree to use the platform only for lawful purposes and in
            compliance with these terms and conditions.
          </p>
          <p>
            We reserve the right to terminate your access to the platform at any
            time and for any reason. If you wish to terminate your account,
            please contact us at info@artisaga.com.
          </p>
          <p>
            We own all content and images on the platform, and all icons and
            logos are our trademarks or service marks.
          </p>
        </div>
      </section>
    </main>
  );
}

export default TermCondition;
