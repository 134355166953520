import logo from "../../assets/images/logo.png";
import product from "../../assets/images/product.png";
import kitchen from "../../assets/images/kitchen.png";
import map from "../../assets/images/map.png";
import party from "../../assets/images/party.png";
import error from "../../assets/images/error.png";
import ourphilosophy1 from "../../assets/images/OUR-PHILOSOPHY.png"
import ourphilosophy2 from "../../assets/images/Our-Philosophy_2.png"
import NIDHI_PHOTO from "../../assets/images/NIDHI_PHOTO.png"
import icon1 from "../../assets/images/SUSTAINABLE.png"
import icon2 from "../../assets/images/ARTISANAL.png"
import icon3 from "../../assets/images/FAIR-SOCIAL.png"
import icon4 from "../../assets/images/TRANSPARENT.png"
import blogimg from "../../assets/images/blog_imgnew.png"
import blogimg1 from "../../assets/images/blog_imgnew1.png"
import blogimg12 from "../../assets/images/blog_imgnew12.png"
import blogimg13 from "../../assets/images/blog_imgnew13.png"
export const images = {
  blogimg1,
  blogimg12,
  blogimg13,
  blogimg,
  logo,
  product,
  kitchen,
  map,
  party,error,
  ourphilosophy1,
  ourphilosophy2,
  NIDHI_PHOTO,
  icon1,
  icon2,
  icon3,
  icon4,
};
