import React, { useContext, useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { AiFillLock } from "react-icons/ai";
import { BsInfoCircleFill, BsLockFill } from "react-icons/bs";
import { GiCommercialAirplane } from "react-icons/gi";
import { RiShip2Fill } from "react-icons/ri";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
import {
  CurrentLoggedInUserContext,
  LoadingContext,
  UserCartContext,
  userIsAuthorisedContext,
} from "../App";
import { getProductById, getSimilarProductsByProductById } from "../services/product.service";
import { addProductToCart } from "../services/userCart.service";
import { getDecodedToken, getUserCart, saveUserCart } from "../services/users.service";
import { useGetCartArr } from "../utils/cartUtils";
import { generateFilePath } from "../utils/filepath";
import { toastError, toastSuccess } from "../utils/toastUtils";
import { images } from "./Utility/Images";
import { useLocation } from "react-router-dom";
import { addQuote } from "../services/quote.service";

function ShopDetail() {

  const [price, setPrice] = useState(0);
  const [quoteQuantity, setQuoteQuantity] = useState("");
  const [pincode, setPincode] = useState("");



  const navigate = useNavigate()

  const params = useParams();

  const location = useLocation();
  const [bigImg, setBigImg] = useState(images.kitchen);

  const [showrange, setshowrange] = useState(false);
  const [quantity, setQuantity] = useState(1);

  const [currentLoggedInUser, setCurrentLoggedInUser] = useContext(
    CurrentLoggedInUserContext
  );
  const [userIsAuthorised, setUserIsAuthorised] = useContext(
    userIsAuthorisedContext
  );
  const [cartArr, setCartArr] = useContext(UserCartContext);

  let value = useGetCartArr();

  const [miniImg, setMiniImg] = useState([
    images.product,
    images.kitchen,
    images.product,
    images.kitchen,
  ]);
  const [ProductTabs, setProductTabs] = useState([
    {
      name: "Description",
      active: true,
      tab: "1",
    },
    {
      name: "Specification",
      active: false,
      tab: "2",
    },
  ]);
  const [quoteModal, setQuoteModal] = useState(false);
  const [customizationModal, setCustomizationModal] = useState(false);
  const [productObj, setProductObj] = useState({});

  const [searchParams, setSearchParams] = useSearchParams();
  const [loading, setLoading] = useContext(LoadingContext);

  const [simillarProductsArr, setSimillarProductsArr] = useState([]);

  const ActiveTab = (i) => {
    const temp = ProductTabs.map((item, index) => {
      i === index ? (item.active = true) : (item.active = false);
      return item;
    });
    setProductTabs([...temp]);
  };



  const handleAddGetAQuote = async (id) => {
    try {
      setLoading(true);

      if (quoteQuantity == "") {
        toastError("Quantity is mandatory !!!")
        setLoading(false);
        return
      }
      let decoded = await getDecodedToken()
      let obj = {
        productId: productObj?._id,
        userId: decoded.userId,
        quantity: quoteQuantity,
        pincode,
      }

      let { data: res } = await addQuote(obj);
      if (res.message) {
        setLoading(false);
        toastSuccess(res.message)
        setQuoteModal(false)
        setQuoteQuantity("")
        setPincode("")
      }
    } catch (err) {
      setLoading(false);
      toastError(err);
    }
  };

  const handleGetProductById = async (id) => {
    try {
      let { data: res } = await getProductById(id);
      if (res.data) {
        setProductObj(res.data);
        let image = "";

        if (res.data.imageArr && res.data.imageArr.length > 0) {
          image = res.data.imageArr[0].image;
        }

        let tempPrice = res.data?.productQuantityRangeArr.find(el => (parseInt(el.from) <= parseInt(quantity) && parseInt(el.to) >= parseInt(quantity)) || (!el.to && parseInt(el.from) <= parseInt(quantity)))
        if (tempPrice) {
          setPrice(tempPrice.value)
        }
        else {
          setPrice(0)
        }
        setBigImg(image);
      }
    } catch (err) {
      toastError(err);
    }
  };

  const handleGetSimilarProductsByProductById = async (id) => {
    try {
      let { data: res } = await getSimilarProductsByProductById(id);
      if (res.data) {
        setSimillarProductsArr(res.data);
      }
    } catch (err) {
      toastError(err);
    }
  };

  const handleAddToCart = async () => {
    if (!quantity || quantity == "" || parseInt(quantity) <= 0) {
      toastError("Quantity should be more than 0");
      return;
    }

    let obj = {
      productId: productObj?._id,
      image: productObj?.imageArr[0]?.image,
      name: productObj?.name,
      quantity: quantity,
    };

    let tempCart = getUserCart();
    if (userIsAuthorised) {
      let { data: res } = await addProductToCart(productObj?._id, obj);
      if (res.success) {
        toastSuccess("Product Added to cart");
      }
    } else {
      if (tempCart) {
        tempCart = JSON.parse(tempCart);
        let tempProductIndex = tempCart.findIndex(
          (el) => el.productId == obj.productId
        );
        if (tempProductIndex != -1) {
          tempCart[tempProductIndex].quantity = parseInt(
            tempCart[tempProductIndex].quantity
          )
            ? parseInt(tempCart[tempProductIndex].quantity) +
            parseInt(obj.quantity)
            : parseInt(obj.quantity);
          let priceObj = productObj?.productQuantityRangeArr.find(
            (el) =>
              el.from <= tempCart[tempProductIndex].quantity &&
              el.to >= tempCart[tempProductIndex].quantity
          );
          if (priceObj) {
            tempCart[tempProductIndex].price = parseInt(priceObj.value);
            saveUserCart(JSON.stringify(tempCart));
          } else {
            toastError(
              "Product range not available for this product! please add a valid range"
            );
            return;
          }
        } else {
          let priceObj = productObj?.productQuantityRangeArr.find(
            (el) => el.from <= quantity && el.to >= quantity
          );
          // console.log(priceObj, "priceObj12");
          if (priceObj) {
            obj.price = parseInt(priceObj.value);
            obj.quantity = parseInt(obj.quantity);
            tempCart.push(obj);
            saveUserCart(JSON.stringify(tempCart));
          } else {
            toastError(
              "Product range not available for this product! please add a valid range"
            );
            return;
          }
        }
      } else {
        let priceObj = productObj?.productQuantityRangeArr.find(
          (el) => el.from <= quantity && el.to >= quantity
        );
        // console.log(priceObj, "priceObj");
        if (priceObj) {
          obj.quantity = parseInt(obj.quantity);
          obj.price = parseInt(priceObj.value);
          saveUserCart(JSON.stringify([obj]));
        } else {
          toastError(
            "Product range not available for this product! please add a valid range"
          );
          return;
        }
      }
      toastSuccess("Product Added to cart");
    }
  };
  const handleOpenQuoteModal = () => {

    if (userIsAuthorised) {
      setQuoteModal(true)
    }
    else {
      alert("Please login to use this option")
    }
  }




  const handleChangeQuantity = (value) => {
    // console.log(value, productObj?.productQuantityRangeArr)
    setQuantity(value)
    let tempPrice = productObj?.productQuantityRangeArr.find(el => (parseInt(el.from) <= parseInt(value) && parseInt(el.to) >= parseInt(value)) || (!el.to && parseInt(el.from) <= parseInt(value)))
    // console.log(tempPrice, "tempPrice")
    if (tempPrice) {
      setPrice(tempPrice.value)
    }
    else {
      setPrice(0)
    }

  }



  useEffect(() => {
    handleGetProductById(params.slug);
    handleGetSimilarProductsByProductById(params.slug);
  }, [params]);

  return (
    <main>
      <section className="product-page ptb-50">
        <div className="container">
          <div className="row gx-xxl-5">
            <div className="col-12 col-lg-6">
              <div className="left">
                <div className="big-img">
                  <img src={generateFilePath(bigImg)} alt="" />
                </div>
                <ul className="mini-img">
                  {productObj?.imageArr &&
                    [...productObj?.imageArr].map((item, i) => {
                      return (
                        <li key={i} onClick={() => setBigImg(item.image)}>
                          <img src={generateFilePath(item.image)} alt="" />
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
            <div className="col-12 col-lg-6">
              <div className="mid">
                <h2 className="product-heading line-height-normal">
                  {productObj.name}
                </h2>
                {!userIsAuthorised ? (
                  <p className="price bg3 mb-0">
                    <b>usd</b>
                    <span>
                      <AiFillLock className="brown" />
                      &nbsp;To reveal price please
                      <Link to="/Login" className="brown fw-semibold">
                        &nbsp;sign in/sign up
                      </Link>
                    </span>
                  </p>
                )
                  :
                  (<p className="price bg3 mb-0">
                    <b>usd</b>
                    <span>
                      {/* <AiFillLock className="brown" /> */}

                      {price}
                    </span>
                  </p>)
                }
                {/* {
                  productObj?.minCartAmount && productObj?.minCartAmount != "" &&
                  <p>
                    <span className="text-danger">*</span> Minimum order value
                    ${productObj?.minCartAmount}
                  </p>
                } */}
                <ul className="info">
                  {productObj?.hsn && productObj?.hsn != "" && (
                    <li>
                      HSN: <span>{productObj?.hsn}</span>
                    </li>
                  )}
                  {productObj?.sku && productObj?.sku != "" && (
                    <li>
                      SKU: <span>{productObj?.sku}</span>
                    </li>
                  )}
                  {productObj?.size && (
                    <li>
                      Size: <span>{productObj?.size}</span>
                    </li>
                  )}
                  {productObj?.material && (
                    <li>
                      Material: <span>{productObj?.material}</span>
                    </li>
                  )}
                  {productObj?.color && (
                    <li>
                      Color:
                      <span>{productObj?.color} </span>
                    </li>
                  )}
                  {productObj?.weight && (
                    <li>
                      Weight: <span>{productObj?.weight}</span>
                    </li>
                  )}
                </ul>
                <form className="form">
                  <label>Quantity</label>
                  <div className="input">
                    <input
                      type="text"
                      value={quantity}
                      onChange={(e) => { handleChangeQuantity(e.target.value) }}
                      className="form-control"
                    />
                    <h4 className="brown icon pointer mb-0">
                      <BsInfoCircleFill />
                    </h4>
                    <ul className="price-table bg3">
                      <li className="brown2 fw-semibold">
                        Range <span>Price</span>
                      </li>
                      {productObj?.productQuantityRangeArr &&
                        productObj?.productQuantityRangeArr.length > 0 &&
                        productObj?.productQuantityRangeArr
                          .sort((a, b) => a.from - b.from)
                          .map((el, index) => {
                            return (
                              <li key={index}>
                                {el.to
                                  ? `${el?.from} - ${el?.to}`
                                  : `${el.from}+`}{" "}
                                {userIsAuthorised ? (
                                  <span>${el?.value}</span>
                                ) : (
                                  <span>
                                    <BsLockFill />
                                  </span>
                                )}
                              </li>
                            );
                          })}
                    </ul>
                  </div>
                </form>
                <p>
                  <span className="text-danger">*</span> For bulk order, please fill the
                  {" "}
                  <Link
                    to="#"
                    className="brown fw-semibold"
                    onClick={(e) => handleOpenQuoteModal()}
                  >
                    get quote
                  </Link>{" "}
                  form
                </p>
                <div className="btns">
                  <button
                    onClick={() => handleAddToCart()}
                    className="btn btn-custom btn-brown hover_transprent"
                  >
                    Add To Cart
                  </button>
                  <Link
                    to={`/RequestCustomization?productId=${productObj._id}&productSlug=${productObj?.slug}`}
                    className="btn btn-custom btn-brown hover_transprent"
                  >
                    Request Customization
                  </Link>
                </div>
                <div className="modes">
                  <p className="mb-0 fw-semibold">Shipping options available</p>
                  <ul>
                    <li>
                      <GiCommercialAirplane className="brown2" />
                      Air
                    </li>

                    <li>
                      <RiShip2Fill className="brown2" />
                      Sea
                    </li>
                  </ul>
                </div>
                  <p>* We recommend pre-planning and consolidating your order above 2 CBM to ship in LCL mode by sea.</p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="product-tabs mb-50">
        <div className="container">
          <ul className="tabs">
            {ProductTabs.map((item, i) => {
              return (
                <li
                  className={item.active ? "active" : ""}
                  key={i}
                  onClick={() => ActiveTab(i)}
                >
                  {item.name}
                </li>
              );
            })}
          </ul>
          <div className="tab-description">
            {ProductTabs.map((item, i) => {
              if (item.active && item.tab === "1") {
                return (
                  <div className="tab-inner" key={i}>
                    <div
                      className="desp"
                      dangerouslySetInnerHTML={{
                        __html: productObj?.description,
                      }}
                    ></div>
                  </div>
                );
              }
              if (item.active && item.tab === "2") {
                return (
                  <div
                    className="tab-inner"
                    dangerouslySetInnerHTML={{
                      __html: productObj?.specification,
                    }}
                  >
                    {/* <ul className="info bg3">
                      <li>
                        Brand<span className="text-dark">Black Cobra</span>
                      </li>
                      <li>
                        Thickness<span className="text-dark">25 mm</span>
                      </li>
                      <li>
                        Usage/Application
                        <span className="text-dark">Home Furniture</span>
                      </li>
                      <li>
                        Grade<span className="text-dark">PMR</span>
                      </li>
                      <li>
                        Color<span className="text-dark">Brown</span>
                      </li>
                      <li>
                        Size<span className="text-dark">2440 x 1220 mm</span>
                      </li>
                      <li>
                        Wood Type
                        <span className="text-dark">Engineered Wood</span>
                      </li>
                      <li>
                        Glue Used<span className="text-dark">Phenol</span>
                      </li>
                      <li>
                        Warranty<span className="text-dark">25 Years</span>
                      </li>
                    </ul> */}
                  </div>
                );
              }
            })}
          </div>
        </div>
      </section>

      <section className="products ptb-50 bg1">
        {
          simillarProductsArr && simillarProductsArr.length > 0 &&
          <div className="container">
            <div className="title-section mb-3">
              <div>
                <h1 className="title">Similar Products</h1>
                {/* <p>simply dummy text of the printing and typesetting industry.</p> */}
              </div>
            </div>
            <div className="row gy-4">
              {
                simillarProductsArr.map((el, index) => {
                  return (
                    <div key={index} className="col-12 col-md-3">
                      <div className="product-box">
                        <div className="image">
                          <Link to={`/ShopDetail/${el?.slug}`}>
                            {/* <Link to="/ShopDetail/product=name"> */}
                            <img src={el.imageArr && el.imageArr.length > 0 && generateFilePath(el.imageArr[0].image)} alt="" />
                          </Link>
                        </div>
                        <div className="content box-bg">
                          <p className="text-white">
                            <Link to={`/ShopDetail/${el?.slug}`}>{el?.name}</Link>
                            {/* <Link to="/ShopDetail/product=name">Snowy bear ceramic planter</Link> */}
                          </p>
                          {!userIsAuthorised ? (
                            <p className="small">
                              <AiFillLock />
                              Sign up to view prices
                            </p>
                          ) : (
                            <p
                              className="small"
                              onClick={() =>
                                navigate(`/ShopDetail/${el?.slug}`)
                              }
                              style={{ cursor: "pointer" }}
                            >
                              {/* <p className="small" onClick={() => navigate(`/ShopDetail/product:${el.slug}`)} style={{ cursor: "pointer" }}> */}
                              Starting from ${el?.minPrice}
                            </p>
                          )}
                        </div>
                      </div>
                    </div>
                  )
                })
              }

            </div>
          </div>
        }
      </section>

      <Modal
        show={quoteModal}
        size="lg"
        centered
        onHide={() => setQuoteModal(false)}
      >
        <Modal.Body className="quote-modal custom-modal">
          <button
            type="button"
            className="btn-close end"
            aria-label="Close"
            onClick={() => setQuoteModal(false)}
          ></button>
          <div className="row g-0">
            <div className="col-12 col-md-6">
              <div className="left">
                <img className="w-100" src={productObj?.imageArr && productObj?.imageArr.length > 0 ? generateFilePath(productObj?.imageArr[0].image) : images.kitchen} alt="" />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="right">
                <h4 className="heading mb-3">{productObj.name}</h4>
                <form className="form row">
                  <div className="col-12">
                    <label>Quantity</label>
                    <input value={quoteQuantity} onChange={(e) => setQuoteQuantity(e.target.value)} type="text" className="form-control" />
                  </div>
                  <div className="col-12">
                    <label>Pincode</label>
                    <input value={pincode} onChange={(e) => setPincode(e.target.value)} type="text" className="form-control" />
                  </div>
                  <div className="col-12 text-center">
                    <button onClick={() => handleAddGetAQuote()} type={"button"} className="btn btn-custom btn-brown btn-sm mt-2">
                      Get Quote
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      {/* <Modal
        show={customizationModal}
        size="xl"
        centered
        onHide={() => setCustomizationModal(false)}
      >
        <Modal.Body className="quote-modal custom-modal">
          <button
            type="button"
            className="btn-close end"
            aria-label="Close"
            onClick={() => setCustomizationModal(false)}
          ></button>
          <div className="row g-0">
            <div className="col-12 col-md-6">
              <div className="left">
                <img src={productObj?.imageArr && productObj?.imageArr.length > 0  ? generateFilePath(productObj?.imageArr[0].image) :images.kitchen} alt="" className="w-100" />
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="right">
                <h4 className="heading brown mb-3">
                  {productObj?.name}
                </h4>
                <form className="form row gx-2">
                  <div className="col-12">
                    <p className="mb-0 line-height-normal small">
                      <span className="text-danger">*</span>&nbsp;Indicates a
                      required field.
                    </p>
                  </div>
                  <div className="col-12 col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Size"
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <input
                      type="color"
                      className="form-control h-100"
                      placeholder="Color"
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Quantity *"
                    />
                  </div>
                  <div className="col-12 col-md-6">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Pincode"
                    />
                  </div>
                  <div className="col-12">
                    <textarea
                      rows="2"
                      className="form-control"
                      placeholder="Comment"
                    ></textarea>
                  </div>
                  <div className="col-12 position-relative">
                    <input type="file" className="form-control" />
                    <div className="form-text brown fw-semibold">
                      Attach reference image
                    </div>
                  </div>
                  <div className="col-12">
                    <button className="btn btn-custom btn-brown px-4">
                      Submit
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal> */}
    </main >
  );
}

export default ShopDetail;
