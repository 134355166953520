import axios from "axios";
import { axiosApiInstance } from "./axios.service";
import { url } from "./url.service";
import jwt_decode from "jwt-decode";
const serverUrl = url + "/users"; // "https://aahilyabackend.ebslon.com"





export const loginUser = (formData) => {
  return axios.post(serverUrl + "/login", formData);
};

export const registerUser = (formData) => {
  return axiosApiInstance.post(serverUrl + "/register", formData);
};

export const getUser = async (query) => {
  let token = await getToken()

  let config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  return axios.get(`${serverUrl}/getById/${query}`, config);
};

export const updateUserStatus = (id, formData) => {
  return axiosApiInstance.patch(
    `${serverUrl}/updateUserStatus/${id}`,
    formData
  );
};

export const updateUserKycStatus = (id, formData) => {
  return axiosApiInstance.patch(
    `${serverUrl}/updateUserKycStatus/${id}`,
    formData
  );
};

export const updateDocuments = (id, formData) => {
  return axiosApiInstance.patch(`${serverUrl}/updateDocuments/${id}`, formData);
};
export const deleteDocument = (id, formData) => {
  return axiosApiInstance.patch(`${serverUrl}/deleteDocument/${id}`, formData);
};
export const changePassword = (id, formData) => {
  return axiosApiInstance.patch(`${serverUrl}/changePassword/${id}`, formData);
};

export const deleteUser = (id) => {
  return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`);
};

export const getSpecificCustomer = (value) => {
  return axiosApiInstance.get(
    `${serverUrl}/getSpecificCustomer?search=${value}`
  );
};

export const getById = (id) => {
  return axiosApiInstance.get(`${serverUrl}/getById/${id}`);
};

export const updateUser = (formData, id) => {
  return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData);
};

export const registerFcmToken = (formData) => {
  return axiosApiInstance.post(
    `${serverUrl}/checkAndRegisterFcmToken`,
    formData
  );
};

export const updateNotificationSetting = (formData) => {
  return axiosApiInstance.post(
    `${serverUrl}/updateNotificationSetting`,
    formData
  );
};

export const refreshToken = async (obj) => {
  return axios.post(`${serverUrl}/refreshToken`, obj)
}


//////////auth token
export const saveToken = (obj) => {
  return localStorage.setItem("user_token", obj)
}
export const getToken = () => {
  return localStorage.getItem("user_token")
}
export const getDecodedToken = () => {
  try {

    let token = localStorage.getItem("user_token")



    return jwt_decode(token)

  } catch (error) {
    return null
  }
}
export const removeToken = () => {
  return localStorage.removeItem("user_token")
}
//////////loggedin user
export const saveLoggedInUser = (obj) => {
  return localStorage.setItem("user_LoggedIn", obj)
}
export const getLoggedInUser = () => {
  return localStorage.getItem("user_LoggedIn")
}
export const removeLoggedInUser = () => {
  return localStorage.removeItem("user_LoggedIn")
}


//////////user cart
export const saveUserCart = (obj) => {
  return localStorage.setItem("User_cart", obj)
}
export const getUserCart = () => {
  return localStorage.getItem("User_cart")
}
export const removeUserCart = () => {
  return localStorage.removeItem("User_cart")
}








