import React from "react";

function PrivacyPolicy() {
  return (
    <main>
      <section className="ptb-50">
        <div className="container">
          <h1 className="mb-4">Privacy Policy</h1>
          <p>
            Our Privacy Policy at Artisaga Private Limited (referred to as “we”,
            “our” or “ARTISAGA” hereinafter) is designed to protect your privacy
            and provide you with the best wholesale shopping experience
            possible.
          </p>

          <p>
            We only collect the necessary personal information required to
            fulfil your orders and to provide you with the relevant product
            information, shipping rates, order processing, delivery updates,
            customer support, and to carry out research on our users’
            demographics. Your personal information collected online will not be
            sold or rented to anyone.
          </p>

          <p>
            You can browse our website without providing any personal
            information. However, if you decide to purchase a product, we will
            require your complete name, email address, contact information, and
            billing address. We may share your information with third-party
            banking and logistics partners to fulfil your orders, but we will
            not disclose your personal information to any other company or
            person without your consent
          </p>

          <p>
            To increase your level of security, please ensure that you log off
            from the website when you have finished using it and take
            appropriate measures to ensure that no history of your activity can
            be found on the browser/device. Artisaga will ensure that all
            information collected from you is safely and securely stored.
          </p>


          <p>
            Please note that we reserve the right to amend this Privacy Policy
            without explicit communication to users. We value your privacy and
            encourage you to read this policy and contact us at
          <a href="mailto: info@artisaga.com">  info@artisaga.com </a>if you have any questions or concerns.By using our website, you agree to accept and comply with our Privacy Policy.
          </p>

         
        </div>
      </section>
    </main>
  );
}

export default PrivacyPolicy;
