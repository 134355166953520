import toast, { Toaster } from 'react-hot-toast'
import { css } from 'styled-components';



export const toastError = (error) => {
    // console.log(typeof error?.response?.data?.message)
    if (error?.response && error?.response?.data && error?.response?.data?.message) toast.error(error?.response?.data?.message, {
        style: {
            border: '1px solid #d8d1c2',
            padding: '10px',
            color: '#000',
            backgroundColor: "#d8d1c2"
        },
        iconTheme: {
            primary: '#713200',
            secondary: '#FFFAEE',
        },
    })
    // alert(error?.response?.data?.message)
    else if (error?.message) toast.error(error?.message, {
        style: {
            border: '1px solid #d8d1c2',
            padding: '10px',
            color: '#000',
            backgroundColor: "#d8d1c2"
        },
        iconTheme: {
            primary: '#713200',
            secondary: '#FFFAEE',
        },
    })
    // alert(error.message)
    else if (error) toast.error(error, {
        style: {
            border: '1px solid #d8d1c2',
            padding: '10px',
            color: '#000',
            backgroundColor: "#d8d1c2"
        },
        iconTheme: {
            primary: '#713200',
            secondary: '#FFFAEE',
        },
    })
    // alert(error)
    // alert("ERROR")
    else toast.error('ERROR')
}
export const toastSuccess = (message) => {
    toast.success(message, {
        style: {
            border: '1px solid #d8d1c2',
            padding: '10px',
            color: '#713200',
            backgroundColor: "#d8d1c2"
        },
        iconTheme: {
            primary: '#713200',
            secondary: '#FFFAEE',
        },
    })
    // alert(message)
}
