import React, { useEffect, useState } from "react";
import { AiFillCalendar } from "react-icons/ai";
import { FaUser } from "react-icons/fa";
import { Link, useParams } from "react-router-dom";
import { getBySlug } from "../services/blog.service";
import { generateFilePath } from "../utils/filepath";
import { toastError } from "../utils/toastUtils";
import { images } from "./Utility/Images";
import DOMPurify from 'dompurify'

function BlogDetail() {

  const [blogsObj, setBlogsObj] = useState({});
  const params = useParams()


  const handleGetBlogs = async () => {
    try {
      let { data: res } = await getBySlug(params.slug)
      if (res.data) {
        setBlogsObj(res.data)
      }
    }
    catch (err) {
      toastError(err)
    }
  }




  useEffect(() => {
    handleGetBlogs()
  }, [])
  const sanitizedData = (data) => ({
    __html: DOMPurify.sanitize(data)
  })



  return (
    <main>
      <section className="ptb-50">
        <div className="container">
          <div className="blog-detail bg-transparent d-block">
            <div className="image">
              <img src={blogsObj.image ? generateFilePath(blogsObj.image) : images.product} className="w-100" alt="" />
            </div>
            <div className="content flex-1">
              <h4>
                <div>{blogsObj?.title}</div>
              </h4>
              <ul className="tags">
                <li>
                  <div className="icon">
                    <AiFillCalendar />
                  </div>
                  {new Date(blogsObj?.createdAt).toDateString()}
                </li>
                <li>
                  <div className="icon">
                    <FaUser />
                  </div>
                  {blogsObj?.author}
                </li>
              </ul>
              <p className="desp">
                <div
                  className="ql-editor view"
                  dangerouslySetInnerHTML={sanitizedData(blogsObj?.description)}
                />
              </p>

            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default BlogDetail;
