import axios from "axios";
import { axiosApiInstance } from "./axios.service";
import { url } from "./url.service";
import { getDecodedToken, getToken } from "./users.service";

const serverUrl = url + "/userOrder";

export const createOrder = async (formData) => {
  let token = await getToken()

  let config = {
    headers: {
      authorization: `Bearer ${token}`
    }
  }
  return axios.post(`${serverUrl}/createOrder`, formData, config);
};

export const getAllOrders = async (query) => {
  let decoded = await getDecodedToken()
  return axiosApiInstance.get(`${serverUrl}/getAll?userId=${decoded.userId}&${query}`);
};

export const getOrderById = async (id) => {
  return axiosApiInstance.get(`${serverUrl}/getbyId/${id}`);
};


export const updatePayment = async (obj, id) => {
  return axios.patch(`${serverUrl}/updatePayment/${id}`, obj)
}

export const orderCallback = async (obj, id) => {
  return axios.get(`${serverUrl}/paymentCB/${id}?${obj}`)
}

export const updateOrderById = async (id, obj) => {
  return axios.patch(`${serverUrl}/updateOrderById/${id}`, obj);
};

