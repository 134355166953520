import React, { useContext } from 'react'
import { LoadingContext } from '../App';

export default function Loader() {
    const [loading, setLoading] = useContext(LoadingContext);
    if (loading) {
        return (
            <div class="lds-grid" style={{ height: "100vh", width: "100vw", backgroundColor: "rgba(0,0,0,0.6)", position: "fixed", top: 0, left: 0, zIndex: 999999999999999 }}>
                <div className="animation-container">
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                    <div></div>
                </div>
            </div>
        )
    }
    else {
        return <></>
    }
}
