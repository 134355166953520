import React, { useContext, useEffect, useLayoutEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Modal from "react-bootstrap/Modal";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { AiFillHome, AiOutlineShoppingCart } from "react-icons/ai";
import { BiSearch } from "react-icons/bi";
import { FaUser } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import { add } from "../../services/bookAppointment.service";
import {
  getNestedCategories
} from "../../services/category.service";
import { getAllRelatedProductsBySlug } from "../../services/product.service";

import { LoadingContext, userIsAuthorisedContext } from "../../App";
import { getToken } from "../../services/users.service";
import { toastError, toastSuccess } from "../../utils/toastUtils";
import { images } from "../Utility/Images";

function Header() {
  const [navbartollge, setNavbartollge] = useState(false);

  const toggleOffCanvas = () => {
    setNavbartollge((navbartollge) => !navbartollge);
  };

  const [categoryArr, setCategoryArr] = useState([]);
  const [showSearchList, setShowSearchList] = useState(false);
  const [timeZone, setTimeZone] = useState("");


  const [minDate, setMinDate] = useState('2023-01-01')


  const navigate = useNavigate();

  useEffect(() => {
    let test = new Date();
    test.setDate(test.getDate() + 1)
    let str = `${test.getFullYear()}-${(test.getMonth() + 1).toString().padStart(2, '0')}-${test.getDate().toString().padStart(2, '0')}`
    setMinDate(str)

    // alert(str)
  }, [])





  const [timeZoneArr, setTimeZoneArr] = useState([

    { value: "Asia/Calcutta", label: "Asia/Calcutta" },

    { value: "America/Chicago", label: "America/Chicago" },
    { value: "America/Denver", label: "America/Denver" },
    { value: "America/Phoenix", label: "America/Phoenix" },
    { value: "America/Los_Angeles", label: "America/Los_Angeles" },
    { value: "America/Anchorage", label: "America/Anchorage" },
    { value: "Pacific/Honolulu", label: "Pacific/Honolulu" },

    { value: "Australia/Perth", label: "Australia/Perth" },
    { value: "Australia/Broken_Hill", label: "Australia/Broken_Hill" },
    { value: "Australia/Brisbane", label: "Australia/Brisbane" },

    { value: "CET", label: "CET" },
    { value: "GMT", label: "GMT" },
    { value: "EET", label: "EET" },

    { value: "Etc/Greenwich", label: "Etc/Greenwich" },
    { value: "Etc/UTC", label: "Etc/UTC" },


    { value: "US/Alaska", label: "US/Alaska" },
    { value: "US/Aleutian", label: "US/Aleutian" },
    { value: "US/Arizona", label: "US/Arizona" },
    { value: "US/East-Indiana", label: "US/East-Indiana" },
    { value: "US/Eastern", label: "US/Eastern" },
    { value: "US/Hawaii", label: "US/Hawaii" },
    { value: "US/Indiana-Starke", label: "US/Indiana-Starke" },
    { value: "US/Michigan", label: "US/Michigan" },
    { value: "US/Mountain", label: "US/Mountain" },
    { value: "US/Pacific", label: "US/Pacific" },
    { value: "US/Samoa", label: "US/Samoa" },

    { value: "Asia/Dubai", label: "Asia/Dubai" },

  ]);

  const [userIsAuthorised, setUserIsAuthorised] = useContext(
    userIsAuthorisedContext
  );


  const [loading, setLoading] = useContext(LoadingContext);

  const [appointmentModal, setAppointmentModal] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [appointmentDate, setAppointmentDate] = useState("");
  const [appointmentTime, setAppointmentTime] = useState("");
  const [comment, setComment] = useState("");

  const [filterProductByName, setFilterProductByName] = useState("");

  const [productFilterArr, setProductFilterArr] = useState([]);

  const [categoryFilterArr, setCategoryFilterArr] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleGetCategory = async () => {
    try {
      let { data: res } = await getNestedCategories();
      if (res.data) {
        setCategoryArr(res.data);
      }
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    handleGetCategory();
  }, []);

  useLayoutEffect(() => {
    window.addEventListener("resize", function () {
      setWindowWidth(window.innerWidth);
    });
    setWindowWidth(window.innerWidth);
    return () => {
      window.addEventListener("resize", function () {
        setWindowWidth(window.innerWidth);
      });
    };
  }, []);

  const handleCheckLogin = () => {
    let token = getToken();
    if (token) {
      setUserIsAuthorised(true);
    } else {
      setUserIsAuthorised(false);
    }
  };

  const handleSearchCategory = (value) => {
    if (value != "") {
      let tempArr = categoryArr.filter((el) => {
        if (`${el.name}`.toLowerCase().includes(`${value}`.toLowerCase())) {
          return true;
        } else {
          return false;
        }
      });
      setCategoryFilterArr([...tempArr]);
    } else {
      setCategoryFilterArr([]);
    }
  };

  const handleSeachProduct = async (e) => {
    let valueOf = e.target.value;
    setFilterProductByName(e.target.value);
    // handleSearchCategory()
    if (valueOf != "") {
      let { data: res } = await getAllRelatedProductsBySlug(valueOf);
      // console.log(res?.data, "12424productArr2w3423");
      if (res?.data) {
        setProductFilterArr(res?.data);
        setShowSearchList(true);
      }
    } else {
      setProductFilterArr([]);
      setShowSearchList(false);
    }
  };

  useEffect(() => {
    handleCheckLogin();
  }, []);

  const clear = () => {
    setName("");
    setEmail("");
    setCompanyName("");
    setAppointmentDate("");
    setAppointmentTime("");
    setComment("");
  };

  const handleSubmit = async (e) => {
    try {
      setLoading(true);
      e.preventDefault();
      if (name == "") {
        setLoading(false);
        toastError("name is mandatory");
        return;
      }
      if (email == "") {
        setLoading(false);
        toastError("Email is mandatory");
        return;
      } else if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          email
        )
      ) {
        setLoading(false);
        toastError("Email is not valid ");
        return;
      } else if (appointmentDate == "") {
        setLoading(false);
        toastError("Appointment data is mandatory");
        return;
      }
      else if (timeZone == "") {
        setLoading(false);
        toastError("Time zone is mandatory");
        return;
      }
      let obj = {
        name,
        email,
        companyName,
        appointmentDate,
        appointmentTime,
        comment,
        timeZone,
      };
      let { data: res } = await add(obj);
      if (res.success) {
        setLoading(false);
        toastSuccess(res?.message);
        setAppointmentModal(false);
        clear();
      }
    }
    catch (err) {
      setLoading(false);
      toastError(err)
    }
  };


  const handleNavigateToFirstLink =() => {

    if(productFilterArr && productFilterArr.length > 0){
      navigate(`/ShopDetail/${productFilterArr[0]?.slug}`)
      return
    }
    else if(categoryFilterArr && categoryFilterArr.length > 0){
      navigate(`/Shop/${categoryFilterArr[0]?.slug}`)
      return
    }
    else{
      return
    }
  }


  return (
    <>
      <header style={{ display: "unset" }}>
        <div className="topbar brown-bg">
          <Container fluid className="marquee_p">
            <p>
              <marquee behavior="" direction="">
                SHOP WHOLESALE ARTISAN-MADE HOME & LIVING PRODUCTS FOR RETAILERS
                | MINIMUM ORDER VALUE $ 250 | SHIPPING WORLDWIDE | EMAIL AT{" "}
                <a href="mailto:info@artisaga.com"> info@artisaga.com </a>
              </marquee>
            </p>
          </Container>
        </div>
        <div className="middle-bar bg3">
          <Container fluid>
            <Link to="/" className="navbar-brand">
              <img src={images.logo} alt="" className="main-logo" />
            </Link>
            {windowWidth > 992 && (
              <form className="search-bar">
                <div
                  className="position-relative"
                  onFocus={() => setShowSearchList(true)}
                // onBlur={() => setShowSearchList(false)}
                >
                  <div className="form-control flex-1">
                    <div className="icon">
                      <BiSearch />
                    </div>
                    <input
                      type="search"
                      placeholder="Search"
                      aria-label="Search"
                      value={filterProductByName}
                      onChange={(e) => {
                        handleSeachProduct(e);
                        handleSearchCategory(e.target.value);
                      }}
                    />
                  </div>
                  {showSearchList && (
                    <ul className="search-form-list bg2">
                      {productFilterArr &&
                        productFilterArr.length > 0 &&
                        productFilterArr.map((el, index) => {
                          return (
                            <li key={index}>
                              <Link
                                onClick={() => {
                                  setCategoryFilterArr([]);
                                  setProductFilterArr([]);
                                  setFilterProductByName("");
                                  setShowSearchList(false);
                                }}
                                to={`/ShopDetail/${el?.slug}`}
                              >
                                {el?.name}
                              </Link>
                            </li>
                          );
                        })}
                      {categoryFilterArr &&
                        categoryFilterArr.length > 0 &&
                        categoryFilterArr.map((el, index) => {
                          return (
                            <>
                              <li key={index}>
                                <Link
                                  onClick={() => {
                                    setCategoryFilterArr([]);
                                    setProductFilterArr([]);
                                    setFilterProductByName("");
                                  }}
                                  to={`/Shop/${el?.slug}`}
                                >
                                  {el?.name}
                                </Link>
                              </li>
                            </>
                          );
                        })}
                    </ul>
                  )}
                </div>
                <button onClick={()=> handleNavigateToFirstLink()} className="btn btn-custom btn-brown rounded-0 px-4">
                  Search Products
                </button>
              </form>
            )}
            <ul className="right">
              <li>
                <Link to="/Cart" className="icon">
                  <AiOutlineShoppingCart />
                </Link>
              </li>
              {userIsAuthorised && (
                <li>
                  <Link to="/Account?tab=My%20Account" className="icon">
                    <FaUser />
                  </Link>
                </li>
              )}
              {windowWidth > 992 && (
                <>
                  <li>
                    <Link
                      to="#"
                      className="btn btn-brown-outline btn-sm"
                      onClick={() => setAppointmentModal(true)}
                    >
                     Schedule a call
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="/EquiryForm"
                      className="btn btn-brown-outline btn-sm"
                    >
                      Request Inquiry
                    </Link>
                  </li>
                </>
              )}
              {!userIsAuthorised && (
                <li>
                  <Link to="/Login" className="btn btn-brown-outline btn-sm">
                    Login
                  </Link>
                </li>
              )}
              {windowWidth < 992 && (
                <li>
                  <Navbar
                    onToggle={() => toggleOffCanvas()}
                    expanded={navbartollge}
                    className="bottom-bar"
                    expand="xl"
                  >
                    <Container fluid>
                      <Navbar.Toggle aria-controls="bottomNavbar-expand-xl" />
                      <Navbar.Offcanvas
                        id="bottomNavbar-expand-xl"
                        aria-labelledby="bottomNavbarLabel-expand-xl"
                        placement="end"
                      >
                        <Offcanvas.Header closeButton>
                          <Offcanvas.Title id="bottomNavbarLabel-expand-xl">
                            <Link
                              onClick={() => setNavbartollge(false)}
                              to="/" className="navbar-brand">
                              <img
                                src={images.logo}
                                alt=""
                                className="main-logo"
                              />
                            </Link>
                          </Offcanvas.Title>
                        </Offcanvas.Header>
                        <Offcanvas.Body>
                          <Nav className="flex-grow-1">
                            <Link
                              onClick={() => setNavbartollge(false)}
                              className="nav-link icon"
                              to="/"
                            >
                              <AiFillHome />
                            </Link>
                            {categoryArr &&
                              categoryArr.length > 0 &&
                              categoryArr.map((el, index) => {
                                return (
                                  <div
                                    key={index}
                                    className="nav-item dropdown"
                                  >
                                    <Link
                                      onClick={() => setNavbartollge(false)}
                                      className="nav-link"
                                      role="button"
                                      data-bs-toggle="dropdown"
                                      aria-expanded="false"
                                      // to={`/Shop?categoryId=${el._id}`}
                                      to={`/Shop/${el?.slug}`}
                                    >
                                      {el?.name}
                                    </Link>
                                    {el.subCategoryArr && (
                                      <ul className="dropdown-menu">
                                        {el.subCategoryArr?.length > 0 &&
                                          el.subCategoryArr.map(
                                            (subcategory, indexX) => (
                                              <li key={indexX}>
                                                <Link
                                                  onClick={() =>
                                                    setNavbartollge(false)
                                                  }
                                                  className="dropdown-item"
                                                  // to={`/Shop?categoryId=${subcategory._id}`}
                                                  to={`/Shop/${subcategory?.slug}`}
                                                >
                                                  {subcategory?.name}
                                                </Link>
                                              </li>
                                            )
                                          )}
                                      </ul>
                                    )}
                                  </div>
                                );
                              })}
                            <Link
                              onClick={() => setNavbartollge(false)}
                              className="nav-link"
                              to="/Blogs"
                            >
                              Blogs
                            </Link>
                            <li>
                              <a
                                href="https://artisagaofficial.faire.com/user/sign-up?signUp=direct"
                                target="_blank"
                                className="nav-link btn btn-brown btn-sm hover_transprent"
                                // onClick={() => setAppointmentModal(true)}
                                style={{ padding: "10px 13px", color: '#000' }}
                              >
                                Shop on Faire
                              </a>
                            </li>
                            <li>
                              <a
                                href="https://core.poweredbypeople.io/core/api/common/embed/artisaga-pvt-ltd?source=artisaga.com&campaign=wholesalewidget&discount_amount=100"
                                target="_blank"
                                className="nav-link btn btn-brown btn-sm hover_transprent"
                                // onClick={() => setAppointmentModal(true)}
                                style={{ padding: "10px 13px", color: '#000' }}
                              >
                                Shop on PBP
                              </a>
                            </li>
                            {windowWidth < 992 && (
                              <>
                                <Link

                                  className="btn btn-brown-outline btn-sm mt-2"
                                  to="#"
                                  onClick={() => setAppointmentModal(true)}
                                >
                                 Schedule a call
                                </Link>
                                <Link
                                  onClick={() => setNavbartollge(false)}
                                  className="btn btn-brown-outline btn-sm mt-2"
                                  to="/EquiryForm"
                                >
                                  Request Inquiry
                                </Link>
                              </>
                            )}
                          </Nav>
                        </Offcanvas.Body>
                      </Navbar.Offcanvas>
                    </Container>
                  </Navbar>
                </li>
              )}
            </ul>
          </Container>
        </div>

        {windowWidth < 992 && (
          <div className="bg1 px-2 mobile_searcbar middle-bar py-2">
            <form className="search-bar">
              <div
                className="position-relative"
                onFocus={() => setShowSearchList(true)}
              // onBlur={() => setShowSearchList(false)}
              >
                <div className="form-control flex-1">
                  <div className="icon">
                    <BiSearch />
                  </div>
                  <input
                    type="search"
                    placeholder="Search"
                    aria-label="Search"
                    value={filterProductByName}
                    onChange={(e) => {
                      handleSeachProduct(e);
                      handleSearchCategory(e.target.value);
                    }}
                  />
                </div>
                {showSearchList && (
                  <ul className="search-form-list bg2">
                    {productFilterArr &&
                      productFilterArr.length > 0 &&
                      productFilterArr.map((el, index) => {
                        return (
                          <li key={index}>
                            <Link
                              onClick={() => {
                                setCategoryFilterArr([]);
                                setProductFilterArr([]);
                                setFilterProductByName("");
                                setShowSearchList(false);
                              }}
                              to={`/ShopDetail/${el?.slug}`}
                            >
                              {el?.name}
                            </Link>
                          </li>
                        );
                      })}
                    {categoryFilterArr &&
                      categoryFilterArr.length > 0 &&
                      categoryFilterArr.map((el, index) => {
                        return (
                          <>
                            <li key={index}>
                              <Link
                                onClick={() => {
                                  setCategoryFilterArr([]);
                                  setProductFilterArr([]);
                                  setFilterProductByName("");
                                }}
                                to={`/Shop/${el?.slug}`}
                              >
                                {el?.name}
                              </Link>
                            </li>
                          </>
                        );
                      })}
                  </ul>
                )}
              </div>
              <button onClick={()=> handleNavigateToFirstLink()} className="btn btn-custom btn-brown rounded-0 px-4 btnsmsearch">
                Search Products
              </button>
            </form>
          </div>
        )}

        {windowWidth > 992 && (
          <Navbar className="bottom-bar sticky-top bg1" expand="xl">
            <Container fluid>
              <Navbar.Toggle aria-controls="bottomNavbar-expand-xl" />
              <Navbar.Offcanvas
                id="bottomNavbar-expand-xl"
                aria-labelledby="bottomNavbarLabel-expand-xl"
                placement="end"
              >
                <Offcanvas.Header closeButton>
                  <Offcanvas.Title id="bottomNavbarLabel-expand-xl">
                    Offcanvas
                  </Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                  <Nav className="flex-grow-1">
                    <Link className="nav-link icon" to="/">
                      <AiFillHome />
                    </Link>
                    {categoryArr &&
                      categoryArr.length > 0 &&
                      categoryArr.map((el, index) => {
                        return (
                          <div key={index} className="nav-item dropdown">
                            <Link
                              className="nav-link"
                              role="button"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                              // to={`/Shop?categoryId=${el._id}`}
                              to={`/Shop/${el?.slug}`}
                            >
                              {el?.name}
                            </Link>
                            {el.subCategoryArr && (
                              <ul className="dropdown-menu">
                                {el.subCategoryArr?.length > 0 &&
                                  el.subCategoryArr.map(
                                    (subcategory, indexX) => (
                                      <li key={indexX}>
                                        <Link
                                          className="dropdown-item"
                                          // to={`/Shop?categoryId=${subcategory._id}`}
                                          to={`/Shop/${subcategory?.slug}`}
                                        >
                                          {subcategory?.name}
                                        </Link>
                                      </li>
                                    )
                                  )}
                              </ul>
                            )}
                          </div>
                        );
                      })}
                    <Link className="nav-link" to="/Blogs">
                      Blogs
                    </Link>
                    <li>
                      <a
                        href="https://artisagaofficial.faire.com/user/sign-up?signUp=direct"
                        target="_blank"
                        className="nav-link btn btn-brown btn-sm hover_transprent"
                        // onClick={() => setAppointmentModal(true)}
                        style={{ padding: "5px 13px", }}
                      >
                        Shop on Faire
                      </a>
                    </li>
                    <li>
                      <a
                        href="https://core.poweredbypeople.io/core/api/common/embed/artisaga-pvt-ltd?source=artisaga.com&campaign=wholesalewidget&discount_amount=100"
                        target="_blank"
                        className="nav-link btn btn-brown btn-sm hover_transprent"
                        // onClick={() => setAppointmentModal(true)}
                        style={{ padding: "5px 13px", }}
                      >
                        Shop on PBP
                      </a>
                    </li>
                    {/* <div className="nav-item dropdown">
                  <a
                    className="nav-link"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    href="/SubCategory/wooden-printing-blocks"
                  >
                    Wooden printing blocks
                  </a>
                  <ul className="dropdown-menu">
                    <li>
                      <Link
                        className="dropdown-item"
                        to="/Products/jamie-mason-block-printing"
                      >
                        Jamie Mason Block Printing
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Products/script-blocks">
                        Script Blocks
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Products/script-blocks">
                        Script Blocks
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Products/script-blocks">
                        Script Blocks
                      </Link>
                    </li>
                    <li>
                      <Link className="dropdown-item" to="/Products/script-blocks">
                        Script Blocks
                      </Link>
                    </li>
                   
                  </ul>
                </div> */}
                  </Nav>
                </Offcanvas.Body>
              </Navbar.Offcanvas>
            </Container>
          </Navbar>
        )}
      </header>

      <Modal
        show={appointmentModal}
        size="lg"
        centered
        onHide={() => setAppointmentModal(false)}
      >
        <Modal.Body className="appointment-modal custom-modal">
          <button
            type="button"
            className="btn-close end"
            aria-label="Close"
            onClick={() => setAppointmentModal(false)}
          ></button>
          <h3 className="heading mb-4 text-center">Schedule a call</h3>
          {/* <p className="date-time-zone brown2 fw-semibold position-absolute top-0 start-0 p-2 small">
          (Date-Time-Zone: Indian)
        </p> */}
          <form className="form row gx-3">
            <div className="col-12 col-md-6">
              <input
                type="text"
                className="form-control"
                placeholder="Name"
                value={name}
                onChange={(event) => setName(event.target.value)}
              />
            </div>
            <div className="col-12 col-md-6">
              <input
                type="email"
                className="form-control"
                placeholder="Email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
            <div className="col-12 col-md-12">
              <input
                type="text"
                className="form-control"
                placeholder="Company Name"
                value={companyName}
                onChange={(e) => setCompanyName(e.target.value)}
              />
            </div>
            <div className="col-12 col-md-4">
              <div className="position-relative overflow-hidden">
                <input
                  type="date"
                  className="form-control"
                  placeholder="Date"
                  value={appointmentDate}
                  min={minDate}
                  onChange={(e) => setAppointmentDate(e.target.value)}
                />
                <div className="form-text brown fw-semibold">{appointmentDate ? appointmentDate : 'Date'}</div>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <select
                value={timeZone}
                onChange={(e) => {
                  setTimeZone(e.target.value);
                }}
                className="form-select"
                aria-label="Time zone *"
              >
                <option selected disabled hidden>
                  Time zone *
                </option>
                <option value="">Time zone *</option>
                {timeZoneArr.map((el, index) => {
                  return (
                    <option value={el.value} key={index}>
                      {el.label}
                    </option>
                  );
                })}
              </select>
              {/* <ReactSelect
              className="form-control"
              placeholder="Time"
              options={timeZoneArr}
              onChange={(e) => setTimeZone(e.value)}
            /> */}
            </div>
            <div className="col-12 col-md-4">
              <div className="position-relative overflow-hidden">
                <input
                  type="time"
                  className="form-control"
                  placeholder="Time"
                  value={appointmentTime}
                  onChange={(e) => setAppointmentTime(e.target.value)}
                />
                <div className="form-text brown fw-semibold">{appointmentTime ? appointmentTime : "Time"}</div>
              </div>
            </div>
            <div className="col-12">
              <textarea
                rows="5"
                className="form-control h-100"
                placeholder="Comment"
                value={comment}
                onChange={(e) => setComment(e.target.value)}
              ></textarea>
            </div>
            <div className="col-12 text-center mt-3">
              <button
                className="btn btn-custom btn-brown px-4"
                onClick={(e) => handleSubmit(e)}
              >
                Submit
              </button>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Header;
