import React, { useContext, useEffect, useState } from "react";
import { generatePath, Link, useNavigate } from "react-router-dom";
import { images } from "./Utility/Images";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper";
import { AiFillLock } from "react-icons/ai";
import { BsArrowRight } from "react-icons/bs";
import { ImQuotesLeft, ImQuotesRight } from "react-icons/im";
import { getBanner } from "../services/banner.service";
import { toastError } from "../utils/toastUtils";
import { generateFilePath } from "../utils/filepath";
import { getProducts } from "../services/product.service";
import { getCategory } from "../services/category.service";
import { getHomeBannerActive } from "../services/Homebanner.service";

import { getTestimonials } from "../services/testimonial.service";
import { userIsAuthorisedContext } from "../App";
// import image from "../assets/images/kitchen.jpg";

function Index() {
  const [bannerObj, setBannerObj] = useState({});
  const [bestSellerProducts, setBestSellerProducts] = useState([]);
  const [newArrivalProducts, setNewArrivalProducts] = useState([]);
  const [categoryArr, setCategoryArr] = useState([]);
  const [testimonialsArr, setTestimonialsArr] = useState([]);
  const [userIsAuthorised, setUserIsAuthorised] = useContext(
    userIsAuthorisedContext
  );

  const [homeBanner, setHomeBanner] = useState("");

  const navigate = useNavigate();
  const handleGetBanner = async () => {
    try {
      let { data: res } = await getBanner("status=true");
      if (res.data) {
        // console.log(res.data, "data");
        setBannerObj(res.data[0]);
      }
    } catch (err) {
      toastError(err);
    }
  };
  const handleGetTestimonials = async () => {
    try {
      let { data: res } = await getTestimonials("status=APPROVED");
      if (res.data) {
        // console.log(res.data, "data");
        setTestimonialsArr(res.data);
      }
    } catch (err) {
      toastError(err);
    }
  };

  const handleGetNewArrivalProduct = async () => {
    try {
      let { data: res } = await getProducts("productDisplay=arrival");
      if (res.data) {
        // console.log(res.data, "data");
        setNewArrivalProducts(res.data);
      }
    } catch (err) {
      toastError(err);
    }
  };

  const handleGetCategory = async () => {
    try {
      let { data: res } = await getCategory("level=1");
      if (res.data) {
        setCategoryArr(res.data);
      }
    } catch (err) {
      toastError(err);
    }
  };

  const handleGetBestSellerProduct = async () => {
    try {
      let { data: res } = await getProducts("productDisplay=bestseller");
      if (res.data) {
        // console.log(res.data, "data");
        setBestSellerProducts(res.data);
      }
    } catch (err) {
      toastError(err);
    }
  };
  const handleGetHomeBanner = async () => {
    let { data: get } = await getHomeBannerActive();
    // console.log(get?.data, "getgetget213");
    // console.log(get?.data?.image, "getgetget213");
    setHomeBanner(get?.data?.image);
  };

  useEffect(() => {
    handleGetBanner();
    handleGetNewArrivalProduct();
    handleGetBestSellerProduct();
    handleGetCategory();
    handleGetTestimonials();
    handleGetHomeBanner();
  }, []);

  return (
    <main>
      <section
        className="main-banner"
        style={{
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
          backgroundImage: `url(${bannerObj?.image && bannerObj?.image != ""
            ? `${generateFilePath(bannerObj?.image)}`
            : "../images/main_banner.jpg"
            })`,
        }}
      >
        <div className="container h100moile">
          <div className="row h100moile" style={bannerObj?.position != "left" ? { justifyContent: "flex-end" } : {}}>
            <div className={"col-12 col-md-6 mobilealignbeetwen"} style={bannerObj?.position != "left" ? { textAlign: "end" } : {}}>
              <div>

                <h1>
                  {bannerObj?.name}
                </h1>
                <p>
                  {bannerObj?.description}
                  {/* Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean
                hendrerit tempor massa vel pulvinar. */}
                </p>
              </div>
              {
                bannerObj?.btnText &&
                <Link to={bannerObj?.url ? bannerObj?.url : "/"} className="btn btn-custom btn-brown">
                  {bannerObj?.btnText ? bannerObj?.btnText : 'Explore More'}
                </Link>
              }

            </div>
          </div>
        </div>
      </section>

      <section className="bg1 three-box ptb-50">
        <div className="container">
          <div className="row gy-4 mobile_scroll">
            <div className="col-12 col-md-4">
              <div className="box text-center box-bg">
                <div>
                  <h4 className="text-white text-uppercase">ARTISAN MADE </h4>
                  <p className="text-white">

                    Authentic, sustainable and hand-made by local artisans
                    in India for the global market.

                  </p>
                </div>

                <Link
                  to={"/Shop/textile"}
                  className="fw-semibold ibm with-icon btn p-0 text-dark"
                >
                  SHOP NOW

                  <BsArrowRight />
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="box text-center box-bg">
                <div>
                  <h4 className="text-white text-uppercase">
                    LOW MINIMUMS
                  </h4>
                  <p className="text-white">
                    Shop for a minimum order value of $250
                    with easy shipping and private labelling.

                  </p>
                </div>
                <Link
                  to="/Shop/home-furnishing"
                  className="fw-semibold ibm with-icon btn p-0 text-dark"
                >
                  SHOP NOW

                  <BsArrowRight />
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="box text-center box-bg">
                <div>
                  <h4 className="text-white text-uppercase">CUSTOMISED DESIGNS
                  </h4>
                  <p className="text-white">
                  Design your products and order them to be made-to-order, with shipped within 45 days while ensuring quality.

                  </p>
                </div>
                <Link
                  to="/EquiryForm"
                  className="fw-semibold ibm with-icon btn p-0 text-dark"
                >
                  INQUIRE NOW

                  <BsArrowRight />
                </Link>
              </div>
            </div>
          </div>
          {/* <div className="row gy-4">
            <div className="col-12 col-md-4">
              <div className="box text-center box-bg">
                <div>
                  <h4 className="text-white text-uppercase">ARTISAN MADE </h4>
                  <p className="text-white">
                    Authentic, sustainable and hand-made by local artisans in
                    India for the global market.
                  </p>
                </div>
                <Link
                  to={"/Shop/textile"}
                  className="fw-semibold ibm with-icon btn p-0 text-dark"
                >
                  EXPLORE NOW
                  <BsArrowRight />
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="box text-center box-bg">
                <div>
                  <h4 className="text-white text-uppercase">LOW MINIMUMS</h4>
                  <p className="text-white">
                    Shop for a minimum order value of $250 with easy shipping
                    and private labelling.
                  </p>
                </div>
                <Link
                  to="#"
                  className="fw-semibold ibm with-icon btn p-0 text-dark"
                >
                  SHOP NOW
                  <BsArrowRight />
                </Link>
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="box text-center box-bg">
                <div>
                  <h4 className="text-white text-uppercase">
                    CUSTOMISE DESIGNS
                  </h4>
                  <p className="text-white">
                    Create your designs and source made-to-order products,
                    Shipped in 45 days. Quality assured.
                  </p>
                </div>
                <Link
                  to="#"
                  className="fw-semibold ibm with-icon btn p-0 text-dark"
                >
                  INQUIRE NOW
                  <BsArrowRight />
                </Link>
              </div>
            </div>
          </div> */}
        </div>
      </section>

      <section className="ptb-50">
        <div className="container">
          <div className="title-section mb-3">
            <div>
              <h1 className="title">Best Seller</h1>
              <p>
              Explore our best seller products recommended by our buyers{" "}
              </p>
            </div>
            <Link
              to="/Shop"
              className="btn btn-custom btn-brown btn_brown_new hover_transprent"
            >
              View All
            </Link>
          </div>
          <div className="row gy-4">
            {bestSellerProducts && bestSellerProducts.length > 0 ? (
              bestSellerProducts.map((el, index) => {
                return (
                  <div key={index} className="col-12 col-md-3">
                    <div className="product-box">
                      <div className="image">
                        <Link to={`/ShopDetail/${el.slug}`}>
                          <img
                            src={
                              el.imageArr &&
                                el.imageArr.length > 0 &&
                                el.imageArr[0].image
                                ? generateFilePath(el.imageArr[0].image)
                                : images.product
                            }
                            alt=""
                          />
                        </Link>
                        <ul className="tags">
                          <li>Best Seller</li>
                        </ul>
                      </div>
                      <div className="content box-bg">
                        <p className="text-white">
                          {/* {JSON.stringify(el)} */}
                          <Link to={`/ShopDetail/${el.slug}`}>{el?.name}</Link>
                        </p>
                        {!userIsAuthorised ? (
                          <p className="small">
                            <AiFillLock />
                            Sign up to view prices
                          </p>
                        ) : (
                          <p
                            className="small"
                            onClick={() => navigate(`/ShopDetail/${el.slug}`)}
                            style={{ cursor: "pointer" }}
                          >
                            {/* <p className="small" onClick={() => navigate(`/ShopDetail/product:${el.slug}`)} style={{ cursor: "pointer" }}> */}
                            Starting from ${el.minPrice}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>No bestsellers found</div>
            )}
          </div>
        </div>
      </section>
      <section className="ptb-50 bg1">
        <div className="container">
          <div className="title-section mb-3 justify-content-end text-end">
            <div>
              <h1 className="title">Top Category</h1>
              <p>
              Explore our handmade products across categories in home & living


              </p>
            </div>
          </div>
          <div className="row gy-4">
            {categoryArr && categoryArr.length > 0 ? (
              categoryArr.map((el, index) => {
                return (
                  <div
                    onClick={() => navigate(`/Shop/${el?.slug}`)}
                    key={index}
                    className="col-12 col-md-3"
                  >
                    <div
                      className={`category-box ${index % 2 != 0 && "reverse"}`}
                    >
                      <div className="image">
                        <img
                          src={
                            el.categoryImage != ""
                              ? generateFilePath(el.categoryImage)
                              : images.product
                          }
                          alt=""
                        />
                      </div>
                      <div className="content box-bg">
                        <h4 className="text-white">{el?.name}</h4>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>No categories found</div>
            )}

            {/* <div className="col-12 col-md-3">
              <div className="category-box reverse">
                <div className="image">
                  <img src={images.product} alt="" />
                </div>
                <div className="content box-bg">
                  <h4 className="text-white">Textile</h4>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="category-box">
                <div className="image">
                  <img src={images.product} alt="" />
                </div>
                <div className="content box-bg">
                  <h4 className="text-white">Home Decor</h4>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="category-box reverse">
                <div className="image">
                  <img src={images.product} alt="" />
                </div>
                <div className="content box-bg">
                  <h4 className="text-white">Kitchen & Dining</h4>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </section>
      <section
        className="home-banner ptb-80"
        // style={{ backgroundImage: `${homeBanner!=""?  generateFilePath(homeBanner): image}` }}
        style={{ backgroundImage: `url(${generateFilePath(homeBanner)})` }}
      >
        <div className="container">
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="box bg2">
                <h1 className="title">
                  Wholesale sourcing for conscious buyers!
                </h1>
                <p>
                  Source handloom and handmade home & living products made by
                  local artisans. Empowering artisans with the vision to take
                  Indian craftsmanship globally.
                </p>
                <div className="btns">
                  <Link
                    to="/About"
                    className="btn btn-custom btn-brown-outline"
                    style={{ width: "50%" }}
                  >
                    KNOW MORE
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="ptb-50 bg3">
        <div className="container">
          <div className="title-section mb-3">
            <div>
              <h1 className="title">New Arrivals</h1>
              <p>
              Explore our exclusive range of collections designed on-trend for
                the global market.
              </p>
            </div>
            <Link className="btn btn-custom btn-brown btn_brown_new hover_transprent">
              View All
            </Link>
          </div>
          <div className="row gy-4">
            {newArrivalProducts && newArrivalProducts.length > 0 ? (
              newArrivalProducts.map((el, index) => {
                return (
                  <div key={index} className="col-12 col-md-3">
                    <div className="product-box">
                      <div className="image">
                        <Link to={`/ShopDetail/${el?.slug}`}>
                          <img
                            src={
                              el.imageArr &&
                                el.imageArr.length > 0 &&
                                el.imageArr[0].image
                                ? generateFilePath(el.imageArr[0].image)
                                : images.product
                            }
                            alt=""
                          />
                        </Link>
                        <ul className="tags">
                          <li>Trending</li>
                        </ul>
                      </div>
                      <div className="content box-bg">
                        <p className="text-white">
                          <Link to={`/ShopDetail/${el?.slug}`}>{el?.name}</Link>
                        </p>
                        {!userIsAuthorised ? (
                          <p className="small">
                            <AiFillLock />
                            Sign up to view prices
                          </p>
                        ) : (
                          <p
                            className="small"
                            onClick={() => navigate(`/ShopDetail/${el.slug}`)}
                            style={{ cursor: "pointer" }}
                          >
                            {/* <p className="small" onClick={() => navigate(`/ShopDetail/product:${el.slug}`)} style={{ cursor: "pointer" }}> */}
                            Starting from ${el.minPrice}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>No new arrivals found</div>
            )}
          </div>
        </div>
      </section>

      <section className="testimonial ptb-50 bg1">
        <div className="container-fluid">
          <div className="title-section d-block text-center mb-3">
            <h1 className="title">Testimonials</h1>
          </div>
          <Swiper
            slidesPerView={1}
            speed={2000}
            modules={[Navigation, Autoplay]}
            navigation
            autoplay
          >
            {testimonialsArr && testimonialsArr.length > 0 ? (
              testimonialsArr.map((el, index) => {
                return (
                  <SwiperSlide key={index}>
                    <div className="box text-center">
                      <p className="desp">
                        <span className="icon brown">
                          <ImQuotesLeft />
                        </span>
                        <span>{el?.comment}</span>
                        <span className="icon brown">
                          <ImQuotesRight />
                        </span>
                      </p>
                      <p className="designation mt-3 fw-semibold">
                        - {el?.name}
                      </p>
                    </div>
                  </SwiperSlide>
                );
              })
            ) : (
              <SwiperSlide>
                <div className="box text-center">
                  <p className="desp">
                    <span className="icon brown">
                      <ImQuotesLeft />
                    </span>
                    <span>No Testimonials found</span>
                    <span className="icon brown">
                      <ImQuotesRight />
                    </span>
                  </p>
                  <p className="designation mt-3 fw-semibold">-</p>
                </div>
              </SwiperSlide>
            )}
          </Swiper>
        </div>
      </section>

      <section className="working-with moblebanerhome">
        <h1 className="lexend fw-semibold title">
          WE WORK WITH OVER 500 ARTISANS
        </h1>
      </section>
    </main>
  );
}

export default Index;
