import React, { useContext, useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaPhoneAlt,
  FaTruck,
  FaTwitter,
} from "react-icons/fa";
import { GrMail } from "react-icons/gr";
import { ImLocation } from "react-icons/im";
import { Link } from "react-router-dom";
import { LoadingContext, userIsAuthorisedContext } from "../../App";
import { getCategory } from "../../services/category.service";
import { addNewsletter } from "../../services/newsletter.service";
import { toastError, toastSuccess } from "../../utils/toastUtils";
import { images } from "../Utility/Images";

function Footer() {
  const [email, setEmail] = useState("");
  const [categoryArr, setCategoryArr] = useState([]);
  const [userIsAuthorised, setUserIsAuthorised] = useContext(
    userIsAuthorisedContext
  );
  const [loading, setLoading] = useContext(LoadingContext);



  const handleAddNewsLetter = async () => {
    try {
      setLoading(true)
      if (email == "") {
        toastError("Email is mandatory !!!");
        setLoading(false)
        return
      }

      if (!email.includes("@")) {
        toastError("Please enter a valid email !!!");
        setLoading(false)
        return
      }
      if (!email.includes(".")) {
        toastError("Please enter a valid email !!!");
        setLoading(false)
        return
      }



      let obj = {
        email
      }
      let { data: res } = await addNewsletter(obj);
      if (res.message) {
        toastSuccess(res.message)
        setLoading(false)
      }
    } catch (err) {
      toastError(err);
      setLoading(false)
    }
  };

  const handleGetCategory = async () => {
    try {
      let { data: res } = await getCategory("level=1");
      if (res.data) {
        setCategoryArr(res?.data);
      }
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    handleGetCategory();
  }, []);


  return (
    <footer className="bg3">
      <div className="footer">
        <div className="container">
          <div className="row gx-lg-5">
            <div className="col-12 col-md-4">
              <div classNtoastame="footer-inner">
                <Link to="/" className="navbar-brand">
                  <img src={images.logo} className="main-logo mb-3" alt="" />
                </Link>
                <p className="text fs-15">
                  Artisaga offers handloom and handcrafted home and lifestyle goods
                  for retailers and small businesses across the globe.
                  Source authentic products with fine craftsmanship directly from artisans of
                  India at a fair price!

                </p>
              </div>
            </div>
            <div className="col-12 col-md-2">
              <div className="footer-inner">
                <h4 className="title text-uppercase">KNOW MORE</h4>
                <ul className="links">
                  <li>
                    <Link to="/About">About Us</Link>
                  </li>
                  <li>
                    <Link to="/FAQ">FAQ</Link>
                  </li>
                  <li>
                    <Link to="/TermCondition">Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to="/PrivacyPolicy">Privacy Policy</Link>
                  </li>
                  <li>
                    <Link to="/Contact">Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="footer-inner">
                <h4 className="title text-uppercase">category</h4>
                <ul className="links">
                  {categoryArr &&
                    categoryArr.length > 0 &&
                    categoryArr.map((el, index) => {
                      return (
                        <li key={index}>
                          <Link to={`/Shop/${el?.slug}`}>{el.name}</Link>
                        </li>
                      );
                    })}
                </ul>
              </div>
            </div>
            <div className="col-12 col-md-3">
              <div className="footer-inner">
                <h4 className="title text-uppercase">Connect with us</h4>
                <ul className="links">
                  <li>
                    <div className="icon">
                      <FaPhoneAlt />
                    </div>
                    <a href="tel:+91 9833362013">+91 9833362013</a>
                  </li>
                  <li>
                    <div className="icon">
                      <GrMail />
                    </div>
                    <a href="mailto:info@artisaga.com ">
                      info@artisaga.com
                    </a>
                  </li>
                  <li>
                    <div className="icon">
                      <ImLocation />
                    </div>
                    <a
                      href="https://www.google.co.in/maps/place/Hiranandani+Estate,+Thane+West,+Thane,+Maharashtra/@19.2698977,72.959899,12.78z/data=!4m5!3m4!1s0x3be7bbf2c465948f:0xc7f8b5e5d69854f1!8m2!3d19.2591372!4d72.9844401?hl=en"
                      target="_blank"
                    >

                      Hiranandani Estate, Thane, Mumbai 400607

                    </a>
                  </li>
                </ul>
                <ul className="social-links my-3">
                  <li>
                    <a href="https://www.facebook.com/artisaga.official" className="icon" target="_blank">
                      <FaFacebookF />
                    </a>
                  </li>
                  <li>
                    <a href="https://www.instagram.com/artisaga.official/" className="icon" target="_blank">
                      <FaInstagram />
                    </a>
                  </li>
                  {/* <li>
                    <a href="https://twitter.com/Artisaga1" className="icon" target="_blank">
                    <FaTwitter />
                    </a>
                  </li> */}
                  <li>
                    <a href="https://www.linkedin.com/company/artisagaofficial/" className="icon" target="_blank">
                      <FaLinkedinIn />
                    </a>
                  </li>
                </ul>
                <div>
                  {
                    userIsAuthorised &&
                    <Link
                      to={`/Account?tab=${encodeURIComponent('Track Your Order')}`}
                      className="btn btn-brown btn-custom w-100 with-icon"
                    >
                      Track Your Order
                      <FaTruck />
                    </Link>
                  }
                </div>
                <div className="newsleter_secion mt-3">
                  <h4 class="title text-uppercase mb-1">NewsLetter</h4>
                  <form className="search-bar">
                    <div
                      className="position-relative newsletterinput d-flex">
                      <div >
                        <input type="email" value={email} onChange={(e) => setEmail(e.target.value)} placeholder="Email Id" aria-label="Search" className="form-control flex-1" />
                      </div>
                      <button type="button" onClick={() => handleAddNewsLetter()} className="btn btn-sm btn-custom btn-brown rounded-0 px-4">Subscribe  </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="copyright text-center">
        <div className="container">
          <p>
            Copyright &copy; 2022, Artisaga, Design & Developed by
            <a href="ebslon.com"> Ebslon Infotech</a>
          </p>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
