// PaymentGateway
import { axiosApiInstance } from "./axios.service";
import { url } from "./url.service";

const serverUrl = url + "/negotiation";

export const addNegotiations = (formData) => {
    return axiosApiInstance.post(`${serverUrl}/`, formData);
};

export const getNegotiations = (id) => {
    return axiosApiInstance.get(`${serverUrl}/getById/${id}`);
};