import React, { useContext, useEffect } from "react";
import DataTable from "react-data-table-component";
import { images } from "./Utility/Images";
import { GrClose } from "react-icons/gr";
import { Link } from "react-router-dom";
import { FaPhoneAlt, FaStarOfLife } from "react-icons/fa";
import { useState } from "react";
import { Modal } from "react-bootstrap";
import { BsCheckLg, BsLockFill } from "react-icons/bs";
import { ImBin2 } from "react-icons/im";
import {
  getLoggedInUser,
  getUserCart,
  saveUserCart,
} from "../services/users.service";
import { LoadingContext, userIsAuthorisedContext } from "../App";
import { generateFilePath } from "../utils/filepath";
import { toastError, toastSuccess } from "../utils/toastUtils";
import { createOrder } from "../services/order.service";
import {
  addAddress,
  deleteAddress,
  getAddress,
  updateAddress,
} from "../services/UserAddress.service";
import { getCart, removeProductFromCart } from "../services/userCart.service";

function Cart() {
  const [userIsAuthorised, setUserIsAuthorised] = useContext(
    userIsAuthorisedContext
  );

  const [loading, setLoading] = useContext(LoadingContext);

  const [countryArr, setCountryArr] = useState([
    { value: "Austria" },
    { value: "Belgium" },
    { value: "Bulgaria" },
    { value: "Cyprus" },
    { value: "Denmark" },
    { value: "Spain" },
    { value: "Finland" },
    { value: "France" },
    { value: "Greece" },
    { value: "Hungary" },
    { value: "Ireland" },
    { value: "Italy" },
    { value: "Luxembourg" },
    { value: "Malta" },
    { value: "Netherlands" },
    { value: "Poland" },
    { value: "Portugal" },
    { value: "Germany" },
    { value: "Rumanía" },
    { value: "Sweden" },
    { value: "Latvia" },
    { value: "Estonia" },
    { value: "Lithuania" },
    { value: "Czech Republic" },
    { value: "Slovak Republic" },
    { value: "Croatia" },
    { value: "Slovenia" },
    { value: "Albania" },
    { value: "Iceland" },
    { value: "Liechtenstein" },
    { value: "Monaco" },
    { value: "Norway" },
    { value: "Andorra" },
    { value: "United Kingdom" },
    { value: "San Marino" },
    { value: "Holy See" },
    { value: "Switzerland" },
    { value: "Ukraine" },
    { value: "Moldova" },
    { value: "Belarus" },
    { value: "Georgia" },
    { value: "Bosnia and Herzegovina" },
    { value: "Armenia" },
    { value: "Russia" },
    { value: "Macedonia " },
    { value: "Serbia" },
    { value: "Montenegro" },
    { value: "Turkey" },
    { value: "Other Countries of Europe" },
    { value: "Burkina Faso" },
    { value: "Angola" },
    { value: "Algeria" },
    { value: "Benin" },
    { value: "Botswana" },
    { value: "Burundi" },
    { value: "Cape Verde" },
    { value: "Cameroon" },
    { value: "Union of Comoros" },
    { value: "Congo" },
    { value: "Ivory Coast" },
    { value: "Djibouti" },
    { value: "Egytp" },
    { value: "Ethiopia" },
    { value: "Gabon" },
    { value: "Gambia" },
    { value: "Ghana" },
    { value: "Guinea" },
    { value: "Guinea-Bissau" },
    { value: "Equatorial Guinea " },
    { value: "Kenya" },
    { value: "Lesotho" },
    { value: "Liberia" },
    { value: "Libya" },
    { value: "Madagascar" },
    { value: "Malawi" },
    { value: "Mali" },
    { value: "Morocco" },
    { value: "Mauritius" },
    { value: "Mauritania" },
    { value: "Mozambique" },
    { value: "Namibia" },
    { value: "Níger" },
    { value: "Nigeria" },
    { value: "Central African Republic" },
    { value: "South Africa" },
    { value: "Rwanda" },
    { value: "Sao Tomé and Príncipe" },
    { value: "Senegal" },
    { value: "Seychelles" },
    { value: "Sierra Leone" },
    { value: "Somalia" },
    { value: "Sudan" },
    { value: "Swaziland" },
    { value: "Tanzania" },
    { value: "Chad" },
    { value: "Togo" },
    { value: "Tunisia" },
    { value: "Uganda" },
    { value: "Democratic Republic of the Congo" },
    { value: "Zambia" },
    { value: "Zimbabwe" },
    { value: "Eritrea" },
    { value: "South Sudan" },
    { value: "Other Countries of Africa" },
    { value: "Canada" },
    { value: "The United States of America" },
    { value: "Mexico" },
    { value: "Antigua y Barbuda" },
    { value: "Bahamas" },
    { value: "Barbados" },
    { value: "Belize" },
    { value: "Costa Rica" },
    { value: "Cuba" },
    { value: "Dominique" },
    { value: "El Salvador" },
    { value: "Grenada" },
    { value: "Guatemala" },
    { value: "Haiti" },
    { value: "Honduras" },
    { value: "Jamaica" },
    { value: "Nicaragua" },
    { value: "Panama" },
    { value: "Saint Vincent and the Grenadines" },
    { value: "Dominican Republic" },
    { value: "Trinidad and Tobago" },
    { value: "Saint Lucia" },
    { value: "Saint Kitts and Neviss" },
    { value: "Argentina" },
    { value: "Bolivia" },
    { value: "Brazil" },
    { value: "Colombia" },
    { value: "Chile" },
    { value: "Ecuador" },
    { value: "Guyana" },
    { value: "Paraguay" },
    { value: "Peru" },
    { value: "Surinam" },
    { value: "Uruguay" },
    { value: "Venezuela" },
    { value: "Other Countries of America" },
    { value: "Afghanistan" },
    { value: "Saudi Arabia" },
    { value: "Bahrain" },
    { value: "Bangladesh" },
    { value: "Myanmar" },
    { value: "China" },
    { value: "United Arab Emirates" },
    { value: "Philippines" },
    { value: "India" },
    { value: "Indonesia" },
    { value: "Iraq" },
    { value: "Iran" },
    { value: "Israel" },
    { value: "Japan" },
    { value: "Jordan" },
    { value: "Cambodia" },
    { value: "Kuwait" },
    { value: "Laos" },
    { value: "Lebanon" },
    { value: "Malaysia" },
    { value: "Maldives" },
    { value: "Mongolia" },
    { value: "Nepal" },
    { value: "Oman" },
    { value: "Pakistan" },
    { value: "Qatar" },
    { value: "Korea" },
    { value: "North Korea" },
    { value: "Singapore" },
    { value: "Syria" },
    { value: "Sri Lanka" },
    { value: "Thailand" },
    { value: "Vietnam" },
    { value: "Brunei" },
    { value: "Marshall Islands" },
    { value: "Yemen" },
    { value: "Azerbaijan" },
    { value: "Kazakhstán" },
    { value: "Kirgyzstan" },
    { value: "Tajikistan" },
    { value: "Turkmeinetan" },
    { value: "Uzbekistan" },
    { value: "Bhutan" },
    { value: "Palestine. Observer State, non-member of the United Nations" },
    { value: "Other Countries of Asia" },
    { value: "Australia" },
    { value: "Fiji" },
    { value: "New Zeland" },
    { value: "Papua New Guinea" },
    { value: "Solomon Islands" },
    { value: "Samoa" },
    { value: "Tonga" },
    { value: "Vanuatu" },
    { value: "Micronesia" },
    { value: "Tuvalu" },
    { value: "Cook Islands" },
    { value: "Kiribati" },
    { value: "Nauru" },
    { value: "Palau" },
    { value: "East Timor" },
  ]);

  const [addressArr, setAddressArr] = useState([]);

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");

  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [pincode, setPincode] = useState("");
  const [deliveryTypeArr, setDeliveryTypeArr] = useState([
    { name: "Ex- Factory", checked: false },
    { name: "C.I.F (Airways)", checked: false },
    { name: "C.I.F (Waterways)", checked: false },
    { name: "F.O.B SAHAR INTERNATIONAL AIRPORT - (AIRWAYS)", checked: false },
    { name: "F.O.B Nava Sheva (Waterways)", checked: false },
  ]);
  const [methodOfShipingArr, setMethodOfShipingArr] = useState([]);

  const [tax, setTax] = useState(1000);
  const [subtotal, setSubtotal] = useState(0);
  const [total, setTotal] = useState(0);

  const [userObj, setUserObj] = useState({});

  const columns = [
    {
      name: "Item",
      width: "30%",
      cell: (row) => (
        <div className="table-product">
          <img src={generateFilePath(row.image)} alt="" />
          <p>{row?.name}</p>
        </div>
      ),
    },
    {
      name: "Price",
      cell: (row) =>
        userIsAuthorised ? (
          `$ ${row.price}`
        ) : (
          <span>
            <BsLockFill color="#C59D69" size={20} />
          </span>
        ),
    },
    {
      name: "Qty",
      selector: (row) => row.quantity,
    },
    {
      name: "Total",
      selector: (row) =>
        userIsAuthorised ? (
          `$ ${row.price * row.quantity}`
        ) : (
          <span>
            <BsLockFill color="#C59D69" size={20} />
          </span>
        ),
    },
    {
      name: "Action",
      width: "10%",
      cell: (row) => (
        <button
          onClick={() => handleDeleteProduct(row.productId)}
          className="brown3 btn mb-0 pointer"
        >
          <ImBin2 />
        </button>
        // <ul className="action-icon">
        //   <li>
        //     <GrClose />
        //   </li>
        // </ul>
      ),
    },
  ];

  const [cartArr, setCartArr] = useState([]);

  const handleDeleteProduct = async (pId) => {
    if (userIsAuthorised) {
      let { data: res } = await removeProductFromCart(pId);
      if (res.success) {
        toastSuccess("Product Deleted successfully");
      }
    } else {
      let tempCart = cartArr.filter((el) => el.productId != pId);
      saveUserCart(JSON.stringify(tempCart));
      toastSuccess("Product Deleted successfully");
    }
    handleGetCart();
  };

  const handleGetCart = async () => {
    setLoading(true)
    if (userIsAuthorised) {
      try {

        let { data: res } = await getCart();
        if (res.data) {
          setLoading(false)
          // console.log(res.data);
          setCartArr(res.data ? [...res.data] : []);
        }
      }
      catch (err) {
        setLoading(false);
        toastError(err)
      }
    } else {
      let tempCartArr = getUserCart();
      tempCartArr = JSON.parse(tempCartArr);
      setCartArr(tempCartArr ? [...tempCartArr] : []);
      // console.log(tempCartArr, "tempCartArr");
      setLoading(false)
    }
  };

  const handleGetAddress = async () => {
    try {
      let { data: res } = await getAddress();
      if (res.data) {
        setAddressArr(res.data);
      }
    } catch (err) {
      toastError(err);
    }
  };

  useEffect(() => {
    handleGetCart();
    let temp = getLoggedInUser();
    temp = JSON.parse(temp);
    if (temp) {
      setUserObj(temp);
      setName(`${temp.firstName} ${temp.lastName}`);
      setPhone(temp.phone);
      setEmail(temp.email);
    }
    if (userIsAuthorised) {
      handleGetAddress();
    }
  }, [userIsAuthorised]);

  const clearFunction = () => {
    setCartArr([]);
    setAddress("");
    setName("");
    setEmail("");
    setPhone("");
    setCountry("");
    setCity("");
    setState("");
    setPincode("");
    setDeliveryTypeArr([
      { name: "Ex- Factory", checked: false },
      { name: "C.I.F", checked: false },
      { name: "F.O.B Nava Sheva", checked: false },
    ]);
    setMethodOfShipingArr([
      { name: "Airways", checked: false },
      { name: "Waterways", checked: false },
    ]);
  };

  const handleCreateOrder = async () => {
    try {
      setLoading(true)
      if (!userObj) {
        toastError("Loggin first !");
        setLoading(false)
        return;
      }
      if (address == "") {
        toastError("Address is mandatory!");
        setLoading(false)
        return;
      }
      if (country == "") {
        toastError("Country is mandatory!");
        setLoading(false)
        return;
      }
      if (city == "") {
        toastError("City is mandatory!");
        setLoading(false)
        return;
      }
      if (pincode == "") {
        toastError("Pincode is mandatory!");
        setLoading(false)
        return;
      }
      if (!deliveryTypeArr.some((el) => el.checked)) {
        toastError("Delivery Type is mandatory!");
        setLoading(false)
        return;
      }
      if ((cartArr && cartArr.length > 0
        ? cartArr.reduce(
          (acc, el) =>
            acc +
            parseFloat(el.price) * parseFloat(el.quantity),
          0
        )
        : 0) < 250) {
        toastError("Minimum order value to proceed with the order is USD$ 250. Please add more products to the cart.");
        setLoading(false)
        return;
      }

      let temp = getLoggedInUser();
      temp = JSON.parse(temp);
      let obj = {
        userId: temp._id,
        items: cartArr,
        addressObj: {
          address,
          name,
          email,
          phone,
          country,
          city,
          state,
          pincode,
        },
        deliveryTypeArr: deliveryTypeArr.filter((el) => el.checked),
        // methodOfShipingArr: methodOfShipingArr.filter((el) => el.checked),
      };
      let { data: res } = await createOrder(obj);
      if (res.message) {
        setLoading(false)
        // toastSuccess(res.message);
        setQuoteModal(false);
        clearFunction();
        setQuote2Modal(true)
      }
    } catch (err) {
      setLoading(false)
      toastError(err);
    }
  };

  const handleSelectSavedAddress = (objValue) => {
    let obj = JSON.parse(objValue);
    if (objValue == "" && !objValue) {
      alert("ad");
      setName("");
      setPhone("");
      setCountry("");
      setCity("");
      setState("");
      setAddress("");
      setPincode("");
    } else {
      setName(`${obj.name}`);
      setPhone(obj?.phone);
      setCountry(obj?.country);
      setCity(obj?.city);
      setState(obj?.state);
      setAddress(obj?.address);
      setPincode(obj?.pincode);
    }
  };

  const handleCheckDeliveryType = (index, value) => {
    let tempArr = deliveryTypeArr;
    tempArr[index].checked = value;
    setDeliveryTypeArr([...tempArr]);
  };

  // const handleCheckMethodOfShipment = (index, value) => {
  //   let tempArr = methodOfShipingArr;
  //   tempArr[index].checked = value;
  //   setMethodOfShipingArr([...tempArr]);
  // };

  const [quoteModal, setQuoteModal] = useState(false);
  const [quoteModal2, setQuote2Modal] = useState(false);





  return (
    <main>
      <section className="cart-page ptb-50">
        <div className="container">
          <h3 className="mb-4">My Shopping Cart</h3>
          <div className="row">
            <div className="col-12 col-md-8">
              <div className="left">
                <DataTable columns={columns} data={cartArr} pagination />
              </div>
            </div>
            <div className="col-12 col-md-4">
              <div className="order-summary">
                <div className="box bg3">
                  <h5>Order Summary</h5>
                  <ul>
                    <li>
                      Subtotal{" "}
                      <span>
                        ${" "}
                        {cartArr && cartArr.length > 0
                          ? cartArr.reduce(
                            (acc, el) =>
                              acc +
                              parseFloat(el.price) * parseFloat(el.quantity),
                            0
                          )
                          : 0}
                      </span>
                    </li>
                    {/* <li>
                      Tax{" "}
                      <span>
                        $ {cartArr && cartArr.length > 0 ? "1,000.00" : 0}
                      </span>
                    </li> */}
                    <li>
                      Shipping price <span>To Be Calculated</span>
                    </li>
                    <li>
                      Order Total{" "}
                      <span>
                        ${" "}
                        {cartArr && cartArr.length > 0
                          ? cartArr.reduce(
                            (acc, el) =>
                              acc +
                              parseFloat(el.price) * parseFloat(el.quantity),
                            0
                          )
                          : 0}
                      </span>
                    </li>
                  </ul>
                  {userIsAuthorised && (
                    <Link
                      className="btn btn-custom btn-brown w-100"
                      onClick={() => setQuoteModal(true)}
                    >
                      Request for a Final Quote
                    </Link>
                  )}
                </div>
                <div className="box2 bg1">
                  <ul>
                    <li>
                      For any queries, please email at <a href="mailto:info@artisaga.com">info@artisaga.com</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>


      <Modal
        show={quoteModal2}
        size="lg"
        centered
        onHide={() => { setQuote2Modal(false); }}
      >
        <Modal.Body className="congrats-modal custom-modal text-center">
          <button
            type="button"
            className="btn-close end"
            aria-label="Close"
            onClick={() => { setQuote2Modal(false); }}
          ></button>
          <img
            src={images.party}
            alt=""
            className="img-fluid img-contain"
            width={80}
          />
          <h2 className="my-3 fw-semibold">Thank You!</h2>
          <p className="mb-0 fw-semibold">
            We have received your request for final quotation.
          </p>
          <p className="mb-0">
            Our team will prepare final quotation and update you in next 24-48 hours.
          </p>
          <p className="my-3 line-height-normal fs-15">
            Please keep a check on your email for further updates
          </p>
          <button onClick={() => setQuote2Modal(false)} className="btn btn-custom btn-brown px-4 mt-4">
            Continue shopping
          </button>
        </Modal.Body>

      </Modal>


      <Modal
        show={quoteModal}
        size="xl"
        centered
        onHide={() => setQuoteModal(false)}
      >
        <Modal.Body className="quote-modal custom-modal rounded-4">
          <button
            type="button"
            className="btn-close end"
            aria-label="Close"
            onClick={() => setQuoteModal(false)}
          ></button>
          <div className="right">
            <h1 className="title mb-4 text-center">Request For Quote</h1>
            <form className="form row g-3">
              <div className="col-12 col-md-6 mb-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Name *"
                  onChange={(e) => setName(e.target.value)}
                  value={name}
                />
              </div>
              <div className="col-12 col-md-6 mb-0">
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email *"
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
              </div>
              <div className="col-12 col-md-6 mb-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Phone number *"
                  onChange={(e) => setPhone(e.target.value)}
                  value={`${phone}`}
                />
              </div>
              <div className="col-12 col-md-6 mb-0">
                <select
                  value={country}
                  onChange={(e) => {
                    // console.log(e.target.value);
                    setCountry(e.target.value);
                  }}
                  className="form-select"
                  aria-label="Country / Region *"
                >
                  <option selected disabled hidden>
                    Country / Region *
                  </option>
                  <option value="">Country / Region *</option>
                  {countryArr.map((el, index) => {
                    return (
                      <option value={el.value} key={index}>
                        {el.value}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="col-12 col-md-6 mb-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="State"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
              </div>
              <div className="col-12 col-md-6 mb-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Town / City *"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                />
              </div>
              <div className="col-12 col-md-6 mb-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Street address *"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </div>
              <div className="col-12 col-md-6 mb-0">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Pincode *"
                  value={pincode}
                  onChange={(e) => setPincode(e.target.value)}
                />
              </div>
              <div className="col-12 col-md-12 mb-0">
                <p className="checkbox-label line-height-normal">
                  Delivery type
                </p>
                <div className="inline-checks">
                  {deliveryTypeArr &&
                    deliveryTypeArr.length > 0 &&
                    deliveryTypeArr.map((el, index) => {
                      return (
                        <div className="form-check" key={index}>
                          <label>
                            <input
                              className="form-check-input"
                              name="delivery_type"
                              type="checkbox"
                              value="Ex- Factory"
                              checked={el.checked}
                              onChange={(e) =>
                                handleCheckDeliveryType(index, e.target.checked)
                              }
                            />
                            <span>{el.name}</span>
                          </label>
                        </div>
                      );
                    })}
                </div>
              </div>
              <hr style={{ color: "#C59D69", margin: "20px 0px 10px 0px" }} />
              <h4 className="checkbox-label line-height-normal text-center">
                Or
              </h4>

              <div className="col-12 col-md-12 mb-0">
                <p className="checkbox-label line-height-normal">
                  {" "}
                  Select from saved addresses
                </p>
                <select
                  onChange={(e) => {
                    handleSelectSavedAddress(e.target.value);
                  }}
                  className="form-select"
                  aria-label="Country / Region *"
                >
                  <option selected disabled hidden>
                    Please Select an address
                  </option>
                  <option value="">Please Select an address</option>
                  {addressArr &&
                    addressArr.length > 0 &&
                    addressArr.map((el, index) => {
                      return (
                        <option value={JSON.stringify(el)} key={index}>
                          {el?.name} | {el?.companyName} | {el?.phone}
                        </option>
                      );
                    })}
                </select>
              </div>
              <div className="col-12 text-center mt-4 mb-0">
                <button
                  type="button"
                  onClick={() => handleCreateOrder()}
                  className="btn btn-custom btn-brown px-4"
                >
                  Submit
                </button>
              </div>
            </form>
          </div>
        </Modal.Body>
      </Modal>
    </main >
  );
}

export default Cart;
