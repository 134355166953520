import React, { useContext, useEffect, useState } from "react";
import { AiFillLock } from "react-icons/ai";
import {
  Link,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import { CurrentLoggedInUserContext, userIsAuthorisedContext } from "../App";

import { useLayoutEffect } from "react";
import { BsSliders } from "react-icons/bs";
import { getMaterials } from "../services/Material.service";
import {
  getCategory,
  getNameBySlug
} from "../services/category.service";
import { generateFilePath } from "../utils/filepath";
import { toastError } from "../utils/toastUtils";
import ShopFilter from "./ShopFilter";
import { images } from "./Utility/Images";
function Shop() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [currentLoggedInUser, setCurrentLoggedInUser] = useContext(
    CurrentLoggedInUserContext
  );
  const [userIsAuthorised, setUserIsAuthorised] = useContext(
    userIsAuthorisedContext
  );
  searchParams.get("__firebase_request_key");
  const params = useParams();
  const [categoryArr, setCategoryArr] = useState([]);
  const [productArr, setProductArr] = useState([]);
  const [subCategroyArr, setSubCategroyArr] = useState([]);
  const [materialArr, setMaterialArr] = useState([]);
  const [sortBy, setSortBy] = useState("");
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [showFilterMobile, setShowFilterMobile] = useState(false);
  const [filterBreakPoint, setFilterBreakPoint] = useState(992)

  const [categoryObj, setCategoryObj] = useState({});

  const handleGetCategory = async () => {
    try {
      let { data: res } = await getCategory("level=1");
      if (res.data) {
        let tempArr = res?.data.map((el) => {
          let obj = { ...el };

          if (params?.slug) {
            if (el.slug == params?.slug) {
              obj.checked = true;
            } else {
              obj.checked = false;
            }
          } else {
            obj.checked = false;
          }

          return obj;
        });
        setCategoryArr(tempArr);
      }
    } catch (err) {
      toastError(err);
    }
  };

  const handleGetCategoryById = async (id) => {
    try {
      // let { data: res } = await getNameById(searchParams.get("categoryId"));

      let { data: res } = await getNameBySlug(params?.slug);
      // console.log(res?.data, "datadt234  ");
      // console.log(productArr, "datadt234  ");
      if (res.data) {
        setCategoryObj(res.data);
        // setProductArr
      }
    } catch (err) {
      toastError(err);
    }
  };

  const handleGetSubCategory = async () => {
    try {
      let { data: res } = await getCategory("level=2");
      if (res.data) {
        let tempArr = res.data.map((el) => {
          let obj = { ...el, checked: false };

          // if (searchParams.get("categoryId")) {
          if (params.slug) {
            if (el.slug == params.slug) {
              obj.checked = true;
            } else {
              obj.checked = false;
            }
          } else {
            obj.checked = false;
          }

          return obj;
        });
        setSubCategroyArr(tempArr);
      }
    } catch (err) {
      toastError(err);
    }
  };

  const handleGetMaterials = async () => {
    try {
      let { data: res } = await getMaterials();
      if (res.data) {
        setMaterialArr(res.data.map((el) => ({ ...el, checked: false })));
      }
    } catch (err) {
      toastError(err);
    }
  };

  const handleCheckCheckboxData = () => {
    let tempSubCategoryArr = subCategroyArr.map((el) => {
      let obj = { ...el, checked: false };

      if (params.slug) {
        if (el.slug == params.slug) {
          obj.checked = true;
        } else {
          obj.checked = false;
        }
      } else {
        obj.checked = false;
      }

      return obj;
    });
    setSubCategroyArr(tempSubCategoryArr);

    let tempCategoryArr = categoryArr.map((el) => {
      let obj = { ...el, checked: false };
      if (params.slug) {
        if (el.slug == params.slug) {
          obj.checked = true;
        } else {
          obj.checked = false;
        }
      } else {
        obj.checked = false;
      }

      return obj;
    });
    setCategoryArr(tempCategoryArr);
  };

  useEffect(() => {
    handleGetCategory();
    handleGetSubCategory();
    handleGetMaterials();
  }, []);

  // useEffect(() => {
  //   if (searchParams.get("categoryId")) {
  //     handleGetCategoryById();
  //     handleCheckCheckboxData();
  //   }
  // }, [searchParams.get("categoryId")]);

  useEffect(() => {
    if (params.slug) {
      handleGetCategoryById();
      handleCheckCheckboxData();
    }
  }, [params]);
  useEffect(() => {
    setShowFilterMobile(showFilterMobile);
  }, [showFilterMobile]);
  useEffect(() => {
    setFilterBreakPoint(filterBreakPoint);
  }, [filterBreakPoint]);

  useEffect(() => { }, [categoryArr]);

  useLayoutEffect(() => {
    window.addEventListener("resize", function () {
      setWindowWidth(window.innerWidth);
    });
    setWindowWidth(window.innerWidth);
    return () => {
      window.addEventListener("resize", function () {
        setWindowWidth(window.innerWidth);
      });
    };
  }, []);

  // useEffect(() => {
  //   // console.log(productArr, "productArr")
  // }, [productArr])
  return (
    <main>
      <section className="shop-page ptb-50">
        {windowWidth < filterBreakPoint && (
          <button
            onClick={() => setShowFilterMobile(true)}
            className="btn btn-brown btn-custom btn-hover filter_btn"
          >
            Filters &nbsp; <BsSliders />
          </button>
        )}
        <div className="container-fluid">
          <div className="row">
            <div className="col-12 col-lg-3 col-xl-3" style={{position:"relative"}}>
            <div style={{position:"sticky", top:"10vh", width:"100%", overflowY:"scroll", height:"70vh"}}>
              <ShopFilter
                categoryArrData={categoryArr}
                // selectedCategoryIdData={searchParams.get("categoryId")}
                selectedCategoryIdData={params.slug}
                sortByData={sortBy}
                setLoading={setLoading}
                subcategoryArrData={subCategroyArr}
                materialArrData={materialArr}
                setProductArr={setProductArr}
                setShowFilterMobile={setShowFilterMobile}
                showFilterMobile={showFilterMobile}
                filterBreakPoint={filterBreakPoint}
              />
            </div>
            </div>
            <div className="col-12 col-lg-8 col-xl-9">
              <div className="right">
                <div className="top">
                  <div>
                    <h1 className="title">{categoryObj?.name}</h1>
                    <p className="mb-0">
                      Shop artisanal and consciously made products, at wholesale
                      price
                    </p>
                  </div>
                  <form className="form">
                    <select
                      onChange={(e) => setSortBy(e.target.value)}
                      value={sortBy}
                      className="form-select"
                      aria-label="Sort By"
                    >
                      <option value={""}>Sort By</option>
                      <option value="Top seller">Top seller</option>
                      <option value="New Arrival">New Arrival</option>
                      <option value="Alphabetically, A-Z">
                        Alphabetically, A-Z
                      </option>
                      <option value="Alphabetically, Z-A">
                        Alphabetically, Z-A
                      </option>
                      <option value="Price, low to high">
                        Price, low to high
                      </option>
                      <option value="Price, high to low">
                        Price, high to low
                      </option>
                      <option value="Date, New to old">
                        Date, New to old{" "}
                      </option>
                      <option value="Date, Old to new">
                        Date, Old to new{" "}
                      </option>
                    </select>
                  </form>
                </div>
                {
                  loading?
                  <h2 className="text-center pt-5 mt-5">
                      Loading please wait...
                    </h2>

                  :

                <div className="row gy-4">
                  {productArr &&
                    productArr.length > 0 ?
                    productArr.map((el, index) => {
                      return (
                        <div key={index} className="col-12 col-sm-6 col-xl-4">
                          <div className="product-box">
                            <div className="image">
                              <Link to={`/ShopDetail/${el?.slug}`}>
                                {/* <Link to={`/ShopDetail/product:${el.slug}`}> */}
                                <img
                                  src={
                                    el.imageArr &&
                                      el.imageArr.length > 0 &&
                                      el.imageArr[0].image
                                      ? generateFilePath(el.imageArr[0].image)
                                      : images.product
                                  }
                                  alt=""
                                />
                              </Link>

                              {el?.productDisplay &&
                                el?.productDisplay == "bestseller" && (
                                  <ul className="tags">
                                    <li>Best Seller</li>
                                  </ul>
                                )}
                              {el?.productDisplay &&
                                el?.productDisplay == "arrival" && (
                                  <ul className="tags">
                                    <li>New Arrival</li>
                                  </ul>
                                )}
                            </div>
                            <div className="content box-bg">
                              <p className="text-white">
                                {/* <Link to="/ShopDetail"> */}
                                <Link to={`/ShopDetail/${el?.slug}`}>
                                  {el.name}
                                </Link>
                              </p>
                              {!userIsAuthorised ? (
                                <p className="small">
                                  <AiFillLock />
                                  Sign up to view prices
                                </p>
                              ) : (
                                <p
                                  className="small"
                                  onClick={() =>
                                    navigate(`/ShopDetail/${el?.slug}`)
                                  }
                                  style={{ cursor: "pointer" }}
                                >
                                  {/* <p className="small" onClick={() => navigate(`/ShopDetail/product:${el.slug}`)} style={{ cursor: "pointer" }}> */}
                                  Starting from ${el.minPrice}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      );
                    })
                    :
                      <h3 className="text-center pt-5 mt-5">Oops! we could not find products for the filters you selected</h3>
                    }
                </div>
              }

              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  );
}

export default Shop;
