import React, { useContext, useEffect } from "react";
import { useState } from "react";
import { AiOutlineClose } from "react-icons/ai";
import { MdEdit } from "react-icons/md";
import { CurrentLoggedInUserContext, userIsAuthorisedContext } from "../../App";
import { addAddress, deleteAddress, getAddress, updateAddress } from "../../services/UserAddress.service";
import { getDecodedToken, getUser } from "../../services/users.service";
import { toastError, toastSuccess } from "../../utils/toastUtils";

function Profile() {
  const [newAddress, setnewAddress] = useState(false);
  const [userObj, setUserObj] = useState({});


  const [selectedAddress, setSelectedAddress] = useState({});
  const [isEditingEnabled, setIsEditingEnabled] = useState(false);



  const [countryArr, setCountryArr] = useState([
    { value: "Austria" },
    { value: "Belgium" },
    { value: "Bulgaria" },
    { value: "Cyprus" },
    { value: "Denmark" },
    { value: "Spain" },
    { value: "Finland" },
    { value: "France" },
    { value: "Greece" },
    { value: "Hungary" },
    { value: "Ireland" },
    { value: "Italy" },
    { value: "Luxembourg" },
    { value: "Malta" },
    { value: "Netherlands" },
    { value: "Poland" },
    { value: "Portugal" },
    { value: "Germany" },
    { value: "Rumanía" },
    { value: "Sweden" },
    { value: "Latvia" },
    { value: "Estonia" },
    { value: "Lithuania" },
    { value: "Czech Republic" },
    { value: "Slovak Republic" },
    { value: "Croatia" },
    { value: "Slovenia" },
    { value: "Albania" },
    { value: "Iceland" },
    { value: "Liechtenstein" },
    { value: "Monaco" },
    { value: "Norway" },
    { value: "Andorra" },
    { value: "United Kingdom" },
    { value: "San Marino" },
    { value: "Holy See" },
    { value: "Switzerland" },
    { value: "Ukraine" },
    { value: "Moldova" },
    { value: "Belarus" },
    { value: "Georgia" },
    { value: "Bosnia and Herzegovina" },
    { value: "Armenia" },
    { value: "Russia" },
    { value: "Macedonia " },
    { value: "Serbia" },
    { value: "Montenegro" },
    { value: "Turkey" },
    { value: "Other Countries of Europe" },
    { value: "Burkina Faso" },
    { value: "Angola" },
    { value: "Algeria" },
    { value: "Benin" },
    { value: "Botswana" },
    { value: "Burundi" },
    { value: "Cape Verde" },
    { value: "Cameroon" },
    { value: "Union of Comoros" },
    { value: "Congo" },
    { value: "Ivory Coast" },
    { value: "Djibouti" },
    { value: "Egytp" },
    { value: "Ethiopia" },
    { value: "Gabon" },
    { value: "Gambia" },
    { value: "Ghana" },
    { value: "Guinea" },
    { value: "Guinea-Bissau" },
    { value: "Equatorial Guinea " },
    { value: "Kenya" },
    { value: "Lesotho" },
    { value: "Liberia" },
    { value: "Libya" },
    { value: "Madagascar" },
    { value: "Malawi" },
    { value: "Mali" },
    { value: "Morocco" },
    { value: "Mauritius" },
    { value: "Mauritania" },
    { value: "Mozambique" },
    { value: "Namibia" },
    { value: "Níger" },
    { value: "Nigeria" },
    { value: "Central African Republic" },
    { value: "South Africa" },
    { value: "Rwanda" },
    { value: "Sao Tomé and Príncipe" },
    { value: "Senegal" },
    { value: "Seychelles" },
    { value: "Sierra Leone" },
    { value: "Somalia" },
    { value: "Sudan" },
    { value: "Swaziland" },
    { value: "Tanzania" },
    { value: "Chad" },
    { value: "Togo" },
    { value: "Tunisia" },
    { value: "Uganda" },
    { value: "Democratic Republic of the Congo" },
    { value: "Zambia" },
    { value: "Zimbabwe" },
    { value: "Eritrea" },
    { value: "South Sudan" },
    { value: "Other Countries of Africa" },
    { value: "Canada" },
    { value: "The United States of America" },
    { value: "Mexico" },
    { value: "Antigua y Barbuda" },
    { value: "Bahamas" },
    { value: "Barbados" },
    { value: "Belize" },
    { value: "Costa Rica" },
    { value: "Cuba" },
    { value: "Dominique" },
    { value: "El Salvador" },
    { value: "Grenada" },
    { value: "Guatemala" },
    { value: "Haiti" },
    { value: "Honduras" },
    { value: "Jamaica" },
    { value: "Nicaragua" },
    { value: "Panama" },
    { value: "Saint Vincent and the Grenadines" },
    { value: "Dominican Republic" },
    { value: "Trinidad and Tobago" },
    { value: "Saint Lucia" },
    { value: "Saint Kitts and Neviss" },
    { value: "Argentina" },
    { value: "Bolivia" },
    { value: "Brazil" },
    { value: "Colombia" },
    { value: "Chile" },
    { value: "Ecuador" },
    { value: "Guyana" },
    { value: "Paraguay" },
    { value: "Peru" },
    { value: "Surinam" },
    { value: "Uruguay" },
    { value: "Venezuela" },
    { value: "Other Countries of America" },
    { value: "Afghanistan" },
    { value: "Saudi Arabia" },
    { value: "Bahrain" },
    { value: "Bangladesh" },
    { value: "Myanmar" },
    { value: "China" },
    { value: "United Arab Emirates" },
    { value: "Philippines" },
    { value: "India" },
    { value: "Indonesia" },
    { value: "Iraq" },
    { value: "Iran" },
    { value: "Israel" },
    { value: "Japan" },
    { value: "Jordan" },
    { value: "Cambodia" },
    { value: "Kuwait" },
    { value: "Laos" },
    { value: "Lebanon" },
    { value: "Malaysia" },
    { value: "Maldives" },
    { value: "Mongolia" },
    { value: "Nepal" },
    { value: "Oman" },
    { value: "Pakistan" },
    { value: "Qatar" },
    { value: "Korea" },
    { value: "North Korea" },
    { value: "Singapore" },
    { value: "Syria" },
    { value: "Sri Lanka" },
    { value: "Thailand" },
    { value: "Vietnam" },
    { value: "Brunei" },
    { value: "Marshall Islands" },
    { value: "Yemen" },
    { value: "Azerbaijan" },
    { value: "Kazakhstán" },
    { value: "Kirgyzstan" },
    { value: "Tajikistan" },
    { value: "Turkmeinetan" },
    { value: "Uzbekistan" },
    { value: "Bhutan" },
    { value: "Palestine. Observer State, non-member of the United Nations" },
    { value: "Other Countries of Asia" },
    { value: "Australia" },
    { value: "Fiji" },
    { value: "New Zeland" },
    { value: "Papua New Guinea" },
    { value: "Solomon Islands" },
    { value: "Samoa" },
    { value: "Tonga" },
    { value: "Vanuatu" },
    { value: "Micronesia" },
    { value: "Tuvalu" },
    { value: "Cook Islands" },
    { value: "Kiribati" },
    { value: "Nauru" },
    { value: "Palau" },
    { value: "East Timor" },
  ]);

  const [phone, setPhone] = useState("");
  const [name, setName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [country, setCountry] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [address, setAddress] = useState("");

  const [addressArr, setAddressArr] = useState([]);


  const [currentLoggedInUser, setCurrentLoggedInUser] = useContext(CurrentLoggedInUserContext);
  const [userIsAuthorised, setUserIsAuthorised] = useContext(userIsAuthorisedContext);
  const handleGetProfile = async () => {
    try {
      let decodedToken = getDecodedToken()
      let { data: res } = await getUser(decodedToken?.userId)
      if (res.data) {
        setUserObj(res.data);
      }
    }
    catch (err) {
      toastError(err)
    }
  }
  const handleGetAddress = async () => {
    try {
      let { data: res } = await getAddress()
      if (res.data) {
        setAddressArr(res.data);
      }
    }
    catch (err) {
      toastError(err)
    }
  }

  const handleDeleteAddress = async (id) => {
    try {
      let { data: res } = await deleteAddress(id)
      if (res.message) {
        toastSuccess(res.message)
        handleGetAddress()
      }
    }
    catch (err) {
      toastError(err)
    }
  }



  const handleAddAddress = async () => {
    try {
      if (name == "") {
        toastError("Name is mandatory !!!");
        return
      }
      if (phone == "") {
        toastError("Phone is mandatory !!!");
        return
      }
      if (country == "") {
        toastError("Country is mandatory !!!");
        return
      }
      if (city == "") {
        toastError("City is mandatory !!!");
        return
      }
      if (state == "") {
        toastError("State is mandatory !!!");
        return
      }
      if (pincode == "") {
        toastError("Pincode is mandatory !!!");
        return
      }
      if (address == "") {
        toastError("Address is mandatory !!!");
        return
      }


      let decoded = getDecodedToken()

      let obj = {
        phone,
        name,
        companyName,
        country,
        city,
        state,
        pincode,
        address,
        userId: decoded.userId
      }

      if (isEditingEnabled) {
        let { data: res } = await updateAddress(obj, selectedAddress?._id)
        if (res.message) {
          toastSuccess(res.message)
          handleGetAddress()
          setnewAddress(false)
          setIsEditingEnabled(false)
        }
      }
      else {
        let { data: res } = await addAddress(obj)
        if (res.message) {
          toastSuccess(res.message)
          handleGetAddress()
          setnewAddress(false)
          setIsEditingEnabled(false)
        }
      }
      handleClearState()


    }
    catch (err) {
      toastError(err)
    }
  }



  useEffect(() => {
    handleGetProfile()
    handleGetAddress()
  }, [])



  const handleClearState = () => {
    setPhone("")
    setName("")
    setCompanyName("")
    setCountry("")
    setCity("")
    setState("")
    setPincode("")
    setAddress("")

  }

  const handleEdit = (obj) => {
    setIsEditingEnabled(true);
    setSelectedAddress(obj);
    setnewAddress(true)
    setPhone(obj?.phone)
    setName(obj?.name)
    setCompanyName(obj?.companyName)
    setCountry(obj?.country)
    setCity(obj?.city)
    setState(obj?.state)
    setPincode(obj?.pincode)
    setAddress(obj?.address)
  }

  return (
    <div class="profile-page">
      <h5 class="title brown2 mb-3 pb-2 border-bottom">Profile</h5>
      <p class="fw-semibold mb-4">BASIC INFOMATION</p>
      <ul class="profile-info row gy-0 gy-md-3">
        <li class="col-12 col-md-6">
          <span>First Name:</span>&nbsp; {userObj?.firstName}
        </li>
        <li class="col-12 col-md-6">
          <span>Mobile:</span>&nbsp; {userObj?.phone}
        </li>
        <li class="col-12 col-md-6">
          <span>Last Name:</span>&nbsp; {userObj?.lastName}
        </li>
        <li class="col-12 col-md-6">
          <span>Email:</span>&nbsp; {userObj?.email}
        </li>
      </ul>
      <div class="d-flex align-items-center justify-content-between border-top border-bottom py-3 my-4">
        <p class="mb-0 fw-semibold">CUSTOMER ADDRESS BOOK</p>
        <button
          class="btn btn-hover btn-custom btn-brown text-uppercase btn-sm py-2 px-4"
          onClick={() => setnewAddress(!newAddress)}
        >
          Add a New Address
        </button>
      </div>
      <ul class="address-box row gy-5">
        {

          addressArr && addressArr.length > 0 && addressArr.map((el, index) => {
            return (
              <li key={index} class="col-12 col-sm-6 col-xxl-4 false">
                <div class="address-box-inner">
                  <h6 class="poppin">{el?.name}</h6>
                  <h6 class="poppin">{el?.companyName}</h6>
                  <p class="text-muted fs-15">
                    <address>
                      {el.address} - {el.pincode}
                    </address>
                  </p>
                  <p class="text-muted fs-15">
                    <address>Phone {el?.phone}</address>
                  </p>
                  <div class="links">
                    <div class="icons" onClick={() => handleDeleteAddress(el._id)}>
                      <AiOutlineClose />
                    </div>
                    <div class="icons" onClick={() => { handleEdit(el) }}>
                      <MdEdit />
                    </div>
                  </div>
                </div>
              </li>
            )
          })
        }

        {/* <li class="col-12 col-sm-6 col-xxl-4 false">
          <div class="address-box-inner">
            <h6 class="poppin">dsfdaf </h6>
            <p class="text-muted fs-15">
              <address>sdfads, , sdfa, sdfads, sdaf, usa - sdaf</address>
            </p>
            <p class="text-muted fs-15">
              <address>Phone 9874569896</address>
            </p>
            <div class="links">
              <div class="icons">
                <AiOutlineClose />
              </div>
              <div class="icons">
                <MdEdit />
              </div>
            </div>
          </div>
        </li>
        <li class="col-12 col-sm-6 col-xxl-4 false">
          <div class="address-box-inner">
            <h6 class="poppin">yogesh kumar (Default)</h6>
            <p class="text-muted fs-15">
              <address>delhi, , , delhi, delhi, germany - 110086</address>
            </p>
            <p class="text-muted fs-15">
              <address>Phone 9898989898</address>
            </p>
            <div class="links">
              <div class="icons">
                <AiOutlineClose />
              </div>
              <div class="icons">
                <MdEdit />
              </div>
            </div>
          </div>
        </li> */}
      </ul>
      {
        newAddress && (
          <form className="form row mt-5">
            <div className="col-12">
              <p className="title fw-semibold">ADD NEW ADDRESS</p>
            </div>
            <div className="col-12 col-sm-6 mb-3">
              <label>
                Name
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
              </label>
            </div>
            <div className="col-12 col-sm-6 mb-3">
              <label>
                Company name(optional)
                <input
                  type="text"
                  className="form-control"
                  value={companyName}
                  onChange={(e) => setCompanyName(e.target.value)}
                />
              </label>
            </div>
            <div className="col-12 mb-3">
              <label>
                Address
                <input
                  type="text"
                  className="form-control"
                  value={address}
                  onChange={(e) => setAddress(e.target.value)}
                />
              </label>
            </div>

            <div className="col-12 mb-3">

              <label>
                Country
                <select value={country} onChange={(e) => { setCountry(e.target.value); }} className="form-select" aria-label="Country / Region *">
                  <option selected disabled hidden>
                    Country / Region *
                  </option>
                  <option value="">Country / Region *</option>
                  {
                    countryArr.map((el, index) => {
                      return (
                        <option value={el.value} key={index}>{el.value}</option>
                      )
                    })
                  }
                </select>
                {/* <input type="text" onChange={(e) => setCountry(e.target.value)} value={country} className="form-control" /> */}
              </label>
            </div>

            <div className="col-12 col-sm-4 mb-3">
              <label>
                City
                <input
                  type="text"
                  value={city}
                  onChange={(e) => setCity(e.target.value)}
                  className="form-control"
                />
              </label>
            </div>
            <div className="col-12 col-sm-4 mb-3">
              <label>
                State
                <input
                  type="text"
                  className="form-control"
                  value={state}
                  onChange={(e) => setState(e.target.value)}
                />
              </label>
            </div>
            <div className="col-12 col-sm-4 mb-3">
              <label>
                Postal / Zip Code
                <input
                  type="text"
                  className="form-control"
                  value={pincode}
                  onChange={(e) => setPincode(e.target.value)}
                />
              </label>
            </div>
            <div className="col-12">
              <label>
                Phone
                <input
                  type="tel"
                  maxLength={10}
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  className="form-control"
                />
              </label>
            </div>
            <div className="col-12 mb-3">
              {/* <div className="form-check mt-2 ps-0">
              <label className="form-check-label d-flex gap-2">
                <input
                  className="form-check-input"
                  type="checkbox"
                  value=""
                />
                Set as Default address
              </label>
            </div> */}
              {/* <div className="form-check mt-2">
              <label className="form-check-label">
                <input className="form-check-input" type="checkbox" value="" />
                Text me with news and offers
              </label>
            </div> */}
            </div>
            <div className="col-12">
              <button
                onClick={() => handleAddAddress()}
                type="button"
                className="btn btn-hover btn-custom btn-brown px-4"
              >
                Submit
              </button>
            </div>
          </form>
        )
      }
    </div >
  );
}

export default Profile;
