import React, { useContext, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import Offcanvas from "react-bootstrap/Offcanvas";
import { BiChevronDown, BiSearch } from "react-icons/bi";
import { AiFillHome, AiOutlineShoppingCart } from "react-icons/ai";
import { FaUser } from "react-icons/fa";
import { useState } from "react";
import { Link } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
// import { getCategory, getNestedCategories } from "../../services/category.service";
import { toastError, toastSuccess } from "../utils/toastUtils";
import { NavDropdown } from "react-bootstrap";
import { add, get, deleteById } from "../services/requestEnquiry.service";
import { LoadingContext } from "../App";

function EquiryForm() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [destinationCountry, setDestinationCountry] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [descriptionOne, setDescriptionOne] = useState("");
  const [dsecriptionTwo, setDescriptionTwo] = useState("");
  const [indicators, setIndicators] = useState("");
  const [image, setImage] = useState("");
  const [getFileName, setGetFileName] = useState('')
  const [loading, setLoading] = useContext(LoadingContext);

  const clear = () => {
    setName("");
    setEmail("");
    setPhone("");
    setDestinationCountry("");
    setZipCode("");
    setDescriptionOne("");
    setDescriptionTwo("");
    setIndicators("");
    setImage("");
  };

  const handleSubmit = async (e) => {
    try {

      setLoading(true)
      e.preventDefault();
      if (name == "") {
        toastError("name is mandatory");
        setLoading(false)

        return;
      }
      if (email == "") {
        toastError("Email is mandatory");
        setLoading(false)

        return;
      } else if (
        !/^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/.test(
          email
        )
      ) {
        toastError("Email is not valid ");
        setLoading(false)

        return;
      } else if (destinationCountry == "") {
        toastError("Destination Country is mandatory");
        setLoading(false)

        return;
      } else if (zipCode == "") {
        toastError("Zip code is mandatory");
        setLoading(false)

        return;
      } else if (descriptionOne == "") {
        toastError(
          "Description is madatory regarding your business brand/ business "
        );
        setLoading(false)

        return;
      } else if (dsecriptionTwo == "") {
        toastError("kindly share us what are you looking for ");
        setLoading(false)

        return;
      } else if (indicators == "") {
        toastError("Kindly tell us estimate quantities required per design");
        setLoading(false)

        return;
      }
      let obj = {
        name,
        email,
        phone,
        destinationCountry,
        zipCode,
        descriptionOne,
        dsecriptionTwo,
        indicators,
        image,
      };
      // console.log(obj, "obj2134123");
      let { data: res } = await add(obj);
      if (res.success) {
        setLoading(false)

        toastSuccess(res?.message);
        clear();
      }
    }
    catch (err) {
      setLoading(false)
      toastError(err)
    }
  };
  const getBase64 = (file, cb) => {
    if (file) {

      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function () {
        cb(reader.result);
      };
      reader.onerror = function (error) {
        // console.log('Error: ', error)
      };
    }
  };
  const handleFileSelection = async (event) => {
    event.preventDefault();
    let tempArr = [];
    let fileNameArr = []
    if (event.target.files && event.target.files.length > 0) {

      for (let i = 0; i <= event.target.files.length - 1; i++) {


        fileNameArr.push(event.target.files[i].name)
        getBase64(event.target.files[i], (result) => {
          // console.log(event.target.files[i], "event.target.files[i]")
          tempArr.push({ link: event.target.files[i], base64: result });
          if (i == event.target.files.length - 1) {
            setImage([...tempArr])
          }
        });
      }
    }
    setGetFileName(`${fileNameArr[0]} ${fileNameArr.length > 1 ? `+ ${fileNameArr.length - 1}more` : ""}`)
  };

  return (
    <main>
      <section className="ptb-80">
        <div className="container">
          <div className="text-center mb-5">
            <h1 className="title mb-3">Request Inquiry</h1>
            <p className="mb-0">Looking for a specific product?</p>
            <p className="mb-0">
              Fill out this form and let us know your requirement.
            </p>
          </div>
          <form className="form row" onSubmit={handleSubmit}>
            <div className="col-12 col-md-6 mb-4">
              <input
                type="text"
                className="form-control"
                placeholder="Name *"
                value={name}
                onChange={(e) => {
                  setName(e.target.value);
                }}
              />
            </div>
            <div className="col-12 col-md-6 mb-4">
              <input
                type="email"
                className="form-control"
                placeholder="Email *"
                value={email}
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
              />
            </div>
            <div className="col-12 col-md-6 mb-4">
              <input
                type="text"
                className="form-control"
                placeholder="Phone Number"
                value={phone}
                onChange={(e) => {
                  setPhone(e.target.value);
                }}
              />
            </div>
            <div className="col-12 col-md-3 mb-4">
              <input
                type="text"
                className="form-control"
                placeholder="Destination Country *"
                value={destinationCountry}
                onChange={(e) => setDestinationCountry(e.target.value)}
              />
            </div>
            <div className="col-12 col-md-3 mb-4">
              <input
                type="text"
                className="form-control"
                placeholder="Destination Pin/ Postal/ Zip Code *"
                value={zipCode}
                onChange={(e) => setZipCode(e.target.value)}
              />
            </div>
            <div className="col-12 mb-4">
              <textarea
                rows="2"
                className="form-control"
                placeholder="Tell us about your brand/business to help us find the right products *"
                value={descriptionOne}
                onChange={(e) => {
                  setDescriptionOne(e.target.value);
                }}
              ></textarea>
            </div>
            <div className="col-12 mb-4">
              <textarea
                rows="2"
                className="form-control"
                placeholder="Please describe the type/material/category of products, you are looking for *"
                value={dsecriptionTwo}
                onChange={(e) => setDescriptionTwo(e.target.value)}
              ></textarea>
            </div>
            <div className="col-12 col-md-6 mb-4">
              <input
                type="text"
                className="form-control"
                placeholder="Please indicate the estimated quantities required per design *"
                value={indicators}
                onChange={(e) => setIndicators(e.target.value)}
              />
            </div>
            <div className="col-12 col-md-6 mb-4">
              <div className="position-relative overflow-hidden">
                <input
                  type="file"
                  className="form-control"
                  multiple
                  onChange={(e) => handleFileSelection(e)}
                />
                <div className="form-text brown fw-semibold">
                  {/* <img src={image} onchange={(e)=>setImage(e.target.value)} alt='' className='w-auto img-contain' /> */}
                  {getFileName
                    ? getFileName
                    : "Attach reference images/sketches of designs, if available"}
                </div>
              </div>
              <p className="mb-0 mt-1 small text-end line-height-normal">
                <span className="text-danger">*</span>&nbsp;Indicates a required
                field.
              </p>
            </div>
            <div className="col-12 text-center">
              <h5>Your information is safe with us.</h5>
              <button
                className="btn btn-custom btn-brown px-4 mt-3"
                onClick={(e) => handleSubmit(e)}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
      </section>
    </main>
  );
}

export default EquiryForm;
